import { endpoint } from '../../../../AppGlobal'
import { EditableListView } from '../../../../components/EditableItemView'
import Role from '../../../../const/role'
import LimitGroupsListFields from '../../../SettingsDetail/LimitGroupsDetail/components/LimitGroupsListFields'
import LimitGroupsFormFields from '../../../SettingsDetail/LimitGroupsDetail/components/LimitGroupsFormFields'
import { Drawer } from 'antd'
function LimitGroupsDetailsModal({ visible, limitgroup, closeModal }) {

    // const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchLimitGroupsDetail');

    // const breadCrumbItems = [
    //     {
    //         title: <a href="/limitgroups">Limit Groups</a>,
    //     },
    //     {
    //         title: "Limit Group"
    //     },
    // ]

    return (
        <Drawer
            title="Limit Group Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700}
        >
            <EditableListView id={limitgroup}
                listFields={LimitGroupsListFields}
                formFields={LimitGroupsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateLimitGroupsDetails')}
                title='Details'
                groupsForEdit={[Role.LimitGroupEditor, Role.LimitGroupAdmin]}
            />
        </Drawer>
    )
}

export default LimitGroupsDetailsModal