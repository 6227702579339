import './App.css';
import { Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux';
import store from './stores/store';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/LoginPage/index';
import Dashboard from './pages/DashboardPage/index'
import ClientManagement from './pages/ClientManagementPage/index';
import Transactions from './pages/TransactionPage/index';
import CardHolders from './pages/CardHoldersPage/index'
// import Reporting from './pages/ReportingPage/index';
import CardManagement from './pages/CardManagementPage/index';
import { ErrorPage } from './pages/errorpages/generic';
import LogoutView from './pages/Logout';

import APPROUTES from './const/routes';

import CardHoldersDetails from './pages/CardHolder';
import ClientManagementDetails from './pages/ClientManagement';
import Role from './const/role';
import ProcessorDetailView from './pages/ClientManagement/tabs/ProcessorDetail';
import { ErrorType } from './const/enums';
import { locale, currency, timezone } from './const/Internalization'
import UserManagement from './pages/UserManagementPage';
import Accounts from './pages/AccountsPage';
import CardDetails from './pages/CardManagementDetail';
import AccountDetails from './pages/AccountsDetail';
import UserDetail from './pages/UserDetail';
import CreateProcessor from './pages/ClientManagement/CreateProcessorForm';
import CreateClient from './pages/ClientManagement/CreateClientForm';
import CreateUser from './pages/UserDetail/CreateUserForm';
import Authorizations from './pages/AuthorizationsPage';
import Settlements from './pages/SettlementsPage';
import TransactionsDetail from './pages/TransactionsDetail';
import AddBalance from './pages/BalanceManagementAdd';
import BalanceManagement from './pages/BalanceManagementPage';
import PassfortSettings from './pages/SettingsPage';
import CreatePassfortSettings from './pages/SettingsPage/CreatePassfortSettings/index'
import CreateIPAddress from './pages/ClientManagement/CreateIPAddressForm';
import Logs from './pages/LogsPage';
import Program from './pages/SettingsPage/tabs/programs';
import ProgramDetail from './pages/SettingsDetail/ProgramsDetail';
import CreateProgram from './pages/SettingsDetail/CreateProgramForm';
import FeeGroups from './pages/SettingsPage/tabs/feegroups';
import FeeGroupsDetail from './pages/SettingsDetail/FeeGroupsDetail';
import CreateFeeGroups from './pages/SettingsDetail/CreateFeeGroupsForm';
import LimitGroups from './pages/SettingsPage/tabs/limitgroups';
import LimitGroupsDetail from './pages/SettingsDetail/LimitGroupsDetail';
import CreateLimitGroups from './pages/SettingsDetail/CreateLimitGroupsForm';
import UsageGroups from './pages/SettingsPage/tabs/usagegroups';
import UsageGroupsDetail from './pages/SettingsDetail/UsageGroupsDetail';
import CreateUsageGroups from './pages/SettingsDetail/CreateUsageGroupsForm';
import CreateHolderComment from './pages/CardHolder/CreateCommentForm';

const config = {
  token: {
    colorPrimary: '#ff4d4f', /*#e5e5e5 */
  },
};

export function View(props) {
  return (
    <div>{props.name}</div>
  )
}



function App() {

  window.currentLocale = locale
  window.currentTimezone = timezone
  window.currentCurrency = currency
  return (
    <Provider store={store}>
      <div className="App">
        <ConfigProvider theme={config}>
          <Routes>
            <Route element={<Login />} path="/login" />
            <Route element={<h1>Not found!</h1>} />
            <Route element={<ErrorPage errorType={ErrorType.ERROR403} />} path="/accessdenied" />
            <Route element={<ErrorPage errorType={ErrorType.ERROR404} />} path="/notfound" />
            <Route element={<PrivateRoute roles={APPROUTES.dashboard.roles} />}>
              <Route element={<Dashboard />} path={APPROUTES.dashboard.path} />
            </Route>

            {/* ------------------- Start Client ---------------------------------------- */}
            <Route element={<PrivateRoute roles={APPROUTES.clientManagment.roles} exact />}>
              <Route element={<ClientManagement />} path={APPROUTES.clientManagment.path} exact />
            </Route>
            <Route element={<PrivateRoute roles={APPROUTES.clientManagment.roles} exact />}>
              <Route element={<ProcessorDetailView />} path={'/clients/detail/:id/processor/:processorid'} exact />
            </Route>
            <Route element={<PrivateRoute roles={APPROUTES.clientManagment.roles} exact />}>
              <Route element={<ClientManagementDetails />} path={'/clients/:id/:tab?'} exact />
            </Route>
            <Route element={<PrivateRoute roles={[Role.ClientEditor, Role.ClientAdmin]} exact />}>
              <Route element={<CreateProcessor />} path="/clients/detail/:id/processor/create" />
            </Route>
            <Route element={<PrivateRoute roles={[Role.ClientEditor, Role.ClientAdmin]} exact />}>
              <Route element={<CreateClient />} path="/clients/create" />
            </Route>
            <Route element={<PrivateRoute roles={[Role.ClientEditor, Role.ClientAdmin]} exact />}>
              <Route element={<CreateIPAddress />} path="/clients/:id/ipaddresses/create" />
            </Route>
            {/* ---------------End  Client-------------------------------------------------*/}

            {/* ---------------Start  Reporting-------------------------------------------------*/}
            {/* <Route element={<PrivateRoute roles={APPROUTES.reporting.roles} exact />}>
              <Route element={<Reporting />} path={APPROUTES.reporting.path} exact />
            </Route> */}
            {/* ---------------End  Reporting-------------------------------------------------*/}

            {/* ---------------Start  Transactions-------------------------------------------------*/}
            <Route element={<PrivateRoute roles={APPROUTES.transactions.roles} exact />}>
              <Route element={<Transactions />} path={'/transactions'} exact />
            </Route>
            <Route element={<PrivateRoute roles={APPROUTES.transactions.roles} exact />}>
              <Route element={<Authorizations />} path={'/authorizations'} exact />
            </Route>
            <Route element={<PrivateRoute roles={APPROUTES.transactions.roles} exact />}>
              <Route element={<Settlements />} path={'/settlements'} exact />
            </Route>
            {/* <Route element={<PrivateRoute roles={APPROUTES.transactions.roles} exact />}>
              <Route element={<TransactionsDetail />} path={'/transactions/:req_type/:trx_id'} exact />
            </Route> */}
            {/* ---------------End  Transaction-------------------------------------------------*/}

            {/* ---------------Start  Card Holders-------------------------------------------------*/}
            <Route element={<PrivateRoute roles={APPROUTES.cardHolders.roles} exact />}>
              <Route element={<CardHoldersDetails />} path={'/holders/:id/:processor/:tab?'} exact />
            </Route>
            <Route element={<PrivateRoute roles={APPROUTES.cardHolders.roles} exact />}>
              <Route element={<CardHolders />} path={APPROUTES.cardHolders.path} exact />
            </Route>
            <Route element={<PrivateRoute roles={[Role.HolderEditor, Role.HolderAdmin]} exact />}>
              <Route element={<CreateHolderComment />} path="/holders/:id/:processor/comments/create" />
            </Route>
            {/* ---------------End  Card Holders-------------------------------------------------*/}

            {/* ---------------Start  Card Management-------------------------------------------------*/}
            <Route element={<PrivateRoute roles={APPROUTES.cardManagement.roles} exact />}>
              <Route element={<CardManagement />} path={APPROUTES.cardManagement.path} exact />
            </Route>
            {/* <Route element={<PrivateRoute roles={APPROUTES.cardManagement.roles} exact />}>
              <Route element={<CardDetails />} path={'/cards/:id/:processor'} exact />
            </Route> */}
            {/* ---------------End  Card Management-------------------------------------------------*/}

            {/* ---------------Start  Account-------------------------------------------------*/}
            <Route element={<PrivateRoute roles={APPROUTES.accounts.roles} exact />}>
              <Route element={<Accounts />} path={APPROUTES.accounts.path} exact />
            </Route>
            {/* <Route element={<PrivateRoute roles={APPROUTES.accounts.roles} exact />}>
              <Route element={<AccountDetails />} path={'/accounts/:id/:processor'} exact />
            </Route> */}
            {/* ---------------End  Account-------------------------------------------------*/}

            {/* ---------------Start  User-------------------------------------------------*/}
            <Route element={<PrivateRoute roles={APPROUTES.userManagement.roles} exact />}>
              <Route element={<UserManagement />} path={APPROUTES.userManagement.path} exact />
            </Route>
            {/* <Route element={<PrivateRoute roles={APPROUTES.userManagement.roles} exact />}>
              <Route element={<UserDetail />} path={'/users/:id'} exact />
            </Route> */}
            <Route element={<PrivateRoute roles={[Role.UserEditor, Role.UserAdmin]} exact />}>
              <Route element={<CreateUser />} path="/users/create" />
            </Route>
            {/* ---------------End  User-------------------------------------------------*/}

            {/* ---------------Start Balance Management-------------------------------------------------*/}

            <Route element={<PrivateRoute roles={APPROUTES.balanceManagement.roles} exact />}>
              <Route element={<BalanceManagement />} path="/balance/:tab?" />
            </Route>
            <Route element={<PrivateRoute roles={[Role.BalanceEditor, Role.BalanceAdmin]} exact />}>
              <Route element={<AddBalance />} path="/balance/load" />
            </Route>

            {/* ---------------End Balance Management-------------------------------------------------*/}

            {/* ---------------Start Logs-------------------------------------------------*/}

            <Route element={<PrivateRoute roles={[Role.Superuser]} exact />}>
              <Route element={<Logs />} path="/logs" />
            </Route>

            
            {/* ---------------End Logs-------------------------------------------------*/}

            {/* ---------------Start Settings-------------------------------------------------*/}

            <Route element={<PrivateRoute roles={[Role.PassfortReader, Role.PassfortEditor, Role.PassfortAdmin]} exact />}>
              <Route element={<PassfortSettings />} path={'/passfort'} />
            </Route>
            <Route element={<PrivateRoute roles={[Role.PassfortEditor, Role.PassfortAdmin]} exact />}>
              <Route element={<CreatePassfortSettings />} path="/passfort/create" />
            </Route>
            <Route element={<PrivateRoute roles={[Role.ProgramReader, Role.ProgramEditor, Role.ProgramAdmin]} exact />}>
              <Route element={<Program />} path={'/programs'} />
            </Route>
            {/* <Route element={<PrivateRoute roles={[Role.ProgramReader, Role.ProgramEditor, Role.ProgramAdmin]} exact />}>
              <Route element={<ProgramDetail />} path={'/programs/:id'} exact />
            </Route> */}
            <Route element={<PrivateRoute roles={[Role.ProgramEditor, Role.ProgramAdmin]} exact />}>
              <Route element={<CreateProgram />} path="/programs/create" />
            </Route>
            <Route element={<PrivateRoute roles={[Role.FeeGroupReader, Role.FeeGroupEditor, Role.FeeGroupAdmin]} exact />}>
              <Route element={<FeeGroups />} path={'/feegroups'} />
            </Route>
            {/* <Route element={<PrivateRoute roles={[Role.FeeGroupReader, Role.FeeGroupEditor, Role.FeeGroupAdmin]} exact />}>
              <Route element={<FeeGroupsDetail />} path={'/feegroups/:id'} exact />
            </Route> */}
            <Route element={<PrivateRoute roles={[Role.FeeGroupEditor, Role.FeeGroupAdmin]} exact />}>
              <Route element={<CreateFeeGroups />} path="/feegroups/create" />
            </Route>
            <Route element={<PrivateRoute roles={[Role.LimitGroupReader, Role.LimitGroupEditor, Role.LimitGroupAdmin]} exact />}>
              <Route element={<LimitGroups />} path={'/limitgroups'} />
            </Route>
            {/* <Route element={<PrivateRoute roles={[Role.LimitGroupReader, Role.LimitGroupEditor, Role.LimitGroupAdmin]} exact />}>
              <Route element={<LimitGroupsDetail />} path={'/limitgroups/:id'} exact />
            </Route> */}
            <Route element={<PrivateRoute roles={[Role.LimitGroupEditor, Role.LimitGroupAdmin]} exact />}>
              <Route element={<CreateLimitGroups />} path="/limitgroups/create" />
            </Route>
            <Route element={<PrivateRoute roles={[Role.UsageGroupReader, Role.UsageGroupEditor, Role.UsageGroupAdmin]} exact />}>
              <Route element={<UsageGroups />} path={'/usagegroups'} />
            </Route>
            {/* <Route element={<PrivateRoute roles={[Role.UsageGroupReader, Role.UsageGroupEditor, Role.UsageGroupAdmin]} exact />}>
              <Route element={<UsageGroupsDetail />} path={'/usagegroups/:id'} exact />
            </Route> */}
            <Route element={<PrivateRoute roles={[Role.UsageGroupEditor, Role.UsageGroupAdmin]} exact />}>
              <Route element={<CreateUsageGroups />} path="/usagegroups/create" />
            </Route>
            {/* ---------------End Settings-------------------------------------------------*/}

            <Route element={<PrivateRoute roles={Role.ANY} exact />}>
              <Route element={<LogoutView />} path={'/logout'} exact />
            </Route>
            <Route element={<ErrorPage errorType={ErrorType.ERROR404} />} path="*" />
          </Routes>
        </ConfigProvider>
      </div>
    </Provider>
  );
}

export default App;
