import React from 'react';
import { Drawer } from 'antd';
import UserListFields from '../../UserDetail/components/UserListFields';
import UserFormFields from '../../UserDetail/components/UserEditFields';
import Endpoint from '../../../api/endpoint';
import { endpoint } from '../../../AppGlobal';
import { EditableListView } from '../../../components/EditableItemView';
import Role from '../../../const/role';

const UserDetailsModal = ({ visible, user, closeModal }) => {

    const fetchMethod = async (user) => {
        const response = await endpoint.getMethod('fetchUserDetails')(user);

        if (response._status === Endpoint.status.success) {
            const groupsField = UserFormFields.find(item => item.dataIndex === 'groups');
            const groupsListField = UserListFields.find(item => item.dataIndex === 'groups');

            const responseGroups = await endpoint.fetchGroups();

            if (responseGroups._status === Endpoint.status.success) {
                groupsListField.options = groupsField.options = responseGroups.results.map(item => ({ label: item.name, value: item.id }));
                groupsField.inprogress = false;
            } else {
                groupsField.inprogress = false;
                groupsField.error = responseGroups.message || 'An error occurred';
            }
        }

        return response;
    };

    return (
        <Drawer
            title="User Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700} // Adjust the width as needed
            // Add any other Drawer props here
        >
            {/* Display user details here */}
            <EditableListView
                id={user}
                listFields={UserListFields}
                formFields={UserFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateUserDetails')}
                title="Details"
                groupsForEdit={[Role.UserEditor, Role.UserAdmin]}
            />
        </Drawer>
    );
};

export default UserDetailsModal;