import React from 'react'
import ClientHistoryColumns from '../components/ClientHistoryColumns'
import { endpoint } from '../../../AppGlobal'
import ListPage from '../../../components/List'
function ClientHistory({clientId}) {
    return (
        <ListPage fetchMethod={async (page,filter)=>{ 
			const response = await endpoint.fetchHistoryOfClient(clientId,page,filter)
			return response
		}} columns={ClientHistoryColumns}   />
    
    )
}

export default ClientHistory