import { ClientEditFormFields } from '../components/DetailFormFields'
import {ClientListFields} from '../components/DetailListFields'
import { endpoint } from "../../../AppGlobal"
import { EditableListView } from "../../../components/EditableItemView"
import Role from '../../../const/role'
function ClientDetail({ clientId }) {

	return (
		<div>
			<EditableListView id={clientId}
			listFields={ClientListFields} 
			formFields={ClientEditFormFields}
			fetchMethod = {endpoint.getMethod('fetchClientDetails')}
			updateMethod={endpoint.getMethod('updateClientDetails')}
			title = 'Details'
			groupsForEdit = {[Role.ClientEditor, Role.ClientAdmin]}
			/>
		</div>
	)
}

export default ClientDetail