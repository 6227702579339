import { currencyBuilder } from "../../../const/Internalization";
import StatusIcon from "../../../components/StatusIcon";
import { Tag } from "antd";
import { ProcessorType } from "../../../const/enums";
import { EyeOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
const TransactionsDetailListFields = (openCardModal) => [
    {
        key: 0,
        label: 'Created At',
        dataIndex: 'created_at',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            // const date = value ? value : '-'
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        }
    },
    {
        key: 1,
        label: 'Created By',
        dataIndex: 'created_by',

    },
    {
        key: 2,
        label: 'Updated At',
        dataIndex: 'updated_at',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            // const date = value ? value : '-'
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        }
    },
    {
        key: 3,
        label: 'Updated By',
        dataIndex: 'updated_by',
    },
    {
        key: 4,
        label: 'Deleted',
        dataIndex: 'is_deleted',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        key: 5,
        label: 'Active',
        dataIndex: 'is_active',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        key: 6,
        label: 'Processor',
        dataIndex: 'processor_type',
        customRender: true,
        render: (type) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Type:
                    </div>
                    <div style={{ display: 'flex' }}>
                        {ProcessorType.ItemFromValue(type)}
                    </div>
                </div>
            )
        }

    },
    {
        key: 50,
        label: 'Client',
        dataIndex: 'client'
    },
    {
        key: 51,
        label: 'Holder',
        dataIndex: 'holder'
    },
    {
        key: 52,
        label: 'Holder Email',
        dataIndex: 'holder_email'
    },
    {
        key: 7,
        label: 'Pcardid',
        dataIndex: 'pcardid',

    },
    {
        key: 8,
        label: 'MCC code',
        dataIndex: 'mcc',
    },
    {
        key: 9,
        label: 'Merchant ID',
        dataIndex: 'merchant_id'
    },
    {
        key: 9,
        label: 'Merchant Name',
        dataIndex: 'merchant_name'
    },
    {
        key: 10,
        label: 'Pos Entry Mode',
        dataIndex: 'pos_entry_mode',
    },
    {
        key: 50,
        label: 'Is PIN',
        dataIndex: 'entry_mode_type',
        customRender: true,
        render: (value, row) => {
            const isPIN = value === 'Icc' ? 'YES' : 'NO';
            return (
                <p className='responsiveInline'>
                    {isPIN}
                </p>
            )
        },
    },
    {
        key: 11,
        label: 'Pos Terminal ID',
        dataIndex: 'pos_terminal_id',

    },
    {
        key: 12,
        label: 'Entry Mode Type',
        dataIndex: 'entry_mode_type',

    },
    {
        key: 13,
        label: 'Holder Amount',
        dataIndex: 'holder_amount_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },

    },
    {
        key: 14,
        label: 'Holder Currency',
        dataIndex: 'holder_currency',

    },
    {
        key: 16,
        label: 'Trx Status',
        dataIndex: 'trx_status_name',

    },
    {
        key: 17,
        label: 'Card Id',
        dataIndex: 'card_id',
        customRender: true,
        render: (value,row) => {
			return (
				<Link key='Card Id' onClick={() => openCardModal(value)}>
                            {value}
                </Link>
			)
		}

    },
    {
        key: 18,
        label: 'Available Balance',
        dataIndex: 'available_balance_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },
    },
    {
        key: 15,
        label: 'Settled Balance',
        dataIndex: 'settled_balance_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },

    },
    {
        key: 27,
        label: 'Reserved Balance',
        dataIndex: 'reserved_balance_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },
    },
    {
        key: 19,
        label: 'Pauthorization ID',
        dataIndex: 'pauthorizationid',

    },
    {
        key: 20,
        label: 'Authorize Type',
        dataIndex: 'authorize_type'
    },
    {
        key: 21,
        label: 'Advice Reason',
        dataIndex: 'advice_reason'
    },
    {
        key: 22,
        label: 'Authorization Date',
        dataIndex: 'auth_date',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <div>
                    <div className='responsiveTableData'>
                        Auth Date:
                    </div>
                    <p className='responsiveInline'>
                        {date}
                    </p>
                </div>
            )
        }
    },
    {
        key: 23,
        label: 'Authorization Amount',
        dataIndex: 'auth_amount_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },
    },
    {
        key: 24,
        label: 'Authorization Currency',
        dataIndex: 'auth_currency',
    },
    {
        key: 25,
        label: 'Authorization Code',
        dataIndex: 'authorization_code'
    },
    {
        key: 26,
        label: 'Authorization Response Code',
        dataIndex: 'authorization_response_code'
    },
    {
        key: 28,
        label: 'Card Scheme',
        dataIndex: 'card_scheme'
    },
    
    {
        key: 29,
        label: 'Transaction ID',
        dataIndex: 'trx_id'
    },
    {
        key: 30,
        label: 'Trx Type',
        dataIndex: 'trx_type_name'
    },
    {
        key: 32,
        label: 'Account ID',
        dataIndex: 'account_id'
    },
    {
        key: 33,
        label: 'Domestic/International',
        dataIndex: 'domestic_international'
    },
    {
        key: 34,
        label: 'Service Provider',
        dataIndex: 'service_provider'
    },
    {
        key: 35,
        label: 'Settle Amount',
        dataIndex: 'settle_amount_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },
    },
    {
        key: 36,
        label: '3D',
        dataIndex: 'is_3d',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        key: 37,
        label: 'Virtual Card',
        dataIndex: 'is_virtual_card',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        key: 38,
        label: 'Card PAN',
        dataIndex: 'card_no'
    },
    {
        key: 39,
        label: 'Local amount',
        dataIndex: 'local_amount_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },
    },
    {
        key: 40,
        label: 'Response Description',
        dataIndex: 'response_description'
    },
    {
        key: 41,
        label: 'Program ID',
        dataIndex: 'program_id',
        customRender: true,
        render: (val) => {
            return (
                <Tag>{val}</Tag>
            )
        }
    },
    {
        key: 42,
        label: 'Settle Date',
        dataIndex: 'settle_date',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        }
    },
    {
        key: 43,
        label: 'Trx Amount',
        dataIndex: 'trx_amount_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <p className='responsiveInline'>
                    {currency}
                </p>
            )
        },
    },
    {
        key: 44,
        label: 'Trx Created Date',
        dataIndex: 'trx_created_date',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        }
    },
    {
        key: 45,
        label: 'Trx Currency',
        dataIndex: 'trx_currency'
    },
    {
        key: 46,
        label: 'Trx Description',
        dataIndex: 'trx_description'
    },
    {
        key: 47,
        label: 'Transaction Fee',
        dataIndex: 'trx_fee',
    },
    {
        key: 48,
        label: 'Trx Response Code',
        dataIndex: 'trx_response_code'
    },
    {
        key: 49,
        label: 'Card Currency',
        dataIndex: 'card_currency'
    },
];

export default TransactionsDetailListFields