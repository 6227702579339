import StatusIcon from "../../../components/StatusIcon";
import { Tag } from "antd";
export const ClientListFields = [

	{
		label: "First Name",
		dataIndex: 'first_name',

	},

	{
		label: "Last Name",
		dataIndex: 'last_name',

	},

	{
		label: "Username",
		dataIndex: 'username',
	},
	{
		label: "Email",
		dataIndex: 'email',
	},
	{
		label: "URL",
		dataIndex: 'profile__sub__url',
	},
	{
		label: "Created at",
		dataIndex: 'created_at',
		customRender: true,
		render: (value) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<p className='responsiveInline'>
					{date}
				</p>
			)
		},
		sorter: () => { }
	},
	{
		label: "Active",
		dataIndex: 'is_active',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}
	},
	{
		label: "Phone",
		dataIndex: 'profile__sub__phone',
		customRender: true,
		render: (text) => {
			return (
				<div style={{ display: 'flex' }}><a href={`tel:${text}`}>{text}</a></div>
			)
		}
	},
	{
		label: "IP Addresses",
		dataIndex: 'ip_address',
		customRender: true,
		render: (val, row) => {
			return (
				<Tag>{val}</Tag>
			)
		}
	},
	{
		noLabel: true,
		dataIndex: 'profile__sub__bank_name',
		customRender: true,
		render: (text) => {
			return (
				<h4 style={{ display: 'inline', marginLeft: '-16px' }}>Bank Details</h4>
			)
		}
	},
	{
		label: "Trade Name",
		dataIndex: 'profile__sub__tradeName',
		sorter: () => { }
	},
	{
		label: "KYB",
		dataIndex: 'profile__sub__kyb',

	},
	{
		label: "Bank Name",
		dataIndex: 'profile__sub__bank_name',
	},
	{
		label: "IBAN",
		dataIndex: 'profile__sub__iban',
	},
	{
		label: "BIC/SWIFT",
		dataIndex: 'profile__sub__bic_swift',
	},
	{
		label: "Number",
		dataIndex: 'profile__sub__account_number',
	},
	{
		label: "Comments",
		dataIndex: 'profile__sub__comment',
		customRender: true,
		render: (value) => {

			const comments = value ? value : '-'
			return (
				<p className='responsiveInline'>
					{comments}
				</p>
			)
		},
	},
	{
		label: "User",
		dataIndex: 'profile__sub__user',
		type: 'Hidden'
	},
	{
		label: "Password",
		dataIndex: 'password',
		type: 'Hidden'
	},

];