import { formFieldForProcessor } from "../components/formfields"
import { listFieldsForProcessor } from "../components/listfields"
import { endpoint } from "../../../AppGlobal"
import { EditableListView } from "../../../components/EditableItemView"
import Role from '../../../const/role'
function HolderDetail({ holderId, processor }) {
	const processorType = parseInt(processor,0)
	const formFields = formFieldForProcessor(processorType)
	const listFields = listFieldsForProcessor(processorType);
	const fetchMethod = endpoint.getMethod('fetchHolderDetails')
	const updateHolder = endpoint.getMethod('updateHolder')
	return (
		<div>
			<EditableListView id={holderId}
			listFields={listFields} 
			formFields={formFields}
			fetchMethod = {fetchMethod}
			updateMethod={updateHolder}
			title = 'Details'
			groupsForEdit = {[Role.HolderEditor, Role.HolderAdmin]}
			/>
		</div>
	)
}

export default HolderDetail