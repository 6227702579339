import StatusIcon from "../../../../../components/StatusIcon"
import { KYCType, OTPType, MonitoringType, ProcessorType } from "../../../../../const/enums"
import { isonToCountryName } from "../../../../../components/CountrySelector"
import { isonToCurrencyName } from "../../../../../components/CountrySelector"
import { Tag } from "antd"
export const listfields = [

	{
		label: "Type",
		dataIndex: 'type',
		customRender: true,
		render: (value) => {
			return ProcessorType.ItemFromValue(value)
		},

	},
	{
		label: "Name",
		dataIndex: 'name',

	},
	{
		label: "Master Account Balance",
		dataIndex: 'master_account_balance_check',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}

	},
	{
		label: "Master Account Currency",
		dataIndex: 'master_account_currency',
        type: 'Currency',
		rule:[
			{
				required: true,
				message: "Please select a currency type"
			},
		],
		customRender: true,
		render: (value, row) => {
			return (
				<p className='responsiveInline'>
					{isonToCurrencyName(value)}
				</p>
			)
		}

	},
	{
		label: "Webhook URL",
		dataIndex: 'webhook_url',

	},
	{
		label: "Auto Generated PIN",
		dataIndex: 'is_auto_generated_pin',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}

	},
	{
		label: "OTP Type",
		dataIndex: 'otp_type',
		customRender: true,
		render: (value) => {
			return OTPType.ItemFromValue(value)
		},

	},
	{
		noLabel: true,
		dataIndex: 'kyc_config__sub__client_id',
		customRender: true,
		render: (text) => {
			return (
				<h4 style={{ display: 'inline', marginLeft: '-16px' }}>KYC Info</h4>
			)
		}
	},
	{
		label: "KYC Type",
		dataIndex: 'kyc_type',
		customRender: true,
		render: (value) => {
			return KYCType.ItemFromValue(value)
		},

	},
	{
		noLabel: true,
		dataIndex: 'monitoring_config__sub__client_id',
		customRender: true,
		render: (text) => {
			return (
				<h4 style={{ display: 'inline', marginLeft: '-16px' }}>Monitoring Info</h4>
			)
		}
	},
	{
		label: "Monitoring Type",
		dataIndex: 'monitoring_type',
		customRender: true,
		render: (value) => {
			return MonitoringType.ItemFromValue(value)
		},

	},
	{
		noLabel: true,
		dataIndex: 'settings__sub__allowed',
		customRender: true,
		render: (text) => {
			return (
				<h4 style={{ display: 'inline', marginLeft: '-16px' }}>Settings</h4>
			)
		}
	},
	{
		label: "Address Check",
		dataIndex: 'settings__sub__addressCheck',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}

	},
	{
		label: "Allowed",
		dataIndex: 'settings__sub__allowed',
		customRender: true,
		render: (val, row) => {
			/*

			*/
			const list = val.map((item)=>{
				return isonToCountryName(item)
			})
			return (
				<div>{list.map(item=>{
					return <Tag>{item}</Tag>
				})}</div>
			)
		}

	},
	{
		label: "Data Encryption Key",
		dataIndex: 'data_encryption_key',

	},

]

export const ClientKycFields = []

export const PassfortKYCFields = [

	{
		label: "Sandbox",
		dataIndex: 'kyc_config__sub__sandbox',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}

	},
	{
		label: "Client ID",
		dataIndex: 'kyc_config__sub__client_id',

	},
	{
		label: "Grant Type",
		dataIndex: 'kyc_config__sub__grant_type',

	},
	{
		label: "Client secret",
		dataIndex: 'kyc_config__sub__client_secret',

	},
	{
		label: "Product Name",
		dataIndex: 'kyc_config__sub__product_name',

	},
	{
		label: "Onfide Enabled",
		dataIndex: 'kyc_config__sub__onfido_enabled',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}

	},

]

export const unMonitoredFields = []

export const NapierMonitoringFields = [
	{
		label: "Client ID",
		dataIndex: 'monitoring_config__sub__client_id',

	},
	{
		label: "Grant Type",
		dataIndex: 'monitoring_config__sub__grant_type',


	},
	{
		label: "Client secret",
		dataIndex: 'monitoring_config__sub__client_secret',
	},
	{
		label: "Product Name",
		dataIndex: 'monitoring_config__sub__product_name',

	},

]

export const FieldsForTribe = [
	
	{
		label: "Programs",
		dataIndex: 'programs',
		customRender: true,
		render: (val, row) => {
			const options = !row.error ? row.options.filter(option => val.indexOf(option.value) !== -1) : []
			return (
				<div>
					{options.map(item=>{
					return <Tag key={item.label}>{item.label}</Tag>
				})}
				</div>
			)
		}

	},
	{
		label: "Card Usage Groups",
		dataIndex: 'card_usage_group',
		customRender: true,
		render: (val, row) => {
			const options = !row.error ? row.options.filter(option => val.indexOf(option.value) !== -1) : []
			return (
				<div>
					{options.map(item=>{
					return <Tag key={item.label}>{item.label}</Tag>
				})}
				</div>
			)
		}

	},
	{
		label: "Fee Groups",
		dataIndex: 'fee_group',
		customRender: true,
		render: (val, row) => {
			const options = !row.error ? row.options.filter(option => val.indexOf(option.value) !== -1) : []
			return (
				<div>
					{options.map(item=>{
					return <Tag key={item.label}>{item.label}</Tag>
				})}
				</div>
			)
		}

	},
	{
		label: "Limit Groups",
		dataIndex: 'limit_group',
		customRender: true,
		render: (val, row) => {
			const options = !row.error ? row.options.filter(option => val.indexOf(option.value) !== -1) : []
			return (
				<div>
					{options.map(item=>{
					return <Tag key={item.label}>{item.label}</Tag>
				})}
				</div>
			)
		}

	}

]

export const FieldsForStripe = []

export const listFieldsForTypes = (processor) =>{

	switch(processor){
	  case ProcessorType.TRIBE.value :
		  return listfields.concat(FieldsForTribe)
	  case ProcessorType.STRIPE.value:
		  return listfields.concat(FieldsForStripe)
	  default:
		  return []
		  
	}

}