import { TransactionType } from "../../../const/enums"

const TransactionsSettlementsFilters = [

	{
        key:0,
        title: 'Pcardid',
        placeholder:'Pcardid',
        dataIndex: 'pcard_id'
    },
    {
        key:1,
        title: 'Card id',
        placeholder:'Card id',
        dataIndex: 'card_id'
    },
    {   
        key:2,
        title: 'Trx Type',
        placeholder:'Trx Type',
        dataIndex: 'trx_type',
        type: 'Select',
        options:TransactionType.asItemList(),
    },
    {
        key:3,
        title: 'Trx Date',
        dataIndex: 'trx_date',
        placeholder:["Trx Start Date", "Trx End Date"],
        rangeNames: ['before', 'after'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true]
    },
    {
        key:4,
        title: 'Auth Amount',
        placeholder:'Auth Amount',
        dataIndex: 'auth_amount'
    },
    {
        key:5,
        title: 'Settle Amount',
        placeholder: 'Settle Amount',
        dataIndex: 'settle_amount'
    },
    {
        key:6,
        title: 'Trans Link',
        placeholder:'Trans Link',
        dataIndex: 'trx_id'
    },
    {
        key:8,
        title: 'Settle Date',
        placeholder: 'Settle Date',
        dataIndex: 'settle_date',
		type: 'Date',
		rule: [{ required: false}],
    },
    // {
    //     key:11,
    //     title: 'Created At From',
	// 	dataIndex:'created_at',
	// 	rangeNames: ['after', 'before'],
	// 	rule: [{ required: false}],
	// 	type: 'DateRange',
	// 	allowEmpty: [true, true],
    // },

]
export default TransactionsSettlementsFilters