import { RequestTypes, TransactionType, TransactionStatus } from "../../../const/enums"

const TransactionsFilters = [
    {
        key:0,
        title: 'Holder Id',
        placeholder: 'Holder Id',
        dataIndex: 'holder_id',
		type: 'Input',
		rule: [{ required: false}]
    },
    {
        key:1,
        title: 'Account Id',
        placeholder: 'Account Id',
        dataIndex: 'account',
		type: 'Input',
		rule: [{ required: false}]
    },
    {
        key:2,
        title: 'Card id',
        placeholder:'Card id',
        dataIndex: 'card_id'
    },
	{
        key:3,
        title: 'FH Card id',
        placeholder:'FH Card id',
        dataIndex: 'pcard_id'
    },
    {
        key:4,
        title: 'Holder',
        placeholder: 'Holder',
        dataIndex: 'holder_name',
		type: 'Input',
		rule: [{ required: false}]
    },
    {
        key:5,
        title: 'Request Type',
        placeholder:'Request Type',
        dataIndex: 'request_type',
        type: 'Select',
        options:RequestTypes.asItemList()
    },
    {   
        key:6,
        title: 'Trx Type',
        placeholder:'Trx Type',
        dataIndex: 'trx_type',
        type: 'Select',
        options:TransactionType.asItemList()
    },
    {
		key:7,
		title: "Trx Currency",
        placeholder: 'Trx Currency',
		dataIndex: 'trx_currency',
        type: 'Currency',

	},
    {   
        key:8,
        title: 'Trx Status',
        placeholder:'Trx Status',
        dataIndex: 'trx_status',
        type: 'Select',
        options:TransactionStatus.asItemList()
    },
    {
        key:9,
        title: 'Auth Amount',
        placeholder:'Auth Amount',
        dataIndex: 'auth_amount'
    },
    {
        key:10,
        title: 'Settle Amount',
        placeholder: 'Settle Amount',
        dataIndex: 'settle_amount'
    },
    {
        key:11,
        title: 'Trans Link',
        placeholder:'Trans Link',
        dataIndex: 'trx_id'
    },
    {
        key:12,
        title: 'Client',
        placeholder:"Client",
		dataIndex:'client',
    },
    {
        key:13,
        title: 'Card PAN',
        placeholder: 'Card PAN',
        dataIndex: 'card_pan',
		type: 'Input',
		rule: [{ required: false}]
    },
    {
        key:14,
        title: 'Authorization ID',
        placeholder: 'Authorization ID',
        dataIndex: 'auth_id',
		type: 'Input',
		rule: [{ required: false}]
    },
    {
        key:15,
        title: 'Date of Birth',
        placeholder: 'Date of Birth',
        dataIndex: 'date_of_birth',
		type: 'Date',
		rule: [{ required: false}]
    },
    {
        key:16,
        title: 'Trx Date',
        dataIndex: 'trx_date',
        placeholder:["Trx Start Date", "Trx End Date"],
        rangeNames: ['before', 'after'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true]
    },
    {
        key:17,
        title: 'Settle Date',
        placeholder: 'Settle Date',
        dataIndex: 'settle_date',
		type: 'Date',
		rule: [{ required: false}]
    },

]
export default TransactionsFilters