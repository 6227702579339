import StatusIcon from "../../../components/StatusIcon";
import { Tag } from "antd";
const UserListFields = [
    {
        label: 'Username',
        dataIndex: 'username',
    },
    {
        label: 'First Name',
        dataIndex: 'first_name',
    },
    {
        label: 'Last Name',
        dataIndex: 'last_name',
    },
    {
        label: 'Email',
        dataIndex: 'email',
    },
    {
        label: 'Active',
        dataIndex: 'is_active',
        customRender: true,
        align: 'center',
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        label: 'Superuser',
        dataIndex: 'is_superuser',
        customRender: true,
        align: 'center',
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        label: 'Date Joined',
        dataIndex: 'date_joined',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <p>{date}</p>
            )
        }
    },
    {
        label: "Groups",
        dataIndex: 'groups',
        customRender: true,
        render: (val, row) => {
            const options = row.options;
            const selectedGroups = options.filter(option => val.indexOf(option.value) !== -1);

            if (selectedGroups.length > 0) {
                return (
                    <div>
                        {selectedGroups.map(item => (
                            <Tag key={item.value}>{item.label}</Tag>
                        ))}
                    </div>
                );
            } else {
                return '-';
            }
        }

    },

];

export default UserListFields