import StatusIcon from '../../../components/StatusIcon';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons'
const CardHoldersColumns = [
	{
		title: "Holder Id",
		dataIndex: 'id',
		customRender: true,
		extraDataIndexes: ['processor'],
		render: (id, row) => {
			return (
				<Link to={`/holders/${id}/${row.processor}/detail`}>
					{id}
				</Link>
			)
		}
	},
	{
		title: "FH Holder Id",
		dataIndex: 'fh_customer_id',
	},
	{
		title: "First Name",
		dataIndex: 'first_name',
		sorter: () => { }
	},
	// {
	// 	key: 2,
	// 	title: "Middle Name",
	// 	dataIndex: 'middle_name',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const middleName = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{middleName}
	// 			</p>
	// 		)
	// 	},
	// 	sorter: () => { }
	// },
	{
		title: "Last Name",
		dataIndex: 'last_name',
		sorter: () => { }
	},
	{
		title: "Client",
		dataIndex: 'client',
        customRender: true,
        render: (value, row) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Client:
                    </div>
                    <div className='responsiveInline'>
                        {value}
                    </div>
                </div>
            )
        }
	},
	{
		title: "Date of Birth",
		dataIndex: 'date_of_birth',
	},
	{
		title: "Email",
		dataIndex: 'email',
		customRender: true,
		render: (text) => {
			return (
				<div>
					<div className='responsiveTableData'>
						Email:
					</div>
					<div className='responsiveInline'>
						<a href={`mailto:${text}`}>
							{text}
						</a>
					</div>
				</div>
			)
		}
	},
	{
		title: "Created at",
		dataIndex: 'created_at',
		customRender: true,
		render: (value) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<div>
					<div className='responsiveTableData'>
						Created at:
					</div>
					<p className='responsiveInline'>
						{date}
					</p>
				</div>
			)
		},
		sorter: () => { }
	},
	{
		title: "Status",
		dataIndex: 'is_active',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<div>
					<div className='responsiveTableData'>
						Status:
					</div>
					<StatusIcon status={queStatus} />
				</div>
			)

		}
	},
	{
		title: "Nationality",
		dataIndex: 'country',
		align: 'center'
	},
	{
		title: "Has Card",
		dataIndex: 'has_card',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<div>
					<div className='responsiveTableData'>
						Status:
					</div>
					<StatusIcon status={queStatus} />
				</div>
			)

		}
	},
	{
		title: "Phone",
		dataIndex: 'phone',
		customRender: true,
		render: (text) => {
			return (
				<div>
					<div className='responsiveTableData'>
						Phone:
					</div>
					<div className='responsiveInline'>
						<a href={`tel:${text}`}>
							{text}
						</a>
					</div>
				</div>
			)
		}
	},
	{
		title: "Action",
		dataIndex: 'id',
		align: 'center',
		customRender: true,
		extraDataIndexes: ['processor'],
		render: (id, row) => {
			return (
				<Link to={`/holders/${id}/${row.processor}/detail`}>
					<EyeOutlined style={{ fontSize: '22px' }} />
				</Link>
			)
		}
	}
];

export default CardHoldersColumns