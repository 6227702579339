import HolderDetail from "./tabs/detail";
import HolderHistory from "./tabs/history";
import { useParams } from "react-router-dom";
import TabDetail from "../../components/TabDetail";
import { useNavigate } from "react-router-dom";
import HolderComments from "./tabs/comments";

function CardHoldersDetails() {

    const { id, processor, tab } = useParams();
    const navigateHandler = value => `/holders/${id}/${processor}/${value}`
    const navigate = useNavigate()

    const detailLink = `/holders/${id}/${processor}/detail`

    const navigateToCards = () => {
        const cardsLink = `/cards?page=1&holder_id=${id}`;
        navigate(detailLink)
        navigate(cardsLink);
    };

    const navigateToTransactions= () => {
        const transactionsLink = `/transactions?page=1&holder_id=${id}`;
        navigate(detailLink)
        navigate(transactionsLink);
    };

    const navigateToAccounts= () => {
        const accountsLink = `/accounts?page=1&holder_id=${id}`;
        navigate(detailLink)
        navigate(accountsLink);
    };

    const navigateToAuthorizations= () => {
        const authorizationsLink = `/transactions?page=1&request_type=cardAuthorization&holder_id=${id}`;
        navigate(detailLink)
        navigate(authorizationsLink);
    };

    const navigateToSettlements= () => {
        const settlementsLink = `/transactions?page=1&request_type=cardSettlement&holder_id=${id}`;
        navigate(detailLink)
        navigate(settlementsLink);
    };

    const breadCrumbItems = [
        {
            title: <a href="/holders">Card Holders</a>,
        },
        {
            title: "Holder"
        },
    ]

    const items = [
        {
            label: 'Details',
            key: 'detail',
            children:
                <HolderDetail holderId={id} processor={processor} />
        },
        {
            label: 'Cards',
            key: 'cards',
            children: tab === 'cards' ? navigateToCards() : null
        },
        {
            label: 'Accounts',
            key: 'accounts',
            children: tab === 'accounts' ? navigateToAccounts() : null
        },
        {
            label: 'Transactions',
            key: 'transactions',
            children: tab === 'transactions' ? navigateToTransactions() : null
        },
        {
            label: 'Authorizations',
            key: 'transactions_authorizations',
            children: tab === 'transactions_authorizations' ? navigateToAuthorizations() : null
        },
        {
            label: 'Settlements',
            key: 'transactions_settlements',
            children: tab === 'transactions_settlements' ? navigateToSettlements() : null
        },
        {
            label: 'History',
            key: 'history',
            children:
                <HolderHistory holderId={id} />
        },
        {
            label: 'Comments',
            key: 'comments',
            children:
                <HolderComments holderId={id} processor={processor} />
        },
    ]

    return (
        <TabDetail tab={tab} tabs={items} breadCrumbItems={breadCrumbItems} navigateHandler={navigateHandler} />
    );
}

export default CardHoldersDetails