import React from 'react';
import { LockOutlined, UserOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Alert, Layout } from 'antd';

function LoginForm({ onFinish, loginInfo, updateValue, passwordVisibilityToggle, validateAndSend }) {

    const { Content } = Layout;

    return (
        <Content>
            {loginInfo.error ? <Alert message={loginInfo.error} banner style={{ marginBottom: '20px', borderRadius: '5px' }} /> : null}
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input value={loginInfo.password}
                        onChange={updateValue}
                        name="username"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input
                        value={loginInfo.password}
                        onChange={updateValue}
                        name="password"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type={loginInfo.passwordVisibility ? 'password' : 'text'}
                        placeholder="Password"
                        suffix={
                            loginInfo.passwordVisibility ?
                                <EyeInvisibleOutlined
                                    onClick={passwordVisibilityToggle}
                                    className="site-form-item-icon" style={{ cursor: 'pointer' }} />
                                :
                                <EyeOutlined
                                    onClick={passwordVisibilityToggle}
                                    className="site-form-item-icon" style={{ cursor: 'pointer' }} />
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <a className="login-form-forgot" href="">
                        Forgot password
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button loading={loginInfo.progress} onClick={validateAndSend} type="primary" htmlType="submit" className="login-form-button" danger>
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    )
}

export default LoginForm