import { isonToCurrencyName } from '../../../components/CountrySelector';
import { LoadType } from '../../../const/enums';
import { currencyBuilder } from '../../../const/Internalization'
import { Link } from "react-router-dom";
const BalanceColumns = (openModal) => [
	{
		title: "Processor",
		dataIndex: 'processor',
		customRender: true,
		render: (value, row) => {
			return (
				<Link onClick={() => openModal(row.processor_id, value)}>
					<div className='responsiveTableData'>
						Processor:
					</div>
					<div className='responsiveInline'>
						<p className='responsiveInline'>
							{row.processor_name}
						</p>
					</div>
				</Link>
				// <a href={`/clients/detail/${row.processor_id}/processor/${value}`}>
				// 	<div className='responsiveTableData'>
				// 		Processor:
				// 	</div>
				// 	<div className='responsiveInline'>
				// 		<p className='responsiveInline'>
				// 			{row.processor_name}
				// 		</p>
				// 	</div>
				// </a>

			)
		}

	},
	{
		title: "Account",
		dataIndex: 'account',
		customRender: true,
		render: (value) => {

			const account = value ? value : '-'
			return (
				<p className='responsiveInline'>
					{account}
				</p>
			)
		},
	},
	{
		title: "Amount",
		dataIndex: 'amount',
        sorter: () => { },
		customRender: true,
		render: (value, row) => {

			const currency = isNaN(value) ? '-' : currencyBuilder(row.currency).format(value)
			return (
				<p className='responsiveInline'>
					{currency}
				</p>
			)
		},

	},
	{
		title: 'Currency',
		dataIndex: 'currency',
		customRender: true,
		render: (value, row) => {
			return (
				<p className='responsiveInline'>
					{isonToCurrencyName(value)}
				</p>
			)
		}

	},
	{
		title: 'Load Type',
		dataIndex: 'load_type',
		customRender: true,
        sorter: () => { },
		render: (status) => {

			return (
				<p className='responsiveInline'>
					{LoadType.ItemFromValue(status)}
				</p>
			)
		}

	},
	{
		title: 'Description',
		dataIndex: 'description',
		customRender: true,
		render: (value) => {

			const description = value ? value : '-'
			return (
				<p className='responsiveInline'>
					{description}
				</p>
			)
		},

	},
	{
		title: 'Created by',
		dataIndex: 'created_by_username',
		customRender: true,
		render: (value) => {

			const createdBy = value ? value : '-'
			return (
				<p className='responsiveInline'>
					{createdBy}
				</p>
			)
		},

	},
	{
		title: 'Created at',
		dataIndex: 'created_at',
		customRender: true,
		render: (value) => {

			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<p className='responsiveInline'>
					{date}
				</p>
			)
		},

	},
	// {
	// 	key: 8,
	// 	title: "Action",
	// 	dataIndex: 'id',
	// 	align: 'center',
	// 	customRender: true,
	// 	render: (id) => {
	// 		return (
	// 			<Link to={`/clients/${id}/detail`}>
	// 				<EyeOutlined style={{ fontSize: '22px' }} />
	// 			</Link>
	// 		)
	// 	}
	// }

];

export default BalanceColumns