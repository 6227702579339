import React from 'react'
import { endpoint } from '../../../AppGlobal';
import Role from '../../../const/role';
import CollapsedSearchLayout from '../../../components/CollapseSearchListView';
import LimitGroupsColumns from './components/LimitGroupsColumns';
import LimitGroupsDetailsModal from './components/LimitGroupsDetailsModal';
import DrawerModal from '../../../components/DrawerModal';

function LimitGroups() {

    const fetchMethod = endpoint.getMethod('fetchLimitGroups');

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    return (

        <div>
            <CollapsedSearchLayout
                columns={LimitGroupsColumns(openModal)} // remove openModal for the old details page
                fetchMethod={fetchMethod}
                filters={[]}
                barRoles={[Role.LimitGroupEditor, Role.LimitGroupAdmin]}
                searchRoles={[]}
                barAddURL="/limitgroups/create"
                title={"Limit Groups"}
            />
            {selectedItem && (
                <LimitGroupsDetailsModal
                    visible={modalVisible}
                    limitgroup={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // For the old details page uncomment this, comment above div.

        // <CollapsedSearchLayout
        //     columns={LimitGroupsColumns}
        //     fetchMethod={fetchMethod}
        //     filters={[]}
        //     barRoles={[Role.Superuser]}
        //     barAddURL="/limitgroups/create"
        //     title={"Limit Groups"}
        // />
    )

}

export default LimitGroups