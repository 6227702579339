import { Form, Space, Row, Col, Button, Spin, Table } from "antd"
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons"
import ProTable from '@ant-design/pro-table'
import { SaveOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import FormBuilder from "./Form"
import { FormNormalizeForNestedFields, FormConvertDictNestedFields } from "./Form"
import { useDispatch } from "react-redux";
import Endpoint from "../api/endpoint";
import { createNotification } from "../stores/reducers/notificationreducer";
import { errorMessageHandler } from "../utils/ErrorHandler"
import Role from "../const/role"
import { roleCheckForUser } from "../utils/RoleCheck"
import {useSelector} from 'react-redux'
export const View = ({ listData }) => {
	// Define columns based on the structure of your listData
	const columns = [
	  {
		dataIndex: 'label',
		key: 'label',
		render: (text, record) => (
		  <>
			{record.noLabel === undefined || record.noLabel === false ? (
			  <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{text}:</span>
			) : null}
		  </>
		),
		width: '20%',
	  },
	  {
		dataIndex: 'value',
		key: 'value',
		width: '80%',
		render: (text, record) =>
		  record.customRender ? record.render(text, record) : text,
	  },
	];
  
	return (
	  <>
		<ProTable
		  dataSource={listData}
		  columns={columns}
		  rowKey={(record) => 'item' + record.label + '_' + record.dataIndex}
		  pagination={false}
          search={false}
          // toolBarRender={false}
          // options={false}
          defaultSize='small' // means compact
		  toolBarRender={false}
		/>
	  </>
	);
  };

export const EditView = ({
	formFields,
	tempItem,
	currentItem,
	updateHandler,
	progress = false,
	finishHandler
}) => {

	let blockUpdate = true;
	Object.keys(tempItem).forEach(key => {
		if (tempItem[key] !== currentItem[key] && tempItem[key] !== undefined) {
			blockUpdate = false;

		}

	})

	const [form] = Form.useForm()
	useEffect(() => {
		form.setFieldsValue(currentItem)
	}, [])



	const onFinish = (values) => {
		form.validateFields({ validateOnly: true }).then(
			() => {
				finishHandler(values)
			}
		)

	}

	

	return (
		<FormBuilder disabled={progress} layout='vertical' models={formFields} onFinishHandler={onFinish} handler={(value,error)=>{
			updateHandler(value,error,form.getFieldsValue())
		}} form={form}>

			<Space>
				<Row>
					<Col className='formItemInline' xs={24} sm={12} xxl={12} >
						<Button loading={progress} type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={blockUpdate} > Save</Button>
					</Col>

				</Row>
			</Space>
		</FormBuilder>
	)

}
export const EditableListView = ({
	id,
	updateMethod,
	fetchMethod,
	listFields,
	formFields,
	title,
	watchHandler,
	fetchHandler,
	groupsForEdit = [Role.ANY],
	setUpdateDispatcher = 0
}) => {

	const { user } = useSelector(state => state.authreducer);
	const [isEditing, setIsEditing] = useState(false);
	const [progress, setProgress] = useState(false);
	const [item, setItem] = useState(null);
	const [tempItem, setTempItem] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const canEdit = roleCheckForUser(user, groupsForEdit) && updateMethod
	async function execFetch() {
		setLoading(true);
		var response = await fetchMethod(id);
		if (response._status === Endpoint.status.success) {
			if(fetchHandler){
				fetchHandler(response)
			}
			delete response._status
			response = FormNormalizeForNestedFields(response)
			setItem(response)
			setTempItem({ ...response })
		} else {
			const message = errorMessageHandler(response)
			dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
		}
		setLoading(false);
	}


	useEffect(() => {
		execFetch()
	}, [setUpdateDispatcher])

	const updateHandler = (value, errors, values ) => {
		setTempItem(value);
		if(watchHandler != null && watchHandler !== undefined){
		    watchHandler(value,values)
		}
	}

	const submitHandler = async (values) => {
		setProgress(true)
		const updatedItem = {...item,...tempItem,...values}
		values = FormConvertDictNestedFields(values)
		const response = await updateMethod(id, values)
		if (response._status === Endpoint.status.success) {
           
			setIsEditing(false);
			setItem(updatedItem);
			dispatch(createNotification({ title: 'Success', message: `${title} have been saved.`, type: 'success' }));
			setTimeout(() => {
				window.location.reload(true); // For the drawer form of the details, after updating reload the page for the updated values.
			}, 500); 

		} else {
			const message = errorMessageHandler(response)
			dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
		}
		setProgress(false);


	}

	const listItems = item != null ? listFields.map(field => {
		const value = item[field.dataIndex]
		field.value = value !== undefined || value !== null ? value : '';
		return field;
	}).filter(field => field.value !== undefined) : [];

	return (
		isEditing === false ? (
			<>
				<div className="listHeader">
					<h3>{title}</h3>
					{

						loading ? (

							<div style={{ minHeight: '250px' }}>
								<Spin spinning={loading}></Spin>
							</div>
						) : (
							canEdit &&(
								<Button onClick={() => { setIsEditing(true) }} icon={<EditOutlined />}>Edit</Button>

								)
						)


					}
				</div>
				<View listData={listItems} />



			</>

		) : (
			<>
				<Button style={{ marginBottom: '16px' }} onClick={() => { setIsEditing(false) }} icon={<CloseCircleOutlined />}>Cancel</Button>
				<EditView tempItem={tempItem} currentItem={item} updateHandler={updateHandler} progress={progress} formFields={formFields} finishHandler={submitHandler} />
			</>
		)
	)
}