
export class AuthInfo{
	#user = null
	constructor(access, refresh) {
		this.access = access;
		this.refresh = refresh;
		const decoded = AuthInfo.decodeAccessToken(this.access)
		this.expire = new Date(decoded.exp * 1000);
		this.user = {
			first_name:decoded.user.first_name,
	   		last_name:decoded.user.last_name,
       		is_superuser:decoded.user.is_superuser,
	   		groups:decoded.user.groups,
	   		id:decoded.user_id,
			processor:decoded.user.processor
		}
		
	}
	static decodeAccessToken(token){
		return JSON.parse(atob(token.split('.')[1]));
	}

	get isExpired() {
         
		return this.expire < new Date()
	}

}

export class User{
	constructor(authInfo) {
       const decoded = AuthInfo.decodeAccessToken(authInfo.access)
	   this.first_name = decoded.user.first_name
	   this.last_name = decoded.user.last_name
       this.is_superuser = decoded.user.is_superuser
	   this.groups = decoded.user.groups
	   this.id = decoded.user_id
	   this.processor = decoded.user.processor
	}
	get asObject() {
		return {
			first_name:this.first_name,
	   		last_name:this.last_name,
       		is_superuser:this.is_superuser,
	   		groups:this.groups,
	   		id:this.id,
			processor: this.processor
		}
	}
}


