const ClientFilters = [

	{
		key: 0,
		title: 'Name',
		placeholder: 'Name',
		dataIndex: 'client_name',
		type: 'Input',
		sizes: {
			xxl: 8,
			xl: 8,
			lg: 8,
			xs: 24
		}
	},
	{
		key: 1,
		title: 'Status',
		placeholder: 'Select Status',
		dataIndex: 'status',
		rule: [{
			required: false,

		}
		],
		type: 'Select',
		sizes: {
			xxl: 8,
			xl: 8,
			lg: 8,
			xs: 24
		},
		options: [
			{
				key: 0,
				label: 'Active',
				value: 1
			},
			{
				key: 1,
				label: 'Not Active',
				value: 0
			}
		]
	},
	{
		key: 2,
		title: 'Created At From',
		dataIndex: 'created_at',
		rangeNames: ['after', 'before'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true],
		sizes: {
			xxl: 8,
			xl: 8,
			lg: 8,
			xs: 24
		}

	}




]
export default ClientFilters