import React from 'react';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import UserManagementColumns from './components/UserManagementColumns';
import UserManagementFilters from './components/UserManagementFilters';
import { endpoint } from '../../AppGlobal';
import Role from '../../const/role';
import UserDetailsModal from './components/UserDetailsModal';
import DrawerModal from '../../components/DrawerModal';

function UserManagement() {
    const fetchMethod = endpoint.getMethod('fetchUsers');

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    return (
        <>
            <CollapsedSearchLayout
                columns={UserManagementColumns(openModal)} // remove openModal for the old details page
                fetchMethod={fetchMethod}
                filters={UserManagementFilters}
                barRoles={[Role.UserEditor, Role.UserAdmin]}
                barAddURL="/users/create"
                title={"Users"}
            />
            {selectedItem && (
                <UserDetailsModal
                    visible={modalVisible}
                    user={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </>
    );
}

export default UserManagement;