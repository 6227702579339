const LogsFilters = [
    {
        key:1,
        title: 'Search',
        placeholder:'Search',
        dataIndex: 'search',
        sizes: {
			xxl: 12,
			xl: 12,
			lg: 12,
			xs: 24
		}
    },
    {
        key:2,
        title: 'Log Date',
        dataIndex: 'logs_date',
        placeholder:["Start Date", "End Date"],
        rangeNames: ['start_date', 'end_date'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true],
        sizes: {
			xxl: 12,
			xl: 12,
			lg: 12,
			xs: 24
		}
    },
    // {
    //     key:2,
    //     title: 'Start Date',
    //     dataIndex: 'start_date',
    //     placeholder:'Start Date',
	// 	rule: [{ required: false}],
	// 	type: 'Date',
    //     sizes: {
	// 		xxl: 8,
	// 		xl: 8,
	// 		lg: 8,
	// 		xs: 24
	// 	}
    // },
    // {
    //     key:3,
    //     title: 'End Date',
    //     dataIndex: 'end_date',
    //     placeholder:'End Date',
	// 	rule: [{ required: false}],
	// 	type: 'Date',
    //     sizes: {
	// 		xxl: 8,
	// 		xl: 8,
	// 		lg: 8,
	// 		xs: 24
	// 	}
    // },
]

export default LogsFilters