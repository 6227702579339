import UserFormFields from './components/UserEditFields'
import UserListFields from './components/UserListFields'
import { endpoint } from '../../AppGlobal'
import { EditableListView } from '../../components/EditableItemView'
import Role from '../../const/role'
import { useParams } from 'react-router-dom'
import DetailLayout from '../../Layout/detail'
import Endpoint from '../../api/endpoint'
function UserDetail() {

    const { id } = useParams();

    const breadCrumbItems = [
        {
            title: <a href="/user">User Management</a>,
        },
        {
            title: "User"
        },
    ]

    const fetchMethod = async (id) => {
        const response = await endpoint.getMethod('fetchUserDetails')(id);
    
        if (response._status === Endpoint.status.success) {
            const groupsField = UserFormFields.find(item => item.dataIndex === 'groups');
            const groupsListField = UserListFields.find(item => item.dataIndex === 'groups');
    
            const responseGroups = await endpoint.fetchGroups();
    
            if (responseGroups._status === Endpoint.status.success) {
                groupsListField.options = groupsField.options = responseGroups.results.map(item => ({label: item.name,value: item.id}));
                groupsField.inprogress = false;
            } else {
                groupsField.inprogress = false;
                groupsField.error = responseGroups.message || 'An error occurred';
            }
        }
    
        return response;
    };

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <EditableListView id={id}
                listFields={UserListFields}
                formFields={UserFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateUserDetails')}
                title='Details'
                groupsForEdit={[Role.UserEditor, Role.UserAdmin]}
            />
        </DetailLayout>
    )
}

export default UserDetail