import StatusIcon from "../../../../components/StatusIcon";
import { ProcessorType } from "../../../../const/enums";
import { isonToCurrencyName } from "../../../../components/CountrySelector";
import { EyeOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
const ProgramsColumns = (openModal) => [
    {
        title: 'Processor',
        dataIndex: 'processor_type',
        customRender: true,
        render: (type) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Type:
                    </div>
                    <div style={{ display: 'flex' }}>
                        {ProcessorType.ItemFromValue(type)}
                    </div>
                </div>
            )
        }
    },
    {
        title: 'Program ID',
        dataIndex: 'program_id'
    },
    {
        title: 'Name',
        dataIndex: 'name'
    },
    {
        title: 'Default Currency',
        dataIndex: 'default_currency',
        customRender: true,
		render: (value, row) => {
			return (
				<p className='responsiveInline'>
					{isonToCurrencyName(value)}
				</p>
			)
		}
    },
    {
        title: 'Enabled',
        dataIndex: 'is_enabled',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        title: 'Action',
        dataIndex: 'id',
        align: 'center',
        customRender: true,
        render: (id, row) => {
            return (
                //comment the Link with openModal and uncomment the other one for the old details page.
                
                <Link onClick={() => openModal(id)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
                // <a href={`/programs/${id}`}>
                //     <EyeOutlined style={{ fontSize: '22px' }} />
                // </a>
            )
        }
    },
    
];

export default ProgramsColumns