import Role from "./role"
import {
	DashboardOutlined,
	UserOutlined,
	TransactionOutlined,
	CreditCardOutlined,
	IdcardOutlined,
	PaperClipOutlined,
	BankOutlined,
	TeamOutlined,
	DollarOutlined,
	SettingOutlined,
	FileTextOutlined
} from '@ant-design/icons';
const APPROUTES = {
    
	dashboard: {
		path:"/",
		roles:[Role.ANY],
		label: 'Dashboard',
		key: '1',
		icon: <DashboardOutlined />,

	},
	transactions:{
		roles:[Role.TransactionReader, Role.TransactionEditor, Role.TransactionAdmin],
		label: 'Transactions',
		key: '2',
		icon: <TransactionOutlined />,
		children: [
			{
				path: '/transactions',
				roles: [Role.TransactionReader, Role.TransactionEditor, Role.TransactionAdmin],
				label: 'Transactions',
				parent: '2',
				key: '3',
				icon: <TransactionOutlined />,
			},
			{
				path: '/authorizations',
				roles: [Role.TransactionReader, Role.TransactionEditor, Role.TransactionAdmin],
				label: 'Authorizations',
				parent: '2',
				key: '4',
				icon: <TransactionOutlined />,
			},
			{
				path: '/settlements',
				roles: [Role.TransactionReader, Role.TransactionEditor, Role.TransactionAdmin],
				label: 'Settlements',
				parent: '2',
				key: '5',
				icon: <TransactionOutlined />,
			}
		]
		
	},
	// reporting:{
	// 	path:'/reporting',
	// 	roles: [Role.ReportingReader, Role.ReportingEditor, Role.ReportingAdmin],
	// 	label: 'Reporting',
	// 	key: '3',
	// 	icon: <PaperClipOutlined />,
		
	// },
	cardHolders:{
		path:'/holders',
		roles:[Role.HolderReader, Role.HolderEditor, Role.HolderAdmin],
		label: 'Holders',
		key: '8',
		icon: <CreditCardOutlined />,
		
	},
	accounts:{
		path:'/accounts',
		roles:[Role.AccountReader, Role.AccountEditor, Role.AccountAdmin],
		label: 'Accounts',
		key: '10',
		icon: <BankOutlined />
		
	},
	cardManagement:{
		path:'/cards',
		roles:[Role.CardReader, Role.CardEditor, Role.CardAdmin],
		label: 'Cards',
		key: '9',
		icon: <IdcardOutlined />,
		
	},
	balanceManagement:{
		path:'/balance',
		roles:[Role.BalanceReader, Role.BalanceEditor, Role.BalanceAdmin],
		label: 'Balance',
		key: '11',
		icon: <DollarOutlined />,
		
	},
	clientManagment:{
		path:'/clients',
		roles: [Role.ClientReader,Role.ClientEditor,Role.ClientAdmin],
		label: 'Clients',
		key: '6',
		icon: <UserOutlined />,
		
	},
	userManagement:{
		path:'/users',
		roles:[Role.UserReader, Role.UserEditor, Role.UserAdmin],
		label: 'Users',
		key: '7',
		icon: <TeamOutlined />
		
	},
	logs:{
		path:'/logs',
		roles:[Role.Superuser],
		label: 'Logs',
		key: '12',
		icon: <FileTextOutlined />,
		
	},
	settings:{
		roles:[Role.Superuser],
		label: 'Settings',
		key: '13',
		icon: <SettingOutlined />,
		children: [
			// {
			// 	path: '/settings',
			// 	roles: [Role.Superuser],
			// 	label: 'Settings',
			// 	parent: '11',
			// 	key: '22',
			// 	icon: <TransactionOutlined />,
			// },
			{
				path: '/passfort',
				roles: [Role.PassfortAdmin],
				label: 'Passfort',
				parent: '13',
				key: '14',
				icon: <SettingOutlined />,
			},
			{
				path: '/programs',
				roles: [Role.ProgramAdmin],
				label: 'Programs',
				parent: '13',
				key: '15',
				icon: <SettingOutlined />,
			},
			{
				path: '/feegroups',
				roles: [Role.FeeGroupAdmin],
				label: 'Fee groups',
				parent: '13',
				key: '16',
				icon: <SettingOutlined />,
			},
			{
				path: '/limitgroups',
				roles: [Role.LimitGroupAdmin],
				label: 'Limit Groups',
				parent: '13',
				key: '17',
				icon: <SettingOutlined />,
			},
			{
				path: '/usagegroups',
				roles: [Role.UsageGroupAdmin],
				label: 'Usage Groups',
				parent: '13',
				key: '18',
				icon: <SettingOutlined />,
			},
		]
		
	},

}
export default APPROUTES