import {useParams } from "react-router-dom";
import ClientDetail from "./tabs/detail";
import ProcessorView from "./tabs/processor";
import TabDetail from "../../components/TabDetail";
import ClientHistory from "./tabs/history";
import ClientIPAddresses from "./tabs/ipaddresses";

function ClientManagementDetails() {
    const { id, tab} = useParams();
    const navigateHandler = value => `/clients/${id}/${value}`
    const breadCrumbItems = [
        {
            title: <a href="/clients">Client Management</a>,
        },
        {
            title: "Client"
        },
    ]

    const items = [
        {
            label: 'Client Details',
            key: 'detail',
            children:

                <ClientDetail clientId={id} />

        },
        {
            label: 'Processor',
            key: 'processor',
            children:
                <ProcessorView clientId={id}/>
        },
        {
            label: 'History',
            key: 'clientHistory',
            children:
                <ClientHistory clientId={id}/>
        },
        {
            label: 'IP Addresses',
            key: 'ipaddresses',
            children:
                <ClientIPAddresses clientId={id}/>
        },
    ]

    return (
        <TabDetail tab={tab} tabs={items} breadCrumbItems={breadCrumbItems} navigateHandler={navigateHandler} />
    );
}

export default ClientManagementDetails