import { endpoint } from "../../../AppGlobal";
import { EditableListView } from "../../../components/EditableItemView";
import TransactionsDetailListFields from "../../TransactionsDetail/components/TransactionsDetailListFields";
import { Drawer } from 'antd';
import CardDetailsModal from "../../CardManagementPage/components/CardManagementDetailsModal";
import { useState } from "react";

const TransactionDetailsModal = ({ visible, transaction, closeModal }) => {
    const trx_id = transaction ? transaction[0] : null;
    const req_type = transaction ? transaction[1] : null;
    // const { trx_id, req_type } = useParams();
    const fetchMethod = async () => {
        if (trx_id && req_type) {
            const response = await endpoint.fetchTransactionsDetail(trx_id, req_type);
            return response;
        }
        return null;
    };

    const [selectedCard, setSelectedCard] = useState(null);
    const [cardModalVisible, setCardModalVisible] = useState(false);

    const openCardModal = (card_id) => {
        setSelectedCard(card_id);
        setCardModalVisible(true);
    };

    const closeCardModal = () => {
        setSelectedCard(null);
        setCardModalVisible(false);
    };

    return (
        <Drawer
            title="Transaction Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700}
        >
            <EditableListView
                id={trx_id}
                listFields={TransactionsDetailListFields(openCardModal)}
                fetchMethod={fetchMethod}
            />
            {selectedCard && (
                    <CardDetailsModal
                        visible={cardModalVisible}
                        card={selectedCard}
                        closeModal={closeCardModal}
                    />
                )}
        </Drawer>
    );
};

export default TransactionDetailsModal;