import { endpoint } from '../../../AppGlobal'
import { EditableListView } from '../../../components/EditableItemView'
import Role from '../../../const/role'
import { useParams } from 'react-router-dom'
import DetailLayout from '../../../Layout/detail'
import LimitGroupsListFields from './components/LimitGroupsListFields'
import LimitGroupsFormFields from './components/LimitGroupsFormFields'
function LimitGroupsDetail() {

    const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchLimitGroupsDetail');

    const breadCrumbItems = [
        {
            title: <a href="/limitgroups">Limit Groups</a>,
        },
        {
            title: "Limit Group"
        },
    ]

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <EditableListView id={id}
                listFields={LimitGroupsListFields}
                formFields={LimitGroupsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateLimitGroupsDetails')}
                title='Details'
                groupsForEdit={[Role.LimitGroupEditor, Role.LimitGroupAdmin]}
            />
        </DetailLayout>
    )
}

export default LimitGroupsDetail