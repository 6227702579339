import {Outlet,Navigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { roleCheckForUser } from '../utils/RoleCheck';


const PrivateRoute = (props) => {
	const { user } = useSelector(state => state.authreducer);
	const isLoggedIn = user != null;
	if(!isLoggedIn) {
		return  <Navigate to="/login" />
	}
    

	const hasRoleIntersection = roleCheckForUser(user,props.roles);
  
	return isLoggedIn && hasRoleIntersection ? <Outlet /> : <Navigate to="/accessdenied" />;
  };
  


export default PrivateRoute