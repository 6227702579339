import { LoadType } from '../../../const/enums';

const BalanceFilters = [
	{
		key: 0,
		title: "Processor",
		placeholder: "Processor",
		dataIndex: 'processor_name',
		type: 'Input'

	},
	{
		key: 1,
		title: "Amount",
		placeholder: "Amount",
		dataIndex: 'amount',
		type: 'Input'

	},
	{
		key: 2,
		title: 'Currency',
		placeholder: 'Currency',
		dataIndex: 'currency',
		type: 'Currency'

	},
	{
		key: 3,
		title: 'Load Type',
		placeholder: 'Load Type',
		dataIndex: 'load_type',
		type: 'Select',
        options:LoadType.asItemList(),
        sizes: {
			xxl: 6,
			xl: 6,
			lg: 6,
			xs: 24
		}
	},
	{
        key:4,
        title: 'Created At From',
		dataIndex:'created_at',
		rangeNames: ['after', 'before'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true],
		sizes: {
			xxl: 6,
			xl: 6,
			lg: 6,
			xs: 24
		}
    },

];

export default BalanceFilters