import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons'
import { ProcessorType } from '../../../const/enums';
import { currencyBuilder } from '../../../const/Internalization';
export const ProcessorColumns = (openModal) => [
	{
		key: 1,
		title: "Name",
		dataIndex: 'name',
		sorter: () => { }
	},
	{
		key: 2,
		title: "Type",
		dataIndex: 'type',
		sorter: () => { },
		customRender: true,
		render: (type) => {
			return <div><div className='responsiveTableData'>Type:</div><div style={{ display: 'flex' }}>{ProcessorType.ItemFromValue(type)}</div></div>
		}

	},
	{
		key: 3,
		title: "Master Account balances",
		dataIndex: 'master_account_balance',
		customRender: true,
		render: (value, row) => {

			const currency = isNaN(value) ? '-' : currencyBuilder(row.currency_ison).format(value / 100)
			return (
				<div>
					<div className='responsiveTableData'>
						Master Account balances:
					</div>
					<p className='responsiveInline'>
						{currency}
					</p>
				</div>
			)
		},
		sorter: () => { }
	},
	{
		key: 4,
		title: "KYC",
		dataIndex: 'kyb',
	},
	{
		key: 45,
		title: "Action",
		dataIndex: 'id',
		align: 'center',
		customRender: true,
		render: (id, row) => {
			return (
				//comment the Link with openModal and uncomment the other one for the old details page.

				<Link onClick={() => openModal(row.user, id)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
				// <Link to={`/clients/detail/${row.user}/processor/${id}`}>
				// 	<EyeOutlined style={{ fontSize: '22px' }} />
				// </Link>
			)
		}
	}

];

export default ProcessorColumns