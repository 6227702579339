import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import GenericTable from './GenericTable';
import Endpoint from '../api/endpoint';
import { useDispatch } from "react-redux";
import { createNotification } from '../stores/reducers/notificationreducer';
import { errorMessageHandler } from '../utils/ErrorHandler';
import FilterForm from './FilterForm';

function ListPage({ fetchMethod, columns, searchFilters, searchVisible = true, setUpdateDispatcher = 0, title }) {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});
    const dispatch = useDispatch()
    const { Content } = Layout;

    const pageHandler = (newPage) => {
        setPage(newPage);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const orderData = await fetchMethod(page, filter);
            if (orderData._status === Endpoint.status.success) {
                setDataSource([])
                setDataSource(orderData);
            } else {
                const message = errorMessageHandler(orderData)
                dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
            }

            setLoading(false);
        };

        fetchData();
    }, [page, filter, setUpdateDispatcher]);

    const filterHandler = (filter) => {
        setFilter(filter);
    };

    const onChangeHandler = (pagination, filters, sorter, extra) => {
        let ordering = ''
        if (sorter !== undefined) {
            ordering = `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`
        }
        const newFilter = { ...filter, ordering: ordering }
        setFilter(newFilter);
    }

    const generateCSVData = () => {
        const csvData = dataSource.map(item => ({
            Title: item.title,
            Quantity: item.quantity,
            Price: item.discountedPrice
        }));

        return csvData;
    };

    return (
        <Content>
            {
                searchFilters && (
                    <Content  className={`filterForm ${searchVisible ?  'collapsed': 'fold'}`} >
                        <FilterForm models={searchFilters} filterHandler={filterHandler} />
                    </Content>
                )
            }
            {/* Render the CardHoldersTableHeader component */}

            {/* Render the CardHoldersTable component */}
            <GenericTable
                dataSource={dataSource}
                loading={loading}
                columns={columns}
                pageSize={50}
                currentPage={page}
                pageHandler={pageHandler}
                onChangeHandler={onChangeHandler}
                title={title}
            />

        </Content>

    );
}

export default ListPage;