import { Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
const ClientIPColumns = (handleDeleteIP) =>[
    {
        dataIndex: "ip",
        title: "IP Address",
        key: 0,
    },
    {
        key: 4,
        title: "Action",
        dataIndex: 'id',
        align: 'center',
        customRender: true,
        render: (text, record) => {
            return (
                <Popconfirm
                    title="Are you sure you want to delete this IP?"
                    onConfirm={() => handleDeleteIP(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" icon={<DeleteOutlined />} danger>
                        Delete
                    </Button>
                </Popconfirm>
            )
        }
    }

];


export default ClientIPColumns