import { endpoint } from '../../../../AppGlobal'
import { EditableListView } from '../../../../components/EditableItemView'
import Role from '../../../../const/role'
import UsageGroupsListFields from '../../../SettingsDetail/UsageGroupsDetail/components/UsageGroupsListFields'
import UsageGroupsFormFields from '../../../SettingsDetail/UsageGroupsDetail/components/UsageGroupsFormFields'
import { Drawer } from 'antd'
function UsageGroupsDetailsModal({ visible, usagegroup, closeModal }) {

    // const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchUsageGroupsDetail');

    // const breadCrumbItems = [
    //     {
    //         title: <a href="/usagegroups">Usage Groups</a>,
    //     },
    //     {
    //         title: "Usage Group"
    //     },
    // ]

    return (
        <Drawer
            title="Usage Group Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700}
        >
            <EditableListView id={usagegroup}
                listFields={UsageGroupsListFields}
                formFields={UsageGroupsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateUsageGroupsDetails')}
                title='Details'
                groupsForEdit={[Role.UsageGroupEditor, Role.UsageGroupAdmin]}
            />
        </Drawer>
    )
}

export default UsageGroupsDetailsModal