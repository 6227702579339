import React, { useState } from "react";
import LoginLogo from './components/LoginLogo';
import LoginForm from './components/LoginForm';
import Endpoint from "../../api/endpoint";
import { endpoint } from "../../AppGlobal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../stores/reducers/authreducer";
import { Card } from 'antd';

function Login() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    //const dispatch = useContext(AuthDispatchContext);
    const [loginInfo, setLoginInfo] = useState({
        username: '',
        password: '',
        usernameError: false,
        passwordError: false,
        progress: false,
        error: null,
        passwordVisibility: true
    })

    //dispatch.dispatch({name:'ali',type:AuthActions.login});

    const passwordVisibilityToggle = e => setLoginInfo(prevState => ({ ...prevState, passwordVisibility: !loginInfo.passwordVisibility }))


    const updateValue = (e) => {
        setLoginInfo(prevState => ({ ...prevState, [e.target.name]: e.target.value, [e.target.name + 'Error']: e.target.value.length === 0 }))
    }

    const validateAndSend = async () => {

        const newState = {
            ...loginInfo,
            error: null,
            usernameError: loginInfo.username.length === 0,
            passwordError: loginInfo.password.length === 0,
            progress: loginInfo.username.length > 0 && loginInfo.password.length > 0
        }
        setLoginInfo(prevState => (newState))

        if (!newState.usernameError && !newState.passwordError) {
           
            let response = await endpoint.login({ username: loginInfo.username, password: loginInfo.password })
            if (response._status === Endpoint.status.error) {

                setLoginInfo(prevState => ({ ...prevState, progress: false, error: response.message }));
            } else {
                //dispatch.dispatch({userInfo:{user:response, isLoggedIn:true}, type:AuthActions.login})
                dispatch(login(response))
                navigate('/')
            }

        }
    }


    return (
        <div className='layout'>
            <div className="container">
                <Card className="contentCard login">
                    <LoginLogo />
                    <LoginForm
                        loginInfo={loginInfo}
                        updateValue={updateValue}
                        passwordVisibilityToggle={passwordVisibilityToggle}
                        validateAndSend={validateAndSend}
                    />
                </Card>
            </div>
        </div>
    )
}

export default Login