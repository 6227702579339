import React from 'react';
import { Select, Space } from 'antd';
import countries from '../const/country.json'

export const CounrtrieItems = countries.map((country, index) => { return { label: country.countryName, value: country.isoNumeric3, key: index } })
export const CurrencyItems = Array.from(new Set(countries.map(country => country.currencyCode)))
    .map(item => {
        return { label: item, value: countries.find(country => country.currencyCode === item).currency_ison };
    })
    .sort((a, b) => {
        // Define the priority currencies
        const priorityCurrencies = ['GBP', 'USD', 'EUR', 'TRY'];

        // Check if either a or b is in the priority currencies
        const aIndex = priorityCurrencies.indexOf(a.label);
        const bIndex = priorityCurrencies.indexOf(b.label);

        // If both a and b are in priority currencies, sort them based on their index
        if (aIndex !== -1 && bIndex !== -1) {
            return aIndex - bIndex;
        }

        // If only a is in priority currencies, place it before b
        if (aIndex !== -1) {
            return -1;
        }

        // If only b is in priority currencies, place it before a
        if (bIndex !== -1) {
            return 1;
        }

        // For other currencies, sort alphabetically
        return a.label.localeCompare(b.label);
    });

export const isonToCountryName = (ison) => {
    const counrty = CounrtrieItems.find(country => country.value === ison)
    return counrty ? counrty.label : 'Unknown Country';
}

export const isonToCurrencyName = (ison) => {
    const counrty = countries.find(item => item.currency_ison === ison)
    return counrty ? counrty.currencyCode : 'GBP';
}

const CountrySelector = (changeHandler) => (

    <Space wrap>
        <Select
            defaultValue="Select Country"
            style={{
                width: 120,
            }}
            onChange={changeHandler}
            options={CounrtrieItems}
        />
    </Space>
);

export default CountrySelector;


