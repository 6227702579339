import StatusIcon from "../../../../components/StatusIcon";
import { ProcessorType } from "../../../../const/enums";
const LimitGroupsListFields = [
    {
        label: 'Created At',
        dataIndex: 'created_at',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            // const date = value ? value : '-'
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        }
    },
    {
        label: 'Updated At',
        dataIndex: 'updated_at',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            // const date = value ? value : '-'
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        }
    },
    {
        label: 'Processor Type',
        dataIndex: 'processor_type',
        customRender: true,
        render: (type) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Type:
                    </div>
                    <div style={{ display: 'flex' }}>
                        {ProcessorType.ItemFromValue(type)}
                    </div>
                </div>
            )
        }
    },
    {
        label: 'Limit Group ID',
        dataIndex: 'limit_group_id'
    },
    {
        label: 'Name',
        dataIndex: 'name'
    },
    {
        label: 'Deleted',
        dataIndex: 'is_deleted',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        label: 'Active',
        dataIndex: 'is_active',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    
];

export default LimitGroupsListFields