import moment from "moment";
import 'moment-timezone';

window.currentTimeZone= {
	id:'Europe/London',
	title:'London'
};
// eslint-disable-next-line no-extend-native
String.prototype.listDateTimeFormat = 'dd MMM yyyy HH:mm';
// eslint-disable-next-line no-extend-native
String.prototype.toDateTime = function(){
	const date = moment(this);
	return date.toDate();
}

// eslint-disable-next-line no-extend-native
String.prototype.toListDateString = function(timezone =  window.currentTimezone ||  { id:'Europe/London', title:'London'} ) {
	const momentItem = moment.tz(this,timezone.id);
	return momentItem.format("D MMM YYYY HH:mm");
}
