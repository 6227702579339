import React from 'react';
import { Layout, Pagination } from 'antd';
import ProTable from '@ant-design/pro-table'
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';

function GenericTable({ dataSource, loading, columns, pageSize = 0, currentPage, pageHandler = null, onChangeHandler = () => { }, on, title }) {

  const { Content } = Layout;
  const pagination = pageSize === 0 ? false : { pageSize: pageSize }
  const total = dataSource.count || 0
  const responsiveTitles = (columns) => {
    columns.map((item, index) => {
      item.key = 'columns_' + item.dataIndex + index
      if (item.customRender === undefined || item.customRender === false) {
        item.render = (title) => {
          return (
            <div>
              <div className='responsiveTableData'>
                {item.title}:
              </div>
              <div style={{ display: 'inline' }}>
                {title}
              </div>
            </div>
          )
        }

      }
      return item

    })

    return columns
  }

  return (
    <ConfigProvider locale={enUS}>
      <Content className='main-container'
      >
        <div className='tableContainer'>
          <h3>{title}</h3>
          <ProTable
            bordered
            columns={responsiveTitles(columns)}
            dataSource={dataSource.results}
            loading={loading}
            rowKey={(record) => record.id}
            onChange={onChangeHandler}
            pagination={false}
            search={false}
            // toolBarRender={false}
            // options={false}
            defaultSize='small' // means compact
            options={{reload:false}}
            // options={{density:false, reload:false}}
            scroll={{ x: 'max-content' }}
          />
        </div>
        {pageSize > 0 && !loading ? (<Pagination onChange={pageHandler != null ? pageHandler : () => { }} style={{ float: 'left', padding: '20px' }} defaultPageSize={pageSize} defaultCurrent={currentPage} total={total} showSizeChanger={false} />) : (<></>)}
      </Content>
    </ConfigProvider>
  );
}

export default GenericTable;
