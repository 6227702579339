import { ProcessorType } from "../../../../const/enums";
import { EyeOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
const FeeGroupsColumns = (openModal) => [
    {
        title: 'Processor',
        dataIndex: 'processor_type',
        customRender: true,
        render: (type) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Type:
                    </div>
                    <div style={{ display: 'flex' }}>
                        {ProcessorType.ItemFromValue(type)}
                    </div>
                </div>
            )
        }
    },
    {
        title: 'Fee Group ID',
        dataIndex: 'fee_group_id'
    },
    {
        title: 'Name',
        dataIndex: 'name'
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        customRender: true,
        render: (value) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <div>
                    <div className='responsiveTableData'>
                        Created at:
                    </div>
                    <p className='responsiveInline'>
                        {date}
                    </p>
                </div>
            )
        },
    },
    {
        title: 'Action',
        dataIndex: 'id',
        align: 'center',
        customRender: true,
        render: (id, row) => {
            return (
                //comment the Link with openModal and uncomment the other one for the old details page.

                <Link onClick={() => openModal(id)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
                // <a href={`/feegroups/${id}`}>
                //     <EyeOutlined style={{ fontSize: '22px' }} />
                // </a>
            )
        }
    },

];

export default FeeGroupsColumns