const UserManagementFilters = [
    {
        key:0,
        title: 'Username',
        placeholder: 'Username',
        dataIndex: 'username',
        sizes: {
			xxl: 12,
			xl: 12,
			lg: 12,
			xs: 24
		},
    },
    {
        key:1,
        title: 'Date Joined From',
        dataIndex: 'date_joined',
        rangeNames: ['after', 'before'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true],
        sizes: {
			xxl: 12,
			xl: 12,
			lg: 12,
			xs: 24
		},
    },
];

export default UserManagementFilters