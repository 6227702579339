import React, { useState } from 'react'
import ClientIPColumns from '../components/ClientIPColumns'
import { endpoint } from '../../../AppGlobal'
import ListPage from '../../../components/List'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Role from '../../../const/role';
import { roleCheckForUser } from '../../../utils/RoleCheck';
import { Button } from 'antd';
import { useDispatch } from "react-redux";
import { PlusCircleOutlined } from '@ant-design/icons';
import { createNotification } from '../../../stores/reducers/notificationreducer';
import Endpoint from '../../../api/endpoint';
import { useParams } from "react-router-dom";
import { errorMessageHandler } from '../../../utils/ErrorHandler';

function ClientIPAddresses({ clientId }) {
    const { id } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(0);
    const { user } = useSelector(state => state.authreducer);
    const canAdd = roleCheckForUser(user, [Role.ClientAdmin, Role.ClientEditor])
    const toCreate = () => {
        navigate(`/clients/${clientId}/ipaddresses/create`)
    }

    const handleDeleteIP = async (ipId) =>{
        const response = await endpoint.deleteClientIP(ipId)
        if (response._status === Endpoint.status.success) {
            dispatch(createNotification({ title: 'Success', message: "IP address deleted", type: 'success' }));
            setUpdate(update + 1) 
        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
    }

    const columns = ClientIPColumns(handleDeleteIP);

    return (
        <div>
            {
                canAdd && (
                    <Button onClick={toCreate} style={{ marginLeft: '16px' }} icon={<PlusCircleOutlined />}>Add</Button>
                )
            }
            <ListPage setUpdateDispatcher={update} fetchMethod={async (page, filter) => {
                const response = await endpoint.fetchClientsIP(clientId, page, filter)
                return response
            }} columns={columns} />
        </div>
    )
}

export default ClientIPAddresses