
import ListPage from '../../../components/List';
import { endpoint } from '../../../AppGlobal';
import ProcessorColumns from '../components/ProcessorColumns';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Role from '../../../const/role';
import { useSelector } from 'react-redux'
import { roleCheckForUser } from '../../../utils/RoleCheck';
import ProcessorDetailsModal from '../components/ProcessorDetailsModal';
import DrawerModal from '../../../components/DrawerModal';

function ProcessorView({ clientId }) {
	const navigate = useNavigate()
	const { user } = useSelector(state => state.authreducer);
	const canAdd = roleCheckForUser(user, [Role.ClientAdmin, Role.ClientEditor])
	const toCreate = () => {
		navigate(`/clients/detail/${clientId}/processor/create`)
	}

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

	return (

		<div>
			{
				canAdd && (
					<Button onClick={toCreate} style={{ marginLeft: '16px' }} icon={<PlusCircleOutlined />}>Add</Button>
				)
			}
			<ListPage
				fetchMethod={async (page, filter) => {
					return await endpoint.listClientProcessors(clientId, page, filter)
				}}
				columns={ProcessorColumns(openModal)} // remove openModal for the old details page
			/>
			{selectedItem && (
                <ProcessorDetailsModal
                    visible={modalVisible}
                    processor={selectedItem}
                    closeModal={closeModal}
                />
            )}
		</div>

		// For the old details page uncomment this div, comment above div.

		// <div>
		// 	{
		// 		canAdd && (
		// 			<Button onClick={toCreate} style={{ marginLeft: '16px' }} icon={<PlusCircleOutlined />}>Add</Button>
		// 		)
		// 	}
		// 	<ListPage
		// 		fetchMethod={async (page, filter) => {
		// 			return await endpoint.listClientProcessors(clientId, page, filter)
		// 		}}
		// 		columns={ProcessorColumns}
		// 	/>
		// </div>
	)
}

export default ProcessorView