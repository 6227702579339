import React from 'react';
import { Button, Card, Result } from 'antd';
import { useNavigate } from 'react-router-dom';


export const ErrorPage = ({ errorType }) => {
  const navigate = useNavigate()
  return(
    <Card>
    <Result
      status={errorType.value}
      title={errorType.value}
      className='errorpage-content'
      subTitle={errorType.label}
      extra={<Button onClick={()=>navigate('/')} type="primary">Back Home</Button>}
    />
  </Card>
  )
}