
import { SearchOutlined, CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Row, Col, Button } from "antd"
import { useState } from "react"
import Role from '../const/role'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom"
export function ListActionBar({ searchHandler, addHandler, roles = [Role.ANY], searchRoles = [Role.ANY] }) {
	const navigate = useNavigate()
	const location = useLocation();
	const { user } = useSelector(state => state.authreducer);
	const [searchIsEnabled, setSearchIsEnabled] = useState(true)
	const toogleSearch = () => {
		setSearchIsEnabled(prev => !prev)
		if (searchHandler) {
			searchHandler(searchIsEnabled)
		}
	}
	const handleAdd = () => {
		if (addHandler) {
			addHandler()
		}
	};
	const isLoggedIn = user != null;
	const userRoles = isLoggedIn ? user.groups : [];
	const canAdd = user.is_superuser || roles.includes(Role.ANY) || userRoles.intersection(roles).length > 0;
	const canSearch = user.is_superuser || searchRoles.includes(Role.ANY) || userRoles.intersection(searchRoles).length > 0;

	return (
		<div style={{ display: 'inline-flex' }}>
			<Row >
				{
					(canSearch && searchRoles.length > 0) && (
						<Col>
							<Button
								type="text"
								icon={searchIsEnabled ? <SearchOutlined /> : <CloseCircleOutlined />}
								onClick={toogleSearch}
							/>
						</Col>
					)
				}
				{
					(canAdd && roles.length > 0) && (
						<Col>
							<Button
								type="text"
								icon={<PlusCircleOutlined />}
								onClick={handleAdd}
							/>
						</Col>
					)
				}
			</Row>
		</div>
	)
}


