import React from "react";
import Applayout from "./app"
import { Breadcrumb, Card } from "antd";
function DetailLayout({ breadCrumbItems, children }) {

    return (
        <Applayout>
            <Breadcrumb style={{marginLeft:'16px', marginBottom:'16px'}} items={breadCrumbItems} />
            <Card>{children}</Card>
        </Applayout>
    );
}

export default DetailLayout