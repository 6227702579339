import React from 'react'
import '../../../theme/css/Generic.css'
import { CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { Layout, Spin, theme } from 'antd';

function DashboardChart({ timeLine, loading }) {

    const { Content } = Layout;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const timeLineData = timeLine


    return (
        <Content
            style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 100,
                borderRadius: '10px',
                background: colorBgContainer
            }}
        >
            <div
                style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    marginRight: "40px"
                }}>
                Cards
                {loading && <Spin style={{ paddingLeft: "40px" }} />}
            </div>

            <ResponsiveContainer height={300}>
                <LineChart data={timeLineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="cards" stroke="#288cff" />
                </LineChart>
            </ResponsiveContainer>
        </Content>
    )
}

export default DashboardChart