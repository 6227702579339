
import { useParams } from "react-router-dom";
import DetailLayout from "../../Layout/detail";
import { endpoint } from "../../AppGlobal";
import { EditableListView } from "../../components/EditableItemView";
import TransactionsDetailListFields from "./components/TransactionsDetailListFields";
function TransactionsDetail() {
    const { trx_id, req_type} = useParams();
    const fetchMethod = async () =>{ 
        const response = await endpoint.fetchTransactionsDetail(trx_id, req_type)
        return response
    }
    const breadCrumbItems = [
        {
            title: <a href="/transactions">Transactions</a>,
        },
        {
            title: "Transaction Detail"
        },
    ]
    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <EditableListView id={trx_id}
                listFields={TransactionsDetailListFields}
                fetchMethod={fetchMethod}
                title='Details'
            />
        </DetailLayout>
       
    );
}

export default TransactionsDetail