import { EyeOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
const UserManagementColumns = (openModal) => [
    {
        title: 'Username',
        dataIndex: 'username',
        sorter: () => { }
    },
    {
        title: 'Date Joined',
        dataIndex: 'date_joined',
        sorter: () => { },
		customRender: true,
		render:(value,row)=>{
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return(
                <div>
                    <div className='responsiveTableData'>
                        Date Joined:
                    </div>
                    <p className='responsiveInline'>
                    {date}
                    </p>
                </div>
			)
		}
    },
    {
        title: 'Action',
        dataIndex: 'id',
        align: 'center',
        customRender: true,
        render: (userId) => {
            return (
                <Link onClick={() => openModal(userId)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
            );
        },
    },
];

export default UserManagementColumns