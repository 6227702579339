import React, { useEffect, useState } from 'react';
import { Menu } from 'antd'
import { useNavigate, useLocation } from "react-router-dom";
import APPROUTES from '../const/routes';
import { roleCheckForUser } from '../utils/RoleCheck';
import { useSelector } from 'react-redux';
function SideMenu() {
	const { SubMenu } = Menu;

	const [selectedKey, setSelectedKey] = useState('');
	const { user } = useSelector(state => state.authreducer);
	const location = useLocation();
	const navigate = useNavigate()
	const items = Object.keys(APPROUTES).map(key => APPROUTES[key])
	//TODO: Sub menu olanlar için role kısmı yapılacak. Transaction ve Settings de sub menu olduğu için role e göre side menu de gizleme çalışmıyor
	const itemsForRole = (items) => {
		return items.reduce((filteredItems, item) => {
			if (item.children) {
				// Handle sub-items separately
				const subItems = itemsForRole(item.children);
				if (subItems.length > 0) {
					const newItem = { ...item, children: subItems };
					filteredItems.push(newItem);
				}
			} else if (roleCheckForUser(user, item.roles)) {
				filteredItems.push(item);
			}
			return filteredItems;
		}, []);
	};

	const filteredItems = itemsForRole(items);

	const findItem = (value, field = 'key', look = items) => {
		let targetItem = null
		look.forEach(item => {
			if (item[field] === value) {
				targetItem = item
			}
			else if (item.children) {
				const subItem = findItem(value, field, item.children)
				if (subItem !== null) {
					targetItem = subItem
				}
			}
		})
		return targetItem
	}

	const handleMenuClick = (key) => {
		const menuItem = findItem(key)
		if (menuItem !== null) {
			navigate(menuItem.path);
			// If this causes a problem about caching, remove this.
			window.location.reload(true)
		}

	}

	const findOpenKey = () => {
		const paths = location.pathname.split('/').filter(item => item.length > 0)
		const pathname = paths.length > 0 ? paths[0] : ''
		const path = '/' + pathname;
		const item = findItem(path, 'path')
		if (item.parent) {
			return [item.parent]
		}
		return []

	}

	useEffect(() => {
		const paths = location.pathname.split('/').filter(item => item.length > 0)
		const pathname = paths.length > 0 ? paths[0] : ''
		const path = '/' + pathname;
		const item = findItem(path, 'path')
		if (item !== null) {
			setSelectedKey(item.key);
		}

	}, [location]);

	return (
		<Menu
			theme="dark"
			mode="inline"
			selectedKeys={[selectedKey]}
			defaultOpenKeys={findOpenKey()}
			onClick={({ key }) => handleMenuClick(key)}
		>
			{filteredItems.map((item) => (
				// Check if the item has children to decide between Menu.Item and SubMenu
				item.children ? (
					<SubMenu key={item.key} icon={item.icon} title={item.label}>
						{item.children.map((subItem) => (
							<Menu.Item key={subItem.key} icon={subItem.icon}>
								{subItem.label}
							</Menu.Item>
						))}
					</SubMenu>
				) : (
					<Menu.Item key={item.key} icon={item.icon}>
						{item.label}
					</Menu.Item>
				)
			))}
		</Menu>
	)
}

export default SideMenu