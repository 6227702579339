import Role from "../const/role";
export const roleCheckForUser = (user, roles) =>{
	const isLoggedIn = user != null;
	const userRoles = isLoggedIn ? user.groups : [];
	const targetRole = roles || []
	const hasRoleIntersection =  user.is_superuser || targetRole.includes(Role.ANY) || userRoles.intersection(targetRole).length > 0;
	if (hasRoleIntersection === false) {
	}
	return hasRoleIntersection
  
}