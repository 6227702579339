import { endpoint } from '../../../AppGlobal'
import { EditableListView } from '../../../components/EditableItemView'
import Role from '../../../const/role'
import { useParams } from 'react-router-dom'
import DetailLayout from '../../../Layout/detail'
import UsageGroupsListFields from './components/UsageGroupsListFields'
import UsageGroupsFormFields from './components/UsageGroupsFormFields'
function UsageGroupsDetail() {

    const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchUsageGroupsDetail');

    const breadCrumbItems = [
        {
            title: <a href="/usagegroups">Usage Groups</a>,
        },
        {
            title: "Usage Group"
        },
    ]

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <EditableListView id={id}
                listFields={UsageGroupsListFields}
                formFields={UsageGroupsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateUsageGroupsDetails')}
                title='Details'
                groupsForEdit={[Role.UsageGroupEditor, Role.UsageGroupAdmin]}
            />
        </DetailLayout>
    )
}

export default UsageGroupsDetail