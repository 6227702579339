import React, { useEffect, useState } from 'react';
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Layout, Button, theme, Space, Dropdown, notification } from 'antd';
import { DownOutlined, SettingOutlined, PoweroffOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux"
import { createNotification } from '../stores/reducers/notificationreducer';
import { useNavigate} from "react-router-dom";
import { logout} from "../stores/reducers/authreducer";
import { endpoint } from '../AppGlobal';
import SideMenu from '../components/SideMenu';
import SessionTimerView from './components/sessionTimerView';
import '../theme/css/Generic.css'
const { Header, Sider } = Layout;

const App = ({bar = null, children }) => {
	const timeZone = document.timeZone
	const dispatch = useDispatch()
	const { notificationItem } = useSelector(state => state.notificationreducer);
	const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = (type, message, title) => {
	  api[type]({
		message: title,
		description:message
	  });
	};
	useEffect(()=>{
      if(notificationItem!=null) {
		openNotificationWithIcon(notificationItem.type, notificationItem.message, notificationItem.title);
	  }
	},
	[notificationItem]
	)
	useEffect(()=>{
		dispatch(createNotification(null));
	})
	const navigate = useNavigate()
	const [collapsed, setCollapsed] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	const {
		token: { colorBgContainer },
	} = theme.useToken();

	const { user } = useSelector(state => state.authreducer)
	const logoutUser = async () => {
		endpoint.sessionManager.clearAuthInfo();
		navigate("/logout")
	}

	useEffect(() => {
		
	}, [user]);


	const settings = () => {
		navigate("/passfort")
	}
	

	const onCancel = () => {
		dispatch(logout());
		setModalOpen(false);
		navigate("/login")
	};

	const items = [
		{
			key: '1',
			label: (
				<a onClick={logoutUser}>
					Logout
				</a>
			),
			icon: (<PoweroffOutlined />)
		},
		{
			key: '2',
			label: (
				<a>
					Profile (disabled)
				</a>
			),
			disabled: true,
			icon: (<UserOutlined />)
		},
		{
			key: '3',
			label: (
				<a onClick={settings}>
					Settings
				</a>
			),
			icon: (<SettingOutlined />)
		}
	];

	
	return (
		<Layout  style={{ height: '100vh' }}>
			{contextHolder}
			<Sider trigger={null} collapsible collapsed={collapsed}>
				<div className="menu-logo"></div>
				<SideMenu role={ user != null ? user.groups : []}></SideMenu>
			</Sider>
			
			<Layout style={{ overflowY:'scroll', width:'100%' }}>
				<Header style={{ padding: 0, background: colorBgContainer }}>

					<Button
						type="text"
						icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
						onClick={() => setCollapsed(!collapsed)}
						style={{
							fontSize: '16px',
							width: 64,
							height: 64,
						}}

					/>

					{bar != null &&(
						bar
					)}
					
					<div style={{ float: 'right', paddingRight: '20px' }}>
					
						<Dropdown
							menu={{
								items,
							}}


						>
							<a className='fhlink' onClick={(e) => e.preventDefault()}>
								<Space>
									{user != null ? `${user.first_name} ${user.last_name}` : 'Current User'}
									<DownOutlined />
								</Space>
							</a>
						</Dropdown>
					</div>
					{/*<p style={{float:'right', height:'30px'}}>Current Time Zone:{timeZone}</p>*/}
				</Header>
				<div className='main-container'>{children}</div>
				<SessionTimerView/>
			</Layout>
		</Layout>
	);
};
export default App;