export const CreateClientIPAddressField = [
    {
		key: 0,
		title: "User",
		dataIndex: 'user',
        type: 'Hidden',
		showTitle:false

	},
    {
		key: 1,
		title: "IP Address",
		dataIndex: 'ip',
        type: 'Input',
        rule:[
			{
				required: true,
				message: "Please enter a IP address"
			},
		]
	},
	
];