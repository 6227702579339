import { endpoint } from '../../../../AppGlobal'
import { EditableListView } from '../../../../components/EditableItemView'
import Role from '../../../../const/role'
import FeeGroupsListFields from '../../../SettingsDetail/FeeGroupsDetail/components/FeeGroupsListFields'
import FeeGroupsFormFields from '../../../SettingsDetail/FeeGroupsDetail/components/FeeGroupsFormFields'
import { Drawer } from 'antd'
function FeeGroupsDetailsModal({ visible, feegroup, closeModal }) {

    // const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchFeeGroupsDetail');

    // const breadCrumbItems = [
    //     {
    //         title: <a href="/feegroups">Fee Groups</a>,
    //     },
    //     {
    //         title: "Fee Group"
    //     },
    // ]

    return (
        <Drawer
            title="Fee Group Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700}
        >
            <EditableListView id={feegroup}
                listFields={FeeGroupsListFields}
                formFields={FeeGroupsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateFeeGroupsDetails')}
                title='Details'
                groupsForEdit={[Role.FeeGroupEditor, Role.FeeGroupAdmin]}
            />
        </Drawer>
    )
}

export default FeeGroupsDetailsModal