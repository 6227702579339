import React from 'react'
import { endpoint } from '../../../AppGlobal'
import ListPage from '../../../components/List'
import { roleCheckForUser } from '../../../utils/RoleCheck'
import Role from '../../../const/role'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import CommentsTabColumns from '../components/CommentsTabColumns'
function HolderComments({ holderId, processor }) {
	const navigate = useNavigate()
    const { user } = useSelector(state => state.authreducer);
    const canAdd = roleCheckForUser(user, [Role.ClientAdmin, Role.ClientEditor])
    const toCreate = () => {
        navigate(`/holders/${holderId}/${processor}/comments/create`)
    }
    return (
        <div>
            {
                canAdd && (
                    <Button onClick={toCreate} style={{ marginLeft: '16px' }} icon={<PlusCircleOutlined />}>Add</Button>
                )
            }
            <ListPage
                fetchMethod={async (page, filter) => {
                    return await endpoint.fetchCommentsOfHolder(holderId, page, filter)
                }}
                columns={CommentsTabColumns}
            />
        </div>

    )
}

export default HolderComments