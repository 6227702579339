import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import './utils/windowManager/ErrorManager.js';
import './utils/windowManager/SessionManager.js';
import './utils/proto/Array.js';
import './utils/proto/String.js';
import './utils/proto/Date.js';
import './utils/proto/Number.js';
const root = ReactDOM.createRoot(document.getElementById('root'));
document.timeZone = ` (${window.currentTimeZone.id})`
root.render(
  // React StrictMode renders components twice on dev server
  // <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
window.debugMode = true;