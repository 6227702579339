import { Status } from "../../../const/enums";
const UserFormFields = [
    {
        key: 0,
        title: 'Username',
		placeholder: 'Username',
        dataIndex: 'username',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 1,
        title: 'First Name',
		placeholder: 'First Name',
        dataIndex: 'first_name',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 2,
        title: 'Last Name',
		placeholder: 'Last Name',
        dataIndex: 'last_name',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 3,
        title: 'Email',
		placeholder: 'Email',
        dataIndex: 'email',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
		key: 4,
		title: 'Active',
		placeholder: 'Active',
		dataIndex: 'is_active',
		rule: [{
			required: true,

		}
		],
		type: 'Select',
		options: Status.asItemList(),
	},
    {
		key: 5,
		title: 'Superuser',
		placeholder: 'Superuser',
		dataIndex: 'is_superuser',
		rule: [{
			required: true,

		}
		],
		type: 'Select',
		options: Status.asItemList(),
	},
    {
		key: 6,
		title: "Groups",
		dataIndex: 'groups',
        type: 'MultiSelect',
		async: true,
		inprogress: true,
	},

];

export default UserFormFields