import { endpoint } from '../../AppGlobal';
import CardHoldersColumns from './components/CardHolderColumns';
import CardHolderFilters from './components/CardHolderFilters';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';


function CardHolders() {
    const fetchMethod = endpoint.getMethod('fetchHolders');
    return (
        <CollapsedSearchLayout fetchMethod={fetchMethod} columns={CardHoldersColumns} filters={CardHolderFilters} barRoles={[]} title={"Holders"}/>
       
    );
}

export default CardHolders;