export const errorMessageHandler = (response) => {
    let message = response.message !== undefined ? response.message : 'An error occurred';
    const keys = Object.keys(response)
    let errorMessage = ''
    if (keys.length > 0) {
        keys.forEach(key => {
            const errorString = response[key]
            errorMessage += errorString + ' '
        })
    }
    return errorMessage.length > 0 ? errorMessage : message
}