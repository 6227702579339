import { CheckCircleOutlined ,CloseCircleOutlined} from '@ant-design/icons';

const EnableIcon = ({status})=>{
	return (
		<>
			{
				status == 'E' ?
					(
						<CheckCircleOutlined style={{ fontSize: '22px', color: '#34B233' }} />
					) : (
						<CloseCircleOutlined style={{ fontSize: '22px', color: '#FF0000' }} />
					)
			}
		</>
	)
}

export default EnableIcon