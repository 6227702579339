import {configureStore } from '@reduxjs/toolkit'
import authreducer from './reducers/authreducer'
import notificationreducer from './reducers/notificationreducer'

export default configureStore({
	reducer:{
		authreducer:authreducer,
		notificationreducer:notificationreducer
	}
})

