import DetailLayout from "../../../Layout/detail";
import FormBuilder from "../../../components/Form";
import { FormConvertDictNestedFields } from "../../../components/Form";
import { useEffect, useState } from "react";
import { CreateClientFields } from "./components/CreateClientFields";
import Form from "antd/es/form/Form";
import { endpoint } from "../../../AppGlobal";
import Endpoint from "../../../api/endpoint";
import { useParams } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../stores/reducers/notificationreducer";
import { useNavigate } from "react-router-dom";
import { errorMessageHandler } from "../../../utils/ErrorHandler";

function CreateClient() {
    const { id } = useParams();
    const [progress, setProgress] = useState(false);
    const [blockUpdate, setBlockUpdate] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [editItem, setEditItem] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const breadCrumbItems = [
        {
            title: <a href="/clients">Client Management</a>,
        },
        {
            title: "Create Client"
        },
    ]

    // const watchHandler = (value, values) => {
    //     if (value.is_staff !== undefined) {
    //         let updatedfields =  CreateClientFields.slice(0, CreateClientFields.length)
    //         console.log(updatedfields)
    //         updatedfields.find(item => item.dataIndex == 'groups').isActive = value.is_staff
    //         setFormFields(updatedfields)
    //     }

    // }
    const [form] = Form.useForm()
    const onFinishHandler = async (values) =>{
        let  clientValues =  FormConvertDictNestedFields(values)
        delete clientValues.confirm_password
        setProgress(true)
        const response = await endpoint.createClient(clientValues)
        if (response._status === Endpoint.status.success) {
            dispatch(createNotification({ title: 'Success', message: "Created a new Client", type: 'success' }));
            const redirectInterval = setInterval(() => {
                clearInterval(redirectInterval);
                navigate(`/clients`)
            }, 500)
        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setProgress(false)
    }

    useEffect(() => {
        setFormFields(CreateClientFields)

    }, [])

    

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
           <FormBuilder 
                    disabled={progress} 
                    form={form} 
                    layout="vertical" 
                    models={formFields.filter(item => item.isActive !== false)} 
                    // handler={watchHandler}
                    onFinishHandler={onFinishHandler}
                    >
                    <Row>
                        <Col className='formItemInline' xs={24} sm={12} xxl={12} >
                            <Button loading={progress} type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={blockUpdate} > Save</Button>
                        </Col>

                    </Row>
                </FormBuilder>
        </DetailLayout>
    );
}

export default CreateClient