const SettingsListFields = [
    {
        label: 'Grant Type',
        dataIndex: 'grant_type'
    },
    {
        label: 'Client Secret',
        dataIndex: 'client_secret'
    },
    {
        label: 'Client ID',
        dataIndex: 'client_id'
    },
    {
        label: 'Product Name',
        dataIndex: 'product_name'
    },
    
];

export default SettingsListFields