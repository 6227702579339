import { Status } from "../../../const/enums"
export const ClientEditFormFields = [

	{
		key: 0,
		title: 'First Name',
		placeholder: 'First Name',
		dataIndex: 'first_name',
		rule: [
			{
				required: true,
				pattern: /^[a-z,A-Z,\s,İıÖöüÜçÇşŞğĞ]{3,250}$/,
				message: "Minimum 3, Maximum 20 characters"
			},
		],
		type: 'Input'
	},
	{
		key: 1,
		title: 'Last Name',
		placeholder: 'Last Name',
		dataIndex: 'last_name',
		rule: [
			{
				required: true,
				pattern: /^[a-z,A-Z,\s,İıÖöüÜçÇşŞğĞ]{3,250}$/,
				message: "Minimum 3, Maximum 20 characters"
			},
		],
		type: 'Input'
	},
	{
		key: 2,
		title: 'Username',
		placeholder: 'Username',
		dataIndex: 'username',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 3,
		title: 'Email',
		placeholder: 'Email',
		dataIndex: 'email',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 4,
		title: 'URL',
		placeholder: 'URL',
		dataIndex: 'profile__sub__url',
		rule: [{
			required: true,
		},
		],
		type: 'Input'
	},
	{
		key: 5,
		title: 'Status',
		placeholder: 'Status',
		dataIndex: 'is_active',
		rule: [{
			required: true,

		}
		],
		type: 'Select',
		options: Status.asItemList(),
	},
	{
		key: 6,
		title: 'Phone',
		placeholder: 'Phone',
		dataIndex: 'profile__sub__phone',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 7,
		title: "Bank Details",
        type: 'Header',
		showTitle:false,

	},
	{
		key: 8,
		title: 'Trade Name',
		placeholder: 'Trade Name',
		dataIndex: 'profile__sub__tradeName',
		rule: [{
			required: true,
			pattern: /\w{3,250}/,
			message: "Minimum 3 characters"
		},
		],
		type: 'Input'
	},
	{
		key: 9,
		title: 'KYB',
		placeholder: 'KYB',
		dataIndex: 'profile__sub__kyb',
		rule: [{
			required: true,

		}
		],
		type: 'Select',
		options:[
		{value: 'WAITING',label: 'waiting'},
    	{value: 'APPLIED',label: 'applied'},
    	{value: 'APPROVED',label: 'approved'},
    	{value: 'IN_REVIEW',label: 'in_review'},
    	{value: 'REJECTED',label: 'rejected'},
    	{value: 'CANCELLED',label: 'cancelled'},
		]
	},
    {
		key: 10,
		title: 'Bank Name',
		placeholder: 'Bank Name',
		dataIndex: 'profile__sub__bank_name',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
    {
		key: 11,
		title: 'IBAN',
		placeholder: 'IBAN',
		dataIndex: 'profile__sub__iban',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
    {
		key: 12,
		title: 'BIC/SWIFT',
		placeholder: 'BIC/SWIFT',
		dataIndex: 'profile__sub__bic_swift',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
    {
		key: 13,
		title: 'Account Number',
		placeholder: 'Account Number',
		dataIndex: 'profile__sub__account_number',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
    {
		key: 14,
		title: 'Comments',
		placeholder: 'Comments',
		dataIndex: 'profile__sub__comment',
		type: 'Input',
		rule: [
			{
				required: false,
			}]

	}
	

]


