import React, { useState } from 'react'
import { endpoint } from '../../AppGlobal';
import { Button } from 'antd';
import Endpoint from '../../api/endpoint';
import { useNavigate } from 'react-router-dom';
import SettingsListFields from './components/PassfortSettingsListFields'
import SettingsFormFields from './components/PassfortSettingsFormFields'
import Role from '../../const/role';
import DetailLayout from '../../Layout/detail';
import { EditableListView } from '../../components/EditableItemView'

function PassfortSettings() {

    const navigate = useNavigate()

    const [isNeeded, setIsNeeded] = useState(false)

    const fetchMethod = async () => {
        let response = await endpoint.fetchPassfortSettings()
        if (response._status === Endpoint.status.success) {
            setIsNeeded(response.id === undefined)
        }
        return response
    }

    const settings = () => {
        navigate("/passfort/create")
    }

    return (

        <DetailLayout DetailLayout >
            <h2>Passfort</h2>

            {isNeeded && (
                <Button style={{marginLeft:'20px', backgroundColor:'#ff4d4f', color:'#ffffff'}} onClick={settings}>Add Passfort Settings</Button>
            )}

            {!isNeeded && (
                <EditableListView
                    listFields={SettingsListFields}
                    formFields={SettingsFormFields}
                    fetchMethod={fetchMethod}
                    updateMethod={endpoint.getMethod('updatePassfortSettingsDetails')}
                    title='Details'
                    groupsForEdit={[Role.PassfortAdmin, Role.PassfortEditor]}
                />
            )}
        </DetailLayout >
    )
}

export default PassfortSettings