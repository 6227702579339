import { endpoint } from '../../../AppGlobal'
import { EditableListView } from '../../../components/EditableItemView'
import Role from '../../../const/role'
import { useParams } from 'react-router-dom'
import DetailLayout from '../../../Layout/detail'
import FeeGroupsListFields from './components/FeeGroupsListFields'
import FeeGroupsFormFields from './components/FeeGroupsFormFields'
function FeeGroupsDetail() {

    const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchFeeGroupsDetail');

    const breadCrumbItems = [
        {
            title: <a href="/feegroups">Fee Groups</a>,
        },
        {
            title: "Fee Group"
        },
    ]

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <EditableListView id={id}
                listFields={FeeGroupsListFields}
                formFields={FeeGroupsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateFeeGroupsDetails')}
                title='Details'
                groupsForEdit={[Role.FeeGroupEditor, Role.FeeGroupAdmin]}
            />
        </DetailLayout>
    )
}

export default FeeGroupsDetail