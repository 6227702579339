import { endpoint } from '../../AppGlobal';
import ClientColumns from './components/ClientColumns';
import ClientFilters from './components/ClientFilters';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import Role from '../../const/role';
function ClientManagement() {

    return (
       
        <CollapsedSearchLayout 
        fetchMethod={endpoint.getMethod('fetchClients')} 
        columns={ClientColumns} 
        filters={ClientFilters} 
        barRoles = {[Role.ClientEditor, Role.ClientAdmin]}
        barAddURL = "/clients/create"
        title={"Clients"}
        />
    );
}

export default ClientManagement;