import ListPage from "./List";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ListActionBar } from "./bar";
import Applayout from "../Layout/app"
import Role from "../const/role";
function CollapsedSearchLayout({fetchMethod,columns,filters,barRoles = [Role.ANY], searchRoles= [Role.ANY], barAddURL = null, hideSearchButton = false, title}) {
   ;
    const [searchEnabled,setSearchIsEnabled] = useState(false)
    const  navigate  = useNavigate()
    const searchHandler = (val)=>{
        setSearchIsEnabled(val)
    }
    const addHandler = ()=>{
        if(barAddURL!= null){
            navigate(barAddURL)
        }
    }
    const bar = hideSearchButton ? null : <ListActionBar searchHandler={searchHandler} searchRoles={searchRoles} roles={barRoles} addHandler={addHandler} />;
    return (
        <Applayout bar={bar}>
            <ListPage fetchMethod={fetchMethod} columns={columns} searchFilters={filters} searchVisible={searchEnabled} title={title} />
        </Applayout>
    );
}

export  default CollapsedSearchLayout;