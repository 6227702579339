import { Status } from "../../../../const/enums"
export const CreateUserFields = [
    {
		key: 0,
		title: "First Name",
        placeholder: 'First Name',
		dataIndex: 'first_name',
        type: 'Input',
		rule:[
			{
				required: true,
				pattern: /^[a-z,A-Z,\s,çÇşŞöÖüÜİığĞ]{3,250}$/,
				message: "Minimum 3, Maximum 250 characters (Use only letters or spaces)"
			},
		]

	},
    {
		key: 1,
		title: "Last Name",
        placeholder: 'Last Name',
		dataIndex: 'last_name',
        type: 'Input',
		rule:[
			{
				required: true,
				pattern: /^[a-z,A-Z,\s,çÇşŞöÖüÜİığĞ]{3,250}$/,
				message: "Minimum 3, Maximum 250 characters (Use only letters or spaces)"
			},
		]

	},
	{
		key: 2,
		title: "Username",
        placeholder: 'Username',
		dataIndex: 'username',
        type: 'Input',
		rule:[
			{
				required: true,
				pattern: /^[a-z,A-Z,çÇşŞöÖüÜİığĞ]{3,250}$/,
				message: "Minimum 3, Maximum 250 characters (Use only letters, do not use spaces)"
			},
		]

	},
    {
		key: 3,
		title: 'Email address',
		placeholder: 'Email address',
		dataIndex: 'email',
		rule: [{
			required: true,
			pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
			message: "Invaild email format"
		},
		],
		type: 'Input'
	},
    {
		key: 4,
		title: "Password",
        placeholder: 'Password',
		dataIndex: 'password',
        type: 'Password',
		rule:[
			{
				required: true,
				message: "Your password must contain at least 8 characters, one special character, one capital letter.",
				pattern:/^(?=.*[A-Z])(?=.*[\W_])(.{8,})$/
			},
		]

	},
    {
		key: 5,
		title: "Confirm Password",
		placeholder: 'Confirm Password',
		dataIndex: 'confirm_password',
		type: 'Password',
		rule: [
			{
				required: true,
				message: "Your password must contain at least 8 characters, one special character, one capital letter.",
				pattern: /^(?=.*[A-Z])(?=.*[\W_])(.{8,})$/
			},
			({ getFieldValue }) => ({
				validator(_, value) {
					if (!value || getFieldValue('password') === value) {
						return Promise.resolve();
					}
					return Promise.reject(new Error('The two passwords do not match.'));
				},
			}),
		]

	},
	{
		key: 6,
		title: 'Active',
		placeholder: 'Active',
		dataIndex: 'is_active',
		rule: [{
			required: true,

		}
		],
		type: 'Select',
		options: Status.asItemList(),
	},
    // {
	// 	key: 7,
	// 	title: 'Superuser',
	// 	placeholder: 'Superuser',
	// 	dataIndex: 'is_superuser',
	// 	rule: [{
	// 		required: true,

	// 	}
	// 	],
	// 	type: 'Select',
	// 	options: Status.asItemList(),
	// },
    {
		key: 8,
		title: "Groups",
		dataIndex: 'groups',
        type: 'MultiSelect',
		async: true,
		inprogress: true,
	},

]