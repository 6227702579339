import DetailLayout from "../../Layout/detail";
import FormBuilder from "../../components/Form";
import { useEffect, useState } from "react";
import { AddBalanceFields } from "./components/AddBalanceFields";
import Form from "antd/es/form/Form";
import { endpoint } from "../../AppGlobal";
import Endpoint from "../../api/endpoint";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Popconfirm, Modal } from "antd";
import { SaveOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { createNotification } from "../../stores/reducers/notificationreducer";
import { useNavigate } from "react-router-dom";
import { errorMessageHandler } from "../../utils/ErrorHandler";
import { currencyBuilder } from "../../const/Internalization";

function AddBalance() {
    const { id } = useParams();
    const { confirm } = Modal;
    const [processors, setProcessors] = useState([]);
    const [progress, setProgress] = useState(false);
    const [blockUpdate, setBlockUpdate] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [editItem, setEditItem] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const breadCrumbItems = [
        {
            title: <a href="/balance">Balance Management</a>,
        },
        {
            title: "Load Balance"
        },
    ]
    const [form] = Form.useForm()
    const onFinishHandler = async (values) => {
        setProgress(true);

        const confirmSave = async () => {
            const response = await endpoint.createBalance(values);
            if (response._status === Endpoint.status.success) {
                dispatch(createNotification({ title: 'Success', message: "Balance loaded", type: 'success' }));
                const redirectInterval = setInterval(() => {
                    clearInterval(redirectInterval);
                    navigate(`/balance`);
                }, 500);
            } else {
                const message = errorMessageHandler(response);
                dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
            }
            setProgress(false);
        };

        const onCancel = () => {
            setProgress(false);
        };
        
        const selectedProcessorValue = form.getFieldValue('processor');
        const selectedProcessor = processors.find(option => option.value === selectedProcessorValue);
        const formattedAmount = currencyBuilder(selectedProcessor.currency).format(form.getFieldValue('amount'));

        confirm({
            title: `Are you sure you want to load ${formattedAmount} to ${selectedProcessor.label}?`,
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { style: { background: '#ff4d4f', borderColor: '#ff4d4f' } },
            onOk: confirmSave,
            onCancel,
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    const fetchMethod = async () => {
        setProgress(true)

        let processors = AddBalanceFields.find(item => item.dataIndex === 'processor');
        if (Endpoint.status.success) {

            let currentPage = 1;
            let hasMorePages = true;

            const responseProcessors = await endpoint.fetchAllProcessors(currentPage)

            if (responseProcessors._status === Endpoint.status.success) {
                processors.options = responseProcessors.results.map(item => { return { label: item.name, value: item.id, currency: item.master_account_currency } })
                setProcessors(processors.options)
                processors.inprogress = false
                currentPage++;
                hasMorePages = responseProcessors.next !== null;
            }
            else {
                processors.inprogress = false
                processors.error = processors.message || 'An error occurred'
            }
        }

        setProgress(false)
    };
    useEffect(() => {
        setFormFields(AddBalanceFields)
        fetchMethod()
    }, [])



    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <FormBuilder
                disabled={progress}
                form={form}
                layout="vertical"
                models={formFields.filter(item => item.isActive !== false)}
                onFinishHandler={onFinishHandler}
            >
                <Row>
                    <Col className='formItemInline' xs={24} sm={12} xxl={12} >
                        <Button loading={progress} type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={blockUpdate} >Load</Button>
                    </Col>

                </Row>
            </FormBuilder>
        </DetailLayout>
    );
}

export default AddBalance