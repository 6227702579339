import { endpoint } from '../../../../AppGlobal'
import { EditableListView } from '../../../../components/EditableItemView'
import Role from '../../../../const/role'
import ProgramsListFields from '../../../SettingsDetail/ProgramsDetail/components/ProgramsListFields'
import ProgramsFormFields from '../../../SettingsDetail/ProgramsDetail/components/ProgramsFormFields'
import { Drawer } from 'antd'
function ProgramDetailsModal({ visible, program, closeModal }) {

    // const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchProgramDetail');

    // const breadCrumbItems = [
    //     {
    //         title: <a href="/programs">Programs</a>,
    //     },
    //     {
    //         title: "Program"
    //     },
    // ]
    
    return (
        <Drawer
            title="Program Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700} // Adjust the width as needed
        // Add any other Drawer props here
        >
            <EditableListView id={program}
                listFields={ProgramsListFields}
                formFields={ProgramsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateProgramDetails')}
                title='Details'
                groupsForEdit={[Role.ProgramEditor, Role.ProgramAdmin]}
            />
        </Drawer>
    )
}

export default ProgramDetailsModal