import { EyeOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
import { isonToCountryName } from "../../../components/CountrySelector";
import { currencyBuilder } from "../../../const/Internalization";
import { AccountStatus } from "../../../const/enums";
const AccountsColumns = (openModal) => [
    {
        title: 'Account Id',
        dataIndex: 'id',
        customRender: true,
        render: (accountId, row) => {
            return (
                <Link key={accountId} onClick={() => openModal(accountId, row.processor_type)}>
                    {accountId}
                </Link>
            )
        }
    },
    {
        title: 'FH Account Id',
        dataIndex: 'paccountid',
        sorter: () => { }
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        sorter: () => { },
        customRender: true,
        render: (value) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <div>
                    <div className='responsiveTableData'>
                        Created at:
                    </div>
                    <p className='responsiveInline'>
                        {date}
                    </p>
                </div>
            )
        },
    },
    {
        title: 'Country',
        dataIndex: 'currency_ison',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Country:
                    </div>
                    <p className='responsiveInline'>
                        {isonToCountryName(value)}
                    </p>
                </div>
            )
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        sorter: () => { },
        customRender: true,
        render: (status) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Status:
                    </div>
                    <p className='responsiveInline'>
                        {AccountStatus.ItemFromValue(status) || '-'}
                    </p>
                </div>

            )
        }
    },
    {
        title: 'Reserved Balance',
        dataIndex: 'reserved_balance',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {

            const currency = isNaN(value) ? '-' : currencyBuilder(row.currency_ison).format(value / 100)
            return (
                <div>
                    <div className='responsiveTableData'>
                        Reserved Balance:
                    </div>
                    <p className='responsiveInline'>
                        {currency}
                    </p>
                </div>
            )
        },
    },
    {
        title: 'Settled Balance',
        dataIndex: 'settled_balance',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {

            const currency = isNaN(value) ? '-' : currencyBuilder(row.currency_ison).format(value / 100)
            return (
                <div>
                    <div className='responsiveTableData'>
                        Settled Balance:
                    </div>
                    <p className='responsiveInline'>
                        {currency}
                    </p>
                </div>
            )
        },
    },
    {
        title: 'Available Balance',
        dataIndex: 'available_balance',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {

            const currency = isNaN(value) ? '-' : currencyBuilder(row.currency_ison).format(value / 100)
            return (
                <div>
                    <div className='responsiveTableData'>
                        Available Balance:
                    </div>
                    <p className='responsiveInline'>
                        {currency}
                    </p>
                </div>
            )
        },
    },
    {
        title: 'Client',
        dataIndex: 'client',
        customRender: true,
        render: (value, row) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Client:
                    </div>
                    <div className='responsiveInline'>
                        {value}
                    </div>
                </div>
            )
        }
    },
    {
        title: 'Holder',
        dataIndex: 'holder_fullname',
        customRender: true,
        render: (value, row) => {
            return (
                <a href={`/holders/${row.holder}/detail`}>
                    <div className='responsiveTableData'>
                        Holder:
                    </div>
                    <div className='responsiveInline'>
                        {value}
                    </div>
                </a>
            )
        }
    },
    {
        title: 'Action',
        dataIndex: 'id',
        align: 'center',
        customRender: true,
        render: (accountId, row) => {
            return (
                //comment the Link with openModal and uncomment the other one for the old details page.

                <Link key={accountId} onClick={() => openModal(accountId, row.processor_type)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
                // <a href={`/accounts/${accountId}/${row.processor_type}`}>
                //     <EyeOutlined style={{ fontSize: '22px' }} />
                // </a>
            )
        }
    },
];

export default AccountsColumns