import StatusIcon from "../../../components/StatusIcon";
const historyColumns = [


	{
		dataIndex: "client_notification",
		title: "Client Notification",
		key: 2,
		customRender: true,
		render: (status) => {
			return (

				<div>
					<div className='responsiveTableData'>
						Client Notification:
					</div>
					<StatusIcon status={status} />
				</div>
			)
		}
	},
	{
		dataIndex: "need_monioring",
		title: "Need Monitoring",
		key: 3,
		customRender: true,
		render: (status) => {
			return (

				<div>
					<div className='responsiveTableData'>
						Need Monitoring:
					</div>
					<StatusIcon status={status} />
				</div>
			)
		}
	},
	{
		dataIndex: "is_monitored",
		title: "Is Monitored",
		key: 4,
		customRender: true,
		render: (status) => {
			return (

				<div>
					<div className='responsiveTableData'>
						Is Monitored:
					</div>
					<StatusIcon status={status} />
				</div>
			)
		}
	},
	{
		dataIndex: "que_status",
		title: "Queue Status",
		key: 5,
		customRender: true,
		render: (status) => {
			return (

				<div>
					<div className='responsiveTableData'>
						Queue Status:
					</div>
					<StatusIcon status={status} />
				</div>
			)
		}
	},
	{
		dataIndex: "created_at",
		title: "Created At",
		key: 6,
		sorter: () => { },
		customRender: true,
		render: (value) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<div>
					<div className='responsiveTableData'>
						Created at:
					</div>
					<p className='responsiveInline'>
						{date}
					</p>
				</div>
			)
		},
	},
	{
		dataIndex: "request_type",
		title: "Request Type",
		key: 7,
		sorter: () => { }
	}
];


export default historyColumns