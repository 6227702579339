import Modal from "antd/es/modal/Modal"
import { useEffect, useState } from "react"
import { logout } from "../../stores/reducers/authreducer"
import { useDispatch } from "react-redux"
import { endpoint, sessionManager } from "../../AppGlobal"

function SessionTimerView() {

	const [countdown, setCountdown] = useState(sessionManager.idleTimer ? sessionManager.idleTimer.warningSeconds : 45);
	const [openModal, setOpenModal] = useState(false)
	const [okDisabled, setOkDisabled] = useState(false)
	const [cancelDisabled, setCancelDisabled] = useState(false)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const continueAction = async () => {
		setOpenModal(false);
		sessionManager.idleTimer.restart();

	}

	const logoutAction = async () => {
		setOkDisabled(true);
		setCancelDisabled(true);
		setLoading(true);
		await endpoint.logout()
		endpoint.sessionManager.clearAuthInfo();
		dispatch(logout())
		
	}

	useEffect(() => {
		const idleHandler = (duration) => {
			setOpenModal(true);
			setCountdown(duration);
			if (duration === 0) {
				logoutAction();
			}
		};

		sessionManager.addIdleHandler(idleHandler);

		return () => {
			sessionManager.removeIdleHandler(idleHandler);
		};
	}, []);

	return (
		<Modal
			className='modalClass'
			closable={false}
			maskClosable={false}
			title="Warning"
			open={openModal}
			onOk={logoutAction}
			onCancel={continueAction}
			confirmLoading={loading}
			okButtonProps={{disabled:okDisabled}}
			cancelButtonProps={{disabled:cancelDisabled}}
			okText="Logout"
			cancelText="Continue"
		>
			<p>Your session will expire after {countdown} seconds. Would you like to continue?</p>
		</Modal>
	)

}

export default SessionTimerView