import { EyeOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
import StatusIcon from "../../../components/StatusIcon"
import { CardStatus } from "../../../const/enums";
const CardManagementColumns = (openModal, openAccountModal) => [
    {
        key: 0,
        title: 'Card Id',
        dataIndex: 'id',
        customRender: true,
        render: (id, row) => {
            return (
                <Link onClick={() => openModal(id, row.processortype)}>
                    {id}
                </Link>
            )
        }
    },
    {
        key: 1,
        title: 'FH Card Id',
        dataIndex: 'pcardid',
        sorter: () => { }
    },
    {
        key: 2,
        title: 'Created at',
        dataIndex: 'created_at',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <div>
                    <div className='responsiveTableData'>
                        Created at:
                    </div>
                    <p className='responsiveInline'>
                        {date}
                    </p>
                </div>
            )
        }
    },
    {
        key: 3,
        title: 'Expiration Date',
        customRender: true,
        render: (value, row) => {
            const expirationDate = `${value.expiration_month}/${value.expiration_year}`;
            return (
                <div>
                    <div className='responsiveTableData'>
                        Expiration Date:
                    </div>
                    <p className='responsiveInline'>
                        {expirationDate}
                    </p>
                </div>
            );
        }
    },
    {
        key: 4,
        title: 'Card Virtual',
        dataIndex: 'card_virtual',
        customRender: true,
        align: 'center',
        render: (queStatus) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Card Virtual:
                    </div>
                    <p className='responsiveInline'>
                        <StatusIcon status={queStatus} />
                    </p>
                </div>

            )

        }
    },
    {
        key: 5,
        title: 'Status',
        dataIndex: 'status',
        customRender: true,
        render: (status) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Status:
                    </div>
                    <p className='responsiveInline'>
                        {CardStatus.ItemFromValue(status) || '-'}
                    </p>
                </div>

            )
        }
    },
    {
        key: 6,
        title: 'Account',
        dataIndex: 'account',
        align: 'center',
        customRender: true,
        render: (value, row) => {
            return (
                <Link onClick={() => openAccountModal(row.account, row.processortype)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
                // <a href={`/accounts/${row.account}/${row.processortype}`}>
                //     <EyeOutlined style={{ fontSize: '22px' }} />
                // </a>
            )
        }
    },
    {
        key: 7,
        title: 'FH Account Id',
        dataIndex: 'paccountid',
    },
    {
        key: 8,
        title: 'Client',
        dataIndex: 'client',
        customRender: true,
        render: (value, row) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Client:
                    </div>
                    <div className='responsiveInline'>
                        {value}
                    </div>
                </div>
            )
        }
    },
    {
        key: 9,
        title: 'Holder',
        dataIndex: 'holder_fullname',
        customRender: true,
        render: (holder, row) => {
            return (
                <a href={`/holders/${row.holder}/detail`}>
                    <div className='responsiveTableData'>
                        Holder:
                    </div>
                    <div className='responsiveInline'>
                        {holder}
                    </div>
                </a>
            )
        }
    },
    {
        key: 10,
        title: 'FH Holder Id',
        dataIndex: 'pholderid',
    },
    {
    	key: 11,
    	title: 'Card Program',
    	dataIndex: 'card_program_name',
        customRender: true,
        render: (value, row) => {
            const card_program = value ? value : '-'
            return (
                <>
                    <div className='responsiveTableData'>
                        Card Program:
                    </div>
                    <div className='responsiveInline'>
                        {card_program}
                    </div>
                </>
            )
        }
    },
    
    {
        key: 12,
        title: 'Card PAN',
        dataIndex: 'card_pan',
        customRender: true,
        render: (value, row) => {
            const card_pan = value ? value : '-'
            return (
                <>
                    <div className='responsiveTableData'>
                        Card PAN:
                    </div>
                    <div className='responsiveInline'>
                        {card_pan}
                    </div>
                </>
            )
        }
    },
    // {
    // 	key: 8,
    // 	title: 'Country Ison',
    // 	dataIndex: 'extrafields__sub__card_country_ison',
    // },
    {
        key: 13,
        title: 'Action',
        dataIndex: 'id',
        align: 'center',
        customRender: true,
        render: (id, row) => {
            return (
                //comment the Link with openModal and uncomment the other one for the old details page.
                
                <Link onClick={() => openModal(id, row.processortype)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
                // <a href={`/cards/${id}/${row.processortype}`}>
                //     <EyeOutlined style={{ fontSize: '22px' }} />
                // </a>
            )
        }
    },
];

export default CardManagementColumns