import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { currencyBuilder } from "../../../const/Internalization";

//remove openModal prop for the old details page, edit action columun.
const TransactionsColumns = (openModal, openCardModal) => [
    {
        title: 'Card Id',
        dataIndex: 'card_id',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Pcardid:
                    </div>
                    <div className='responsiveInline'>
                        <Link key='Card Id' onClick={() => openCardModal(row.card_id, row.processor_type)}>
                            {value}
                        </Link>
                        {/* <a href={`/cards/${row.card_id}/${row.processor_type}`}>
                            {value}
                        </a> */}
                    </div>
                </div>

            )
        }

    },
    {
        title: 'FH Card Id',
        dataIndex: 'pcard_id',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Pcardid:
                    </div>
                    <div className='responsiveInline'>
                        {value}
                    </div>
                </div>

            )
        }

    },
    {
        title: 'Holder Name',
        dataIndex: 'holder',
        customRender: true,
        render: (value, row) => {
            const holder_name = value ? value : '-'
            return (
                <div>
                    <div className='responsiveTableData'>
                        Client Name:
                    </div>
                    <p className='responsiveInline'>
                        {holder_name}
                    </p>
                </div>
            )
        },
        sorter: () => { }

    },
    {
        title: 'Client Name',
        dataIndex: 'client',
        customRender: true,
        render: (value, row) => {
            const client = value ? value : '-'
            return (
                <div>
                    <div className='responsiveTableData'>
                        Client Name:
                    </div>
                    <p className='responsiveInline'>
                        {client}
                    </p>
                </div>
            )
        },
        sorter: () => { }

    },
    {
        title: 'Request Type',
        dataIndex: 'req_type'
    },
    {
        title: 'Merchant Name',
        dataIndex: 'merchant_name'
    },
    {
        title: 'Trx Description',
        dataIndex: 'trx_description'
    },
    {
        title: 'Trx Type',
        dataIndex: 'trx_type_name',
        customRender: true,
        render: (value, row) => {
            const trx_type = value ? value : '-'
            return (
                <div>
                    <div className='responsiveTableData'>
                        Trx Type:
                    </div>
                    <p className='responsiveInline'>
                        {trx_type}
                    </p>
                </div>
            )
        }
    },
    {
        title: 'Trx Date',
        dataIndex: 'trx_date',
        customRender: true,
        sorter: () => { },
        render: (value, row) => {
            // In the console deprecation warning for toListDateString
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <div>
                    <div className='responsiveTableData'>
                        Trx Date:
                    </div>
                    <p className='responsiveInline'>
                        {date}
                    </p>
                </div>
            )
        }
    },
    {
        title: 'Auth Amount',
        dataIndex: 'auth_amount_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <div>
                    <div className='responsiveTableData'>
                        Auth Amount:
                    </div>
                    <p className='responsiveInline'>
                        {currency}
                    </p>
                </div>
            )
        },
    },
    {
        title: 'Settle Date',
        dataIndex: 'settle_date',
        customRender: true,
        render: (value, row) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <div>
                    <div className='responsiveTableData'>
                        Settle Date:
                    </div>
                    <p className='responsiveInline'>
                        {date}
                    </p>
                </div>
            )
        }
    },
    {
        title: 'Settle Amount',
        dataIndex: 'settle_amount_with_currency',
        customRender: true,
        render: (value, row) => {

            const currency = value
            return (
                <div>
                    <div className='responsiveTableData'>
                        Settle Amount:
                    </div>
                    <p className='responsiveInline'>
                        {currency}
                    </p>
                </div>
            )
        },
    },
    {
        title: 'Trx Status',
        dataIndex: 'trx_status_name',
        customRender: true,
        render: (value, row) => {
            const trx_status = value ? value : '-'
            return (
                <div>
                    <div className='responsiveTableData'>
                        Trx Status:
                    </div>
                    <p className='responsiveInline'>
                        {trx_status}
                    </p>
                </div>
            )
        }
    },
    {
        title: 'Card PAN',
        dataIndex: 'card_pan',
        customRender: true,
        render: (value, row) => {
            const card_pan = value ? value : '-'
            return (
                <div>
                    <div className='responsiveTableData'>
                        Card PAN:
                    </div>
                    <p className='responsiveInline'>
                        {card_pan}
                    </p>
                </div>
            )
        }
    },
    {
        title: 'Action',
        dataIndex: 'trans_link',
        align: 'center',
        customRender: true,
        render: (trx_id, val) => {
            return (
                //comment the Link with openModal and uncomment the other one for the old details page.

                <Link key='Action' onClick={() => openModal(trx_id, val.req_type)}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
                // <Link to={`/transactions/${val.req_type}/${trx_id}`}>
                //     <EyeOutlined style={{ fontSize: '22px' }} />
                // </Link>
            )
        }
    },
];

export default TransactionsColumns