import { Status, UsageGroupValues } from "../../../../const/enums";
const UsageGroupsFormFields = [
    {
        key: 0,
        title: 'Processor Type',
		placeholder: 'Processor Type',
        dataIndex: 'processor_type',
        type: 'Select',
		options: [
			{
				key: 1,
				label: 'Tribe',
				value: 1
			},
			{
				key: 2,
				label: 'Stripe',
				value: 2
			}
		],
		rule:[
			{
				required: true,
				message: "Please select a processor type"
			},
		]
    },
    {
        key: 3,
        title: 'Usage Group ID',
		placeholder: 'Usage Group ID',
        dataIndex: 'usage_group_id',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 4,
        title: 'Name',
		placeholder: 'Name',
        dataIndex: 'name',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
	{
        key: 5,
        title: 'Contact',
		placeholder: 'Contact',
        dataIndex: 'contact',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: UsageGroupValues.asItemList(),
    },
	{
        key: 6,
        title: 'Contactless',
		placeholder: 'Contactless',
        dataIndex: 'contactless',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: UsageGroupValues.asItemList(),
    },
	{
        key: 7,
        title: 'Ecom',
		placeholder: 'Ecom',
        dataIndex: 'ecom',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: UsageGroupValues.asItemList(),
    },
	{
        key: 8,
        title: 'ATM',
		placeholder: 'ATM',
        dataIndex: 'atm',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: UsageGroupValues.asItemList(),
    },
    {
        key: 9,
        title: 'Deleted',
		placeholder: 'Deleted',
        dataIndex: 'is_deleted',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: Status.asItemList(),
    },
    {
        key: 10,
        title: 'Active',
		placeholder: 'Active',
        dataIndex: 'is_active',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: Status.asItemList(),
    },
    
];

export default UsageGroupsFormFields