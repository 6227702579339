export const CardSetPinFields = [

	{
		key: 0,
		placeholder: 'Pin',
		dataIndex: 'card_pin',
		type: 'Input',
		rule: [
			{
				required: true,
			},
		]

	},
];