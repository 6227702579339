import DetailLayout from "../../../Layout/detail";
import FormBuilder from "../../../components/Form";
import { useEffect, useState } from "react";
import { createFieldsForTypes, FieldsForTribe } from "./components/CreateProcessorFields";
import { ProcessorType, KYCType, MonitoringType, OTPType } from "../../../const/enums";
import Form from "antd/es/form/Form";
import { endpoint } from "../../../AppGlobal";
import Endpoint from "../../../api/endpoint";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Spin } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../stores/reducers/notificationreducer";
import { errorMessageHandler } from "../../../utils/ErrorHandler";
import { useNavigate } from "react-router-dom";
import { FormConvertDictNestedFields } from "../../../components/Form";


function CreateProcessor() {
    const { id } = useParams();
    const [formFields, setFormFields] = useState([]);
    const [progress, setProgress] = useState(false);
    const [blockUpdate, setBlockUpdate] = useState(false);
    const [editItem, setEditItem] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const breadCrumbItems = [
        {
            title: <a href="/clients">Client Management</a>,
        },
        {
            title: <a href={`/clients/${id}/processor`}>Processor</a>,
        },
        {
            title: "Create Processor"
        },
    ]

    const watchHandler = (value, values) => {

        if (value.kyc_type !== undefined) {
            let fields = createFieldsForTypes(editItem.type, value.kyc_type, editItem.monitoring_type)
            setFormFields(fields)
            editItem.kyc_type = value
            setEditItem({ ...editItem, kyc_type: value.kyc_type })
        }
        if (value.monitoring_type !== undefined) {
            let fields = createFieldsForTypes(editItem.type, editItem.kyc_type, value.monitoring_type)
            setFormFields(fields)
            setEditItem({ ...editItem, monitoring_type: value.monitoring_type })
        }
        if (value.type !== undefined) {
            let fields = createFieldsForTypes(value.type, editItem.kyc_type, editItem.monitoring_type)
            setFormFields(fields)
            setEditItem({ ...editItem, type: value.type })
        }
        if (value.settings__sub__addressCheck !== undefined) {
            let updatedfields = createFieldsForTypes(editItem.type, editItem.kyc_type, editItem.monitoring_type)
            updatedfields.find(item => item.dataIndex === 'settings__sub__allowed').isActive = value.settings__sub__addressCheck
            setFormFields(updatedfields)

        }

    }

    const fetchOptions = async () => {
        setProgress(true)
        const temp = {
            type: ProcessorType.TRIBE.value,
            kyc_type: KYCType.CLIENT.value,
            monitoring_type: MonitoringType.UNMONITORED.value,
            user: id,
            settings__sub__addressCheck: false,
            otp_type: OTPType.CLIENT3D.value
        }

        let programs = FieldsForTribe.find(item => item.dataIndex === 'programs');
        let usageGroup = FieldsForTribe.find(item => item.dataIndex === 'card_usage_group')
        let feeGroup = FieldsForTribe.find(item => item.dataIndex === 'fee_group')
        let limitGroup = FieldsForTribe.find(item => item.dataIndex === 'limit_group')
        if (Endpoint.status.success) {

            let fields = createFieldsForTypes(ProcessorType.TRIBE.value, KYCType.CLIENT.value, MonitoringType.UNMONITORED.value)
            const responsePrograms = await endpoint.fetchPrograms()

            if (responsePrograms._status === Endpoint.status.success) {
                programs.options = responsePrograms.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                programs.inprogress = false
            }
            else {
                programs.inprogress = false
                programs.error = programs.message || 'An error occurred'
            }

            const responseUsage = await endpoint.fetchUsageGroups()

            if (responseUsage._status === Endpoint.status.success) {
                usageGroup.options = responseUsage.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                usageGroup.inprogress = false
            }
            else {
                usageGroup.inprogress = false
                usageGroup.error = responseUsage.message || 'An error occurred'
            }

            const responseFee = await endpoint.fetchFeeGroups()

            if (responseFee._status === Endpoint.status.success) {
                feeGroup.options = responseFee.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                feeGroup.inprogress = false
            }
            else {
                feeGroup.inprogress = false
                feeGroup.error = responseFee.message || 'An error occurred'
            }

            const responseLimit = await endpoint.fetchLimitGroups()

            if (responseLimit._status === Endpoint.status.success) {
                limitGroup.options = responseLimit.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                limitGroup.inprogress = false
            }
            else {
                limitGroup.inprogress = false
                limitGroup.error = limitGroup.message || 'An error occured'
            }
            fields.find(item => item.dataIndex === 'settings__sub__allowed').isActive = temp.settings__sub__addressCheck;
            setEditItem(temp)
            form.setFieldsValue(temp)
            setFormFields(fields)
        }

        setProgress(false)

    }

    const save = async (values) => {
        setProgress(true);
        const response = await endpoint.createClientProcessor(FormConvertDictNestedFields(values))
        if (response._status === Endpoint.status.success) {
            dispatch(createNotification({ title: 'Success', message: "Created a new processor", type: 'success' }));
            const redirectInterval = setInterval(() => {
                clearInterval(redirectInterval);
                navigate(`/clients/${id}/processor`)
            }, 500)
        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setProgress(false);
    }


    useEffect(() => {
        fetchOptions();
    }, [])

    const [form] = Form.useForm()


    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            {progress ? (
                <div style={{ minHeight: '250px' }}>
                    <Spin spinning={progress}></Spin>
                </div>
            ) : (
                <FormBuilder disabled={progress} form={form} layout="vertical" models={formFields.filter(item => item.isActive !== false)} handler={watchHandler} onFinishHandler={save} >
                    <Row>
                        <Col className='formItemInline' xs={24} sm={12} xxl={12} >
                            <Button loading={progress} type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={blockUpdate} > Save</Button>
                        </Col>

                    </Row>
                </FormBuilder>
            )}
        </DetailLayout>
    );
}

export default CreateProcessor