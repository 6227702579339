const CommentsTabColumns = [
    {
        key: 0,
        title: 'Created at',
        dataIndex: 'created_at',
        sorter: () => { },
        customRender: true,
        render: (value) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <div>
                    <div className='responsiveTableData'>
                        Created at:
                    </div>
                    <p className='responsiveInline'>
                        {date}
                    </p>
                </div>
            )
        },
    },
    {
        key: 1,
        title: 'Comment',
        dataIndex: 'comment',
        sorter: () => { },
        customRender: true,
        render: (value, row) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Comment:
                    </div>
                    <p className='responsiveInline'>
                        {value}
                    </p>
                </div>
            )
        }
    },
    {
        key: 2,
        title: 'User',
        dataIndex: 'user',
        sorter: () => { },
        customRender: true,
        render: (value) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        User:
                    </div>
                    <p className='responsiveInline'>
                        {value}
                    </p>
                </div>

            )
        }
    },
];

export default CommentsTabColumns