import { endpoint } from '../../AppGlobal';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import Role from '../../const/role';
import BalanceColumns from './components/BalanceColumns';
import BalanceFilters from './components/BalanceFilters';
import ProcessorDetailsModal from '../ClientManagement/components/ProcessorDetailsModal';
import DrawerModal from '../../components/DrawerModal';
function BalanceManagement() {

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    return (

        <div>
            <CollapsedSearchLayout
                fetchMethod={endpoint.getMethod('fetchBalance')}
                columns={BalanceColumns(openModal)}
                filters={BalanceFilters}
                barRoles={[Role.BalanceEditor, Role.BalanceAdmin]}
                barAddURL="/balance/load"
                title={"Balance"}
            />
            {selectedItem && (
                <ProcessorDetailsModal
                    visible={modalVisible}
                    processor={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // <CollapsedSearchLayout 
        // fetchMethod={endpoint.getMethod('fetchBalance')} 
        // columns={BalanceColumns} 
        // filters={BalanceFilters} 
        // barRoles = {[Role.BalanceEditor, Role.BalanceAdmin]}
        // barAddURL = "/balance/load"
        // title={"Balance Management"}
        // />
    );
}

export default BalanceManagement;