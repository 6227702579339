import { ProcessorType } from "../../../../../const/enums"
export const formfields = [

	{
		key: 2,
		title: "Type",
		dataIndex: 'type',
        type: 'Select',
		options: [
			{
				key: 0,
				label: 'Tribe',
				value: 1
			},
			{
				key: 1,
				label: 'Stripe',
				value: 2
			}
		],
		rule:[
			{
				required: true,
				message: "Please select a processor type"
			},
		]

	},

	{
		key: 1,
		title: "Name",
        placeholder: 'Name',
		dataIndex: 'name',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please insert a processor name minimum (min:3 max: 25 characters)",
				pattern:/^[a-z,A-Z,\s]{3,25}$/
			},
		]

	},
	{
		key: 3,
		title: "Master Account Balance",
		dataIndex: 'master_account_balance_check',
        type: 'Switch'

	},
	{
		key: 55,
		title: "Master Account Currency",
		dataIndex: 'master_account_currency',
        type: 'Currency',
		rule:[
			{
				required: true,
				message: "Please select a currency type"
			},
		]

	},
	{
		key: 5,
		title: "Webhook URL",
		dataIndex: 'webhook_url',
        type: 'Input',
		rule:[
			{
				required:true,
				message: "Please enter a valid webhook URL",
				pattern:/^https?:\/\/.*$/
			}
		]

	},
	{
		key: 'data-enc',
		title: "Auto Generated PIN",
		dataIndex: 'data_encryption_key',
        type: 'hidden'

	},
	
	{
		key: 6,
		title: "Auto Generated PIN",
		dataIndex: 'is_auto_generated_pin',
        type: 'Switch'

	},
	{
		key: 7,
		title: "OTP Type",
		dataIndex: 'otp_type',
        type: 'Select',
		options: [
			{
				key: 0,
				label: 'FH - 3D OTP',
				value: 1
			},
			{
				key: 1,
				label: 'Client - 3D OTP',
				value: 2
			}
		]

	},
	
	{
		key: 30,
		title: "KYC Info",
        type: 'Header',
		showTitle:false,

	},
	{
		key: 8,
		title: "KYC Type",
		dataIndex: 'kyc_type',
        type: 'Select',
		options: [
			{
				key: 0,
				label: 'Passfort',
				value: 1
			},
			{
				key: 1,
				label: 'Client kyc',
				value: -1
			}
		]

	},
	///KYC----------------------------------------------------------------
	

	////////////////////////////////

	{
		key: 37,
		title: "Settings",
        type: 'Header',
		showTitle:false,

	},
	{
		key: 18,
		title: "Address Check",
		dataIndex: 'settings__sub__addressCheck',
        type: 'Switch'

	},
	{
		key: 17,
		title: "Allowed",
		dataIndex: 'settings__sub__allowed',
        type: 'CountryMulti',
		rule:[
			{
				required:true,
				message:'Please select a country'
			}
		]


	}

]

export const ClientKycFields = []

export const PassfortKYCFields = [
	{
		key: 'kyc_config__sub__client_id',
		title: "Client ID",
		dataIndex: 'kyc_config__sub__client_id',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please insert a client ID"
			},
		]
		

	},
	{
		key: 'kyc_config__sub__grant_type',
		title: "Grant Type",
		dataIndex: 'kyc_config__sub__grant_type',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please  insert a grant type"
			},
		]

	},
	{
		key: 'kyc_config__sub__client_secret',
		title: "Client secret",
		dataIndex: 'kyc_config__sub__client_secret',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please insert a client secret",
				
			},
		]

	},
	{
		key: 'kyc_config__sub__product_name',
		title: "Product Name",
		dataIndex: 'kyc_config__sub__product_name',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please insert a product name"
			},
		]

	}
]

export const unMonitoredFields = []

export const NapierMonitoringFields = [
	
	{
		key: 'monitoring_config__sub__client_id',
		title: "Client ID",
		dataIndex: 'monitoring_config__sub__client_id',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please insert a client id"
			},
		]

	},
	{
		key: 'monitoring_config__sub__grant_type',
		title: "Grant Type",
		dataIndex: 'monitoring_config__sub__grant_type',
        type: 'Input',
		ule:[
			{
				required: true,
				message: "Please select a grant type"
			},
		]


	},
	{
		key: 'monitoring_config__sub__client_secret',
		title: "Client secret",
		dataIndex: 'monitoring_config__sub__client_secret',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please insert a client secret"
			},
		]


	},
	{
		key: 'monitoring_config__sub__product_name',
		title: "Product Name",
		dataIndex: 'monitoring_config__sub__product_name',
        type: 'Input',
		rule:[
			{
				required: true,
				message: "Please insert a product name"
			},
		]

	}
]


export const FieldsForTribe = [
	{
		key: 22,
		title: "Programs",
		dataIndex: 'programs',
        type: 'MultiSelect',
		async: true,
		inprogress: true,
		rule:[
			{
				required: true,
				message:"Please select a program"
			}

		]
	},
	{
		key: 23,
		title: "Card Usage Groups",
		dataIndex: 'card_usage_group',
        type: 'MultiSelect',
		async: true,
		inprogress: true,
		rule:[
			{
				required: true,
				message:"Please select a usage group"
			}

		]
	},
	{
		key: 24,
		title: "Fee Groups",
		dataIndex: 'fee_group',
        type: 'MultiSelect',
		async: true,
		inprogress: true,
		rule:[
			{
				required: true,
				message:"Please select a fee group"
			}

		]
	},
	{
		key: 25,
		title: "Limit Groups",
		dataIndex: 'limit_group',
        type: 'MultiSelect',
		async: true,
		inprogress: true,
		rule:[
			{
				required: true,
				message:"Please select a limit group"
			}

		]
	}
]
export const FieldsForStripe = []

export const fieldsForTypes = (processor) =>{

	  switch(processor){
		case ProcessorType.TRIBE.value :
			return formfields.concat(FieldsForTribe)
		case ProcessorType.STRIPE.value:
			return formfields.concat(FieldsForStripe)
		default:
			return []
			
	  }

}

