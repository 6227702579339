import React, { useState } from "react";
import DetailLayout from '../Layout/detail';
import { Tabs } from "antd";
import { useNavigate} from "react-router-dom";

function TabDetail({
	navigateHandler,
	tab, 
	tabs,
	breadCrumbItems

}) {

    const navigate = useNavigate();
    const [tabkey,setTabKey] = useState((new Date()).getTime())
    const setActiveTab = (value)=>{
        setTabKey((new Date()).getTime())
        const path = navigateHandler(value)
		navigate(path)
        setTabKey((new Date()).getTime())
    }

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <Tabs activeKey={tab} onChange={setActiveTab} items={tabs} key={tabkey} >
            </Tabs>
        </DetailLayout>
    );
}

export default TabDetail