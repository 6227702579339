import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons'
import StatusIcon from "../../../components/StatusIcon";

export const ClientColumns = [
	{
		title: "Id",
		dataIndex: 'id',
		sorter: () => { }
	},
	{
		title: "Client Name",
		dataIndex: 'username',
		sorter: () => { }
	},
	{
		title: "Url",
		dataIndex: 'url',
	},
	{
		title: "Contact Name",
		dataIndex: 'first_name',
		customRender: true,
		render: (value, row) => {
			return (
				<div>
					<div className='responsiveTableData'>
						Status:
					</div>
					<p className='responsiveInline'>
						{value + " " + row.last_name}
					</p>
				</div>

			)
		}
	},
	{
		title: "Contact Email",
		dataIndex: 'email',
	},
	{
		title: "Date Joined",
		dataIndex: 'date_joined',
		sorter: () => { },
		customRender: true,
		render: (value, row) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<div>
					<div className='responsiveTableData'>
						Date Joined:
					</div>
					<p className='responsiveInline'>
						{date}
					</p>
				</div>
			)
		}
	},
	{
		title: "Total Balance of Main Accounts",
		dataIndex: 'master_account_balance',
		customRender: true,
		render: (value, row) => {

			const currency = isNaN(value) ? '-' : window.currentCurrency.format(value / 100)
			return (
				<div>
					<div className='responsiveTableData'>
						Total Balance of Main Accounts:
					</div>
					<p style={{display:'flex'}}>
						{currency}
					</p>
				</div>
			)
		},
		sorter: () => { }
	},
	{
		title: "Active",
		dataIndex: 'is_active',
        customRender: true,
        align: 'center',
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
	},
	{
		title: "Action",
		dataIndex: 'id',
		align: 'center',
		customRender: true,
		render: (id) => {
			return (
				<Link to={`/clients/${id}/detail`}>
					<EyeOutlined style={{ fontSize: '22px' }} />
				</Link>
			)
		}
	}

];

export default ClientColumns