import React from 'react';
import { Form, Input, Select, DatePicker, Button, Layout, Row, Col, Switch, Spin } from 'antd';
import { CounrtrieItems, CurrencyItems } from './CountrySelector';
import dayjs from 'dayjs'



export const ItemFactory = ({ model, layout }) => {
	const { RangePicker } = DatePicker;
	const { TextArea } = Input;
	const rangePresets = [
		{
			label: 'Last 7 Days',
			value: [dayjs().add(-7, 'd'), dayjs()],
		},
		{
			label: 'Last 14 Days',
			value: [dayjs().add(-14, 'd'), dayjs()],
		},
		{
			label: 'Last 30 Days',
			value: [dayjs().add(-30, 'd'), dayjs()],
		},
		{
			label: 'Last 90 Days',
			value: [dayjs().add(-90, 'd'), dayjs()],
		},
	];
	let field = null
	const keyval = 'input' + model.key
	let customAttributes = {
		name: model.dataIndex,
		label: layout === 'vertical' && (model.showTitle !== false) ? model.title : '',
		rules: model.rule,
		key: 'Item' + model.key

	}
	switch (model.type) {
		case 'Input':
			field = <Input placeholder={model.placeholder} key={keyval} />
			break;
		case 'Hidden':
			field = <Input type="hidden" placeholder={model.placeholder} key={keyval} />
			break;
		case 'Password':
			field = <Input.Password placeholder={model.placeholder} type='password' key={keyval} />
			break;
		case 'Tel':
			field = <Input type='tel' key={keyval} placeholder={model.placeholder} />
			break;
		case 'Select':
			field = <Select options={model.options || []} key={keyval} placeholder={model.placeholder} />
			break
		case 'MultiSelect':
			field = <Select mode="multiple" options={model.options || []} key={keyval} placeholder={model.placeholder} />
			break
		case 'Country':
			field = <Select options={CounrtrieItems || []} key={keyval} placeholder={model.placeholder} />
			break
		case 'Currency':
			field = <Select options={CurrencyItems || []} key={keyval} placeholder={model.placeholder} />
			break
		case 'CountryMulti':
			field = <Select mode="multiple" options={CounrtrieItems || []} key={keyval} placeholder={model.placeholder} />
			break
		case 'Date':
			field = <DatePicker style={{width:'250px'}} placeholder={model.placeholder} />
			break;
		case 'DateRange':
			field = <RangePicker presets={rangePresets} allowEmpty={model.allowEmpty || [false, false]} placeholder={model.placeholder} />
			break;
		case 'Switch':
			field = <Switch key={keyval} />
			customAttributes.valuePropName = 'checked'
			break;
		case 'Checkbox':
			field = <Switch key={keyval} />
			break;
		case 'Text':
			field = <TextArea key={keyval} placeholder={model.placeholder}/>
			break;
		case 'Button':
			field = <Button key={keyval} type={model.buttonType || 'primary'} htmlType={model.htmlType} icon={model.icon || null}>{model.title || ''}</Button>
			break;
		case 'Header':
			field = <h3 key={keyval}>{model.title}</h3>
			break;
		case 'SubForm':
			field = <LayoutInitalizer models={model.fields} layout={layout} placeholder={model.placeholder} />
			break;
		default:
			field = <Input type='text' key={keyval} placeholder={model.placeholder} />
			break;

	}

	if (model.async === true && model.inprogress === true) {
		const preloader = <div><Spin style={{ display: 'inline' }} /><div style={{ display: 'none' }}>{field}</div></div>
		field = preloader
	}


	return (
		<Form.Item
			{...customAttributes}
		>
			{field}
		</Form.Item>

	)

}


const LayoutFactory = ({ type, children }) => {
	let layout = null
	switch (type) {

		case 'inline':
			layout = <Row align={'middle'} justify={'justify'}    >  {children}</Row>;
			break;
		default:
			layout = <div>{children}</div>;
			break;

	}

	return (
		layout
	)

}

const ItemContainerFactory = ({ type, sizes, children }) => {
	let layout = null

	switch (type) {

		case 'inline':
			sizes = sizes === undefined ? {} : sizes
			const xs = sizes.xs || 24;
			const sm = sizes.sm || 24;
			const md = sizes.md || 12;
			const lg = sizes.lg || 8;
			const xl = sizes.xl || 4;
			const xxl = sizes.xxl || 4
			layout = <Col className='formItemInline' xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}> {children}</Col>;
			break;
		default:
			layout = <div>{children}</div>;
			break;

	}

	return (
		layout
	)

}

const LayoutInitalizer = ({ models, layout }) => {
	return (
		<LayoutFactory type={layout}>
			{models.map(model => { return <ItemContainerFactory key={'c' + model.key} type={layout} sizes={model.sizes}  ><ItemFactory key={'a' + model.key} model={model} layout={layout} /></ItemContainerFactory> })}
		</LayoutFactory>
	)
}

const FormBuilder = ({ models, binder, handler, layout = 'inline', form, onFinishHandler, disabled = false, children }) => {

	return (
		<Layout
			style={{
				backgroundColor: 'rgba(255,255,255,0)',
			}}
		>
			<Form
				onFinish={
					(values) => {
						if (onFinishHandler !== undefined) {
							onFinishHandler(values)
						}

					}
				}
				onValuesChange={
					(values) => {
						if (handler !== undefined && handler !== null) {
							handler(values, form.errors)
						}

					}
				}
				layout={layout}
				disabled={disabled}
				form={form}
				ref={binder}>

				<LayoutInitalizer models={models} layout={layout} />
				{children}
			</Form>
		</Layout>
	)
}


export const FormNormalizeForNestedFields = (item) => {

	Object.keys(item).forEach(key => {
		if (typeof item[key] === 'object' && !Array.isArray(item[key])) {
			const storedItem = item[key];
			delete item[key];
			if (storedItem !== null && storedItem !== undefined) {
				Object.keys(storedItem).forEach(subkey => {
					item[key + '__sub__' + subkey] = storedItem[subkey]
				})
			}
		}
	})

	return item
}

export const FormConvertDictNestedFields = (values) => {
	const keys = Object.keys(values)
	const subkeypattern = "__sub__";

	keys.forEach(key => {
		if (values[key] == null) {
			delete values[key]
		}
		if (key.indexOf(subkeypattern) > -1) {
			const val = values[key]
			delete values[key]
			const field_names = key.split(subkeypattern)
			const mainField = field_names[0]
			const subField = field_names[1]
			values[mainField] = values[mainField] === undefined ? {} : values[mainField];
			values[mainField][subField] = val
		}

	})
	return values
}

export default FormBuilder

