import React from 'react'
import historyColumns from '../components/HistoryColumns'
import { endpoint } from '../../../AppGlobal'
import ListPage from '../../../components/List'
function HolderHistory({holderId}) {
    return (
        <ListPage fetchMethod={async (page,filter)=>{ 
			const response = await endpoint.fetchHistoryOfHolder(holderId,page,filter)
			return response
		}} columns={historyColumns}   />
    
    )
}

export default HolderHistory