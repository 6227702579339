import React, { useEffect, useState } from 'react'
import DashboardHeader from './components/DashboardHeader'
import DashboardChart from './components/DashboardChart'
import Applayout from '../../Layout/app';
import { Layout } from 'antd';
import Endpoint from '../../api/endpoint';
import { endpoint } from '../../AppGlobal';
import { useDispatch } from "react-redux";
import { createNotification } from '../../stores/reducers/notificationreducer';
import { errorMessageHandler } from '../../utils/ErrorHandler';
import GenericTable from '../../components/GenericTable';
import TransactionsColumns from '../TransactionPage/components/TransactionsColumns'
import TransactionDetailsModal from '../TransactionPage/components/TransactionDetailsModal';
import CardDetailsModal from '../CardManagementPage/components/CardManagementDetailsModal';
import DrawerModal from '../../components/DrawerModal';

function Dashboard() {

    const dispatch = useDispatch()
    const { Content } = Layout;
    const [usage, setUsage] = useState(null)
    const [timeLine, setTimeLine] = useState([])
    const [haedLoading, setHeadLoading] = useState(false)
    const [timeLineLoading, setTimeLineLoading] = useState(false)
    const [notificationItems, setNotificationItems] = useState([])
    const [notificationLoading, setNotificationLoading] = useState(false)

    const usageData = async () => {
        setHeadLoading(true);
        const responseUsage = await endpoint.fetchUsage();
        if (responseUsage._status === Endpoint.status.success) {
            setUsage(responseUsage)
        } else {
            const message = errorMessageHandler(responseUsage)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setHeadLoading(false);
    }

    const timeLineData = async () => {
        setTimeLineLoading(true)
        const timeLineResponse = await endpoint.fetchCardsTimeLine();
        if (timeLineResponse._status === Endpoint.status.success) {
            setTimeLine(timeLineResponse)
        } else {
            const message = errorMessageHandler(timeLineResponse)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setTimeLineLoading(false)
    }

    const notificationData = async () => {
        setNotificationLoading(true)
        const notificationResponse = await endpoint.fetchLastNotifications();
        if (notificationResponse._status === Endpoint.status.success) {
            notificationResponse.results.forEach(item => {
                item.created_at = item.created_at.toListDateString();
            })
            setNotificationItems(notificationResponse)
        } else {
            const message = errorMessageHandler(notificationResponse)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setNotificationLoading(false)
    }

    useEffect(() => {
        const fetchData = async () => {

            usageData();
            timeLineData();
            notificationData();
        };

        fetchData();
    }, []);

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    const [selectedCard, setSelectedCard] = useState(null);
    const [cardModalVisible, setCardModalVisible] = useState(false);

    const openCardModal = (card_id, processor_type) => {
        setSelectedCard(card_id, processor_type);
        setCardModalVisible(true);
    };

    return (
        <Applayout>
            <Content>
                <h2 style={{ marginLeft: '16px' }}>Dashboard</h2>
                <DashboardHeader
                    usage={usage}
                    loading={haedLoading}
                />
                <DashboardChart
                    timeLine={timeLine}
                    loading={timeLineLoading}
                />
                <GenericTable
                    dataSource={notificationItems}
                    loading={notificationLoading}
                    columns={TransactionsColumns(openModal, openCardModal)} //remove openModal prop for the old details page
                    title={
                        <span>
                            Transactions (Last 20). More: {' '}
                            <a href="/transactions" style={{ textDecoration: 'underline' }}>
                                Transactions
                            </a>{' '}
                        </span>
                    }
                />
                {selectedItem && (
                    <TransactionDetailsModal
                        visible={modalVisible}
                        transaction={selectedItem}
                        closeModal={closeModal}
                    />
                )}
                {selectedCard && (
                    <CardDetailsModal
                        visible={cardModalVisible}
                        card={selectedCard}
                        closeModal={() => setCardModalVisible(false)}
                    />
                )}
            </Content>
        </Applayout>
    )
}

export default Dashboard