import { useState } from 'react';

const DrawerModal = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const openModal = (...args) => {
        setSelectedItem(args);
        setModalVisible(true);
    };

    const closeModal = () => {
        setSelectedItem(null);
        setModalVisible(false);
    };

    return {
        modalVisible,
        selectedItem,
        openModal,
        closeModal,
    };
};

export default DrawerModal;