import { AccountStatus } from "../../../const/enums";
const AccountsFilters = [
  {
    key: 0,
    title: 'Account ID',
    placeholder: 'Account ID',
    dataIndex: 'account_id',
  },
  {
    key: 1,
    title: 'FH Account ID',
    placeholder: 'FH Account ID',
    dataIndex: 'paccountid',
  },
  {
    key: 2,
    title: 'Holder ID',
    placeholder: 'Holder ID',
    dataIndex: 'holder_id',
  },
  {
    key: 3,
    title: 'Holder First Name',
    placeholder: 'Holder First Name',
    dataIndex: 'first_name',
  },
  {
    key: 4,
    title: 'Holder Last Name',
    placeholder: 'Holder Last Name',
    dataIndex: 'last_name',
  },
  {
    key: 5,
    title: 'Client',
    placeholder: 'Client',
    dataIndex: 'client',
  },
  {
    key: 6,
    title: 'Status',
    placeholder: 'Status',
    dataIndex: 'status',
    type: 'Select',
    options: AccountStatus.asItemList(),
  },
  {
    key: 7,
    title: 'Created At From',
    dataIndex: 'created_at',
    rangeNames: ['after', 'before'],
    rule: [{ required: false }],
    type: 'DateRange',
    allowEmpty: [true, true],
  },
];

export default AccountsFilters