import DetailLayout from "../../../Layout/detail";
import FormBuilder from "../../../components/Form";
import { useEffect, useState } from "react";
import { CreateClientIPAddressField } from "./components/CreateIPAddressField";
import Form from "antd/es/form/Form";
import { endpoint } from "../../../AppGlobal";
import Endpoint from "../../../api/endpoint";
import { useParams } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../stores/reducers/notificationreducer";
import { useNavigate } from "react-router-dom";
import { errorMessageHandler } from "../../../utils/ErrorHandler";

function CreateIPAddress() {
    const { id } = useParams();
    const [progress, setProgress] = useState(false);
    const [blockUpdate, setBlockUpdate] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const breadCrumbItems = [
        {
            title: <a href="/clients">Client Management</a>,
        },
        {
            title: <a href={`/clients/${id}/ipaddresses`}>Ip Addresses</a>,
        },
        {
            title: "Create Client"
        },
    ]
    const [form] = Form.useForm()

    const fetchOptions = async () => {
        setProgress(true)
        const temp = {
            user: id,
        }
        if (Endpoint.status.success) {
            form.setFieldsValue(temp)
        }

        setProgress(false)

    }

    useEffect(() => {
        fetchOptions();
    }, [])

    const onFinishHandler = async (values, ipId) =>{
        setProgress(true)
        const response = await endpoint.createClientIP(values)
        if (response._status === Endpoint.status.success) {
            dispatch(createNotification({ title: 'Success', message: "Created a new IP address", type: 'success' }));
            const redirectInterval = setInterval(() => {
                clearInterval(redirectInterval);
                navigate(`/clients/${id}/ipaddresses`)
            }, 500)
        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setProgress(false)
    }

    useEffect(() => {
        setFormFields(CreateClientIPAddressField)

    }, [])

    

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
           <FormBuilder 
                    disabled={progress} 
                    form={form} 
                    layout="vertical" 
                    models={formFields.filter(item => item.isActive !== false)} 
                    // handler={watchHandler}
                    onFinishHandler={onFinishHandler}
                    >
                    <Row>
                        <Col className='formItemInline' xs={24} sm={12} xxl={12} >
                            <Button loading={progress} type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={blockUpdate} > Save</Button>
                        </Col>

                    </Row>
                </FormBuilder>
        </DetailLayout>
    );
}

export default CreateIPAddress