import { Card, Layout, Spin } from "antd";
import { useEffect} from "react";
import { endpoint } from "../../AppGlobal";
import { useDispatch } from "react-redux";
import { logout } from "../../stores/reducers/authreducer";
function LogoutView(){

	
    const dispatch = useDispatch()
	const _logout = async ()=>{
        await endpoint.logout();
		//endpoint.sessionManager.clearAuthInfo();
		dispatch(logout())

	}

	useEffect(()=>{
		_logout();
	},[])

	return(
		  <Layout style={{width:'100%', height:'100vh', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
			 <Card>
			 	<div className="logo"></div>
			  	<Spin style={{display:'inline'}} ></Spin><h3 style={{display:'inline'}}> Waiting ...</h3>
			 </Card>
		  </Layout>
	)

}

export default LogoutView