export const CreateClientFields = [
	{
		key: 0,
		title: "First Name",
		placeholder: 'First Name',
		dataIndex: 'first_name',
		type: 'Input',
		rule: [
			{
				required: true,
				pattern: /^[a-z,A-Z,\s,çÇşŞöÖüÜİığĞ]{3,250}$/,
				message: "Minimum 3, Maximum 250 characters (Use only letters or spaces)"
			},
		]

	},
	{
		key: 1,
		title: "Last Name",
		placeholder: 'Last Name',
		dataIndex: 'last_name',
		type: 'Input',
		rule: [
			{
				required: true,
				pattern: /^[a-z,A-Z,\s,çÇşŞöÖüÜİığĞ]{3,250}$/,
				message: "Minimum 3, Maximum 250 characters (Use only letters or spaces)"
			},
		]

	},
	{
		key: 2,
		title: "Username",
		placeholder: 'Username',
		dataIndex: 'username',
		type: 'Input',
		rule: [
			{
				required: true,
				pattern: /^[a-z,A-Z,çÇşŞöÖüÜİığĞ]{3,250}$/,
				message: "Minimum 3, Maximum 250 characters (Use only letters, do not use spaces)"
			},
		]

	},
	{
		key: 3,
		title: 'Email address',
		placeholder: 'Email address',
		dataIndex: 'email',
		rule: [{
			required: true,
			pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
			message: "Invaild email format"
		},
		],
		type: 'Input'
	},
	{
		key: 4,
		title: 'URL',
		placeholder: 'URL',
		dataIndex: 'profile__sub__url',
		rule: [{
			required: true,
		},
		],
		type: 'Input'
	},
	{
		key: 5,
		title: "Password",
		placeholder: 'Password',
		dataIndex: 'password',
		type: 'Password',
		rule: [
			{
				required: true,
				message: "Your password must contain at least 8 characters, one special character, one capital letter.",
				pattern: /^(?=.*[A-Z])(?=.*[\W_])(.{8,})$/
			},
		]

	},
	{
		key: 6,
		title: "Confirm Password",
		placeholder: 'Confirm Password',
		dataIndex: 'confirm_password',
		type: 'Password',
		rule: [
			{
				required: true,
				message: "Your password must contain at least 8 characters, one special character, one capital letter.",
				pattern: /^(?=.*[A-Z])(?=.*[\W_])(.{8,})$/
			},
			({ getFieldValue }) => ({
				validator(_, value) {
					if (!value || getFieldValue('password') === value) {
						return Promise.resolve();
					}
					return Promise.reject(new Error('The two passwords do not match.'));
				},
			}),
		]

	},
	{
		key: 7,
		title: 'Phone',
		placeholder: 'Phone',
		dataIndex: 'profile__sub__phone',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 8,
		title: 'Trade Name',
		placeholder: 'Trade Name',
		dataIndex: 'profile__sub__tradeName',
		rule: [{
			required: true,
			pattern: /^[a-z,A-Z,\s,0-9,\.,İıÖöçÇşŞğĞüÜ]{3,250}$/,
			message: "Minimum 3 characters"
		},
		],
		type: 'Input'
	},
	{
		key: 9,
		title: 'Bank Name',
		placeholder: 'Bank Name',
		dataIndex: 'profile__sub__bank_name',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 10,
		title: 'IBAN',
		placeholder: 'IBAN',
		dataIndex: 'profile__sub__iban',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 11,
		title: 'BIC/SWIFT',
		placeholder: 'BIC/SWIFT',
		dataIndex: 'profile__sub__bic_swift',
		type: 'Input',
		rule: [
			{
				required: true,
				pattern: /^[a-z,A-Z,0-9]{3,11}$/,
				message: 'Please enter a valid swift number'
			}]

	},
	{
		key: 12,
		title: 'Account Number',
		placeholder: 'Account Number',
		dataIndex: 'profile__sub__account_number',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 13,
		title: 'Comments',
		placeholder: 'Comments',
		dataIndex: 'profile__sub__comment',
		type: 'Text',
		rule: [
			{
				required: false,
			}]

	}

]