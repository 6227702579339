import StatusIcon from "../../../components/StatusIcon";
import countries from "../../../const/country.json"
import { ProcessorType } from "../../../const/enums";
import { CardStatus } from "../../../const/enums";
export const CardListFields = [

	{
		label: 'pcardid',
		dataIndex: 'pcardid',
	},
	{
		label: "Created at",
		dataIndex: 'created_at',
		customRender: true,
		render: (value) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<p className='responsiveInline'>
					{date}
				</p>
			)
		},
	},
	{
		label: "Card PIN",
		dataIndex: 'card_pin',
	},
	{
		label: "Card PAN",
		dataIndex: 'card_pan',
	},
	{
		label: "Card CVC",
		dataIndex: 'card_cvc2',
	},
	{
		label: 'Expiration Month',
		dataIndex: 'expiration_month',
	},
	{
		label: 'Expiration Year',
		dataIndex: 'expiration_year',
	},
	{
		label: 'Card Virtual',
		dataIndex: 'card_virtual',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}
	},
	{
		label: 'Card Status',
		dataIndex: 'status',
		customRender: true,
		render: (status) => {

			return (
				<p>{CardStatus.ItemFromValue(status)}</p>
			)
		}

	},
	{
		label: "Is Deleted",
		dataIndex: 'is_deleted',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}
	},
	{
		label: "Status",
		dataIndex: 'is_active',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}
	},
	{
		label: 'Account',
		dataIndex: 'account',
		// customRender:true,
		// render:(value)=>{
		//     return(
		//         <a href="#">
		//             <EyeOutlined style={{fontSize:'22px'}}/>
		//         </a>
		//     )
		// }
	},
	{
		label: 'Holder',
		dataIndex: 'holder',
		customRender: true,
		render: (value) => {
			const holder = value[0]
			return (
				<a href={`/holders/${holder.id}/detail`}>
					{holder.first_name + ' ' + holder.last_name}
				</a>

			)
		}
	},
	{
		label: 'Client',
		dataIndex: 'client',
		customRender: true,
		render: (value) => {
			return (
				<div>
					{value}
				</div>

			)
		}
	},
	{
		label: 'Delivery Address',
		dataIndex: 'delivery_address',
	}
];

export const TribeCardListFields = [


	{
		noLabel: true,
		dataIndex: 'extrafields__sub__acs_password',
		customRender: true,
		render: (text) => {
			return (
				<h4 style={{ display: 'inline', marginLeft: '-16px' }}>Additional Info</h4>
			)
		}
	},
	// {

	// 	label: 'Acs Password',
	// 	dataIndex: 'extrafields__sub__acs_password',
	// },
	{
		label: 'Delivery title',
		dataIndex: 'extrafields__sub__delivery_title',
	},
	{
		label: 'Program ID',
		dataIndex: 'extrafields__sub__card_program_id',
	},
	{
		label: 'Name Line 3',
		dataIndex: 'extrafields__sub__card_name_line_3',
	},
	{
		label: 'Delivery Last Name',
		dataIndex: 'extrafields__sub__delivery_last_name',
	},
	{
		label: 'Delivery First Name',
		dataIndex: 'extrafields__sub__delivery_first_name',
	},
	{
		label: 'Order Shipping Method',
		dataIndex: 'extrafields__sub__order_shipping_method',
	},
	{
		label: 'Country',
		dataIndex: 'extrafields__sub__card_country_ison',
		type: 'Input',
		customRender: true,
		render: (text) => {
			let ison = -1
			try {
				ison = parseInt(text)
			} catch (e) {

			}
			const countryItem = countries.find(country => country.isoNumeric3 === parseInt(ison))
			const name = countryItem ? countryItem.countryName : 'Unknown';
			return (
				<>{name}</>
			)
		},
		rule: [
			{
				required: true,
			}
		]
	}


]


export const listFieldsForCard = (processorType) => {


	switch (processorType) {
		case ProcessorType.TRIBE.value:
			let concat = CardListFields.concat(TribeCardListFields)
			return concat
		case ProcessorType.STRIPE.value:
			return CardListFields
		default:
			return CardListFields
	}


}