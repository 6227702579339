import { ProcessorType, Status, IDType, Titles } from "../../../const/enums"

export const formType = {
	DETAIL: 0,
	ADDRESS: 1,
}

export const AddressFormFields = [

	{
		key: 8,
		title: 'Address',
		type: 'Header',
		showTitle: false

	},
	{
		key: 9,
		title: 'Street Name',
		placeholder: 'Street Name',
		dataIndex: 'address__sub__street_name',
		type: 'Input',
		rule: [
			{
				required: true,
			}
		]
	},
	{
		key: 10,
		title: 'Street Number',
		placeholder: 'Street Number',
		dataIndex: 'address__sub__street_number',
		type: 'Input',
		rule: [
			{
				required: false,
			}
		]
	},
	{
		key: 11,
		title: 'Floor',
		placeholder: 'Floor',
		dataIndex: 'address__sub__floor',
		type: 'Input',
		rule: [
			{
				required: false,
			}
		]
	},
	{
		key: 12,
		title: 'Apartment',
		placeholder: 'Apartment',
		dataIndex: 'address__sub__apartment',
		type: 'Input',
		rule: [
			{
				required: false,
			}
		]
	},
	{
		key: 13,
		title: 'Zip Code',
		placeholder: 'Zip Code',
		dataIndex: 'address__sub__zip_code',
		type: 'Input',
		rule: [
			{
				required: true,
			}
		]
	},
	{
		key: 14,
		title: 'Neighborhood',
		placeholder: 'Neighborhood',
		dataIndex: 'address__sub__neighborhood',
		type: 'Input',
		rule: [
			{
				required: false,
			}
		]
	},
	{
		key: 15,
		title: 'City',
		placeholder: 'City',
		dataIndex: 'address__sub__city',
		type: 'Input',
		rule: [
			{
				required: true,
			}
		]
	},
	{
		key: 16,
		title: 'Region',
		placeholder: 'Region',
		dataIndex: 'address__sub__region',
		type: 'Input',
		rule: [
			{
				required: true,
			}
		]
	},
	{
		key: 17,
		title: 'Additional Info',
		placeholder: 'Additional Info',
		dataIndex: 'address__sub__additional_info',
		type: 'Input',
		rule: [
			{
				required: false,
			}
		]
	},
	{
		key: 18,
		title: 'Country',
		placeholder: 'Country',
		dataIndex: 'address__sub__country_ison',
		type: 'Country',
		rule: [
			{
				required: true,
			}
		]
	}

]

export const HolderEditFormFields = [

	{
		key: 0,
		title: 'First Name',
		placeholder: 'First Name',
		dataIndex: 'first_name',
		rule: [
			{
				required: true,
				pattern: /^[a-z,A-Z,\s,çÇşŞöÖüÜİığĞ]{3,250}$/,
				message: "Minimum 3, Maximum 250 characters (Use only letters or spaces)"
			},
		],
		type: 'Input'
	},
	{
		key: 1,
		title: 'Last Name',
		placeholder: 'Last Name',
		dataIndex: 'last_name',
		rule: [{
			required: true,
			pattern: /\w{3,20}/,
			message: "Minimum 3 characters"
		}
		],
		type: 'Input'
	},
	{
		key: 2,
		title: 'Email',
		placeholder: 'Email',
		dataIndex: 'email',
		rule: [{
			required: true,
			pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
			message: "Invaild email format"
		},
		],
		type: 'Input'
	},
	{
		key: 3,
		title: 'Phone Number',
		placeholder: 'Phone Number',
		dataIndex: 'phone_number',
		rule: [{
			required: true,
			pattern: /\w{3,20}/,
			message: "Minimum 3 characters"
		},
		],
		type: 'Input'
	},
	{
		key: 4,
		title: 'Status',
		placeholder: 'Select Status',
		dataIndex: 'is_active',
		rule: [{
			required: true,

		}
		],
		type: 'Select',
		options: Status.asItemList(),
	},
	{
		key: 5,
		title: 'Nationality',
		placeholder: 'Select Nationality',
		dataIndex: 'country',
		rule: [{
			required: true,

		}
		],
		type: 'Country',
	},
	{
		key: 6,
		title: 'Id Type',
		placeholder: 'Select Id Type',
		dataIndex: 'id_type',
		type: 'Select',
		options: IDType.asItemList(),
		rule: [
			{
				required: true,
			}]
	}
	,
	{
		key: 7,
		title: 'Id Value',
		placeholder: 'Id Value',
		dataIndex: 'id_value',
		type: 'Input',
		rule: [
			{
				required: true,
			}]

	}


]

const TribeFields = [
	{
		key: 'Tribe 1',
		title: 'Title',
		placeholder: 'Title',
		dataIndex: 'title',
		type: 'Select',
		options: Titles.asItemList(),
		rule: [
			{
				required: false,
			}]
	},
	{
		key: 'Tribe 2',
		title: 'Limit Group id',
		placeholder: 'Limit Group id',
		dataIndex: 'holder_limit_group_id',
		type: 'M',
		rule: [
			{
				required: true,
			}]

	}
]

export const formFieldForProcessor = (processorType) => {

	switch (processorType) {
		case ProcessorType.TRIBE.value:
			let concat = [TribeFields[0]].concat(HolderEditFormFields)
			concat.push(TribeFields[1])
			return concat.concat(AddressFormFields)
		case ProcessorType.STRIPE.value:
			return HolderEditFormFields.concat(AddressFormFields)
		default:
			return HolderEditFormFields.concat(AddressFormFields)
	}


}

