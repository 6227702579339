import StatusIcon from "../../../../components/StatusIcon";
import { ContactType, ProcessorType } from "../../../../const/enums";
import { isonToCurrencyName } from "../../../../components/CountrySelector";
const ProgramsListFields = [
    {
        label: 'Processor Type',
        dataIndex: 'processor_type',
        customRender: true,
        render: (type) => {
            return (
                <div>
                    <div className='responsiveTableData'>
                        Type:
                    </div>
                    <div style={{ display: 'flex' }}>
                        {ProcessorType.ItemFromValue(type)}
                    </div>
                </div>
            )
        }
    },
    {
        label: 'Program ID',
        dataIndex: 'program_id'
    },
    {
        label: 'Name',
        dataIndex: 'name'
    },
    {
        label: 'Default Currency',
        dataIndex: 'default_currency',
        customRender: true,
		render: (value, row) => {
			return (
				<p className='responsiveInline'>
					{isonToCurrencyName(value)}
				</p>
			)
		}
    },
    {
        label: 'Contact Type',
        dataIndex: 'contact_type',
        customRender: true,
        render: (value, row) => {
			return (
				<div>
                    <div className='responsiveTableData'>
                        Contact Type:
                    </div>
                    <div style={{ display: 'flex' }}>
                        {ContactType.ItemFromValue(value)}
                    </div>
                </div>
			)
		}
    },
    {
        label: 'Virtual',
        dataIndex: 'is_virtual',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        label: 'Enabled',
        dataIndex: 'is_enabled',
        customRender: true,
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    
];

export default ProgramsListFields