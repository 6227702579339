const Role = {
	ClientReader:'ClientReader',
	ClientEditor:'ClientEditor',
	ClientAdmin : 'ClientAdmin',
	CardReader:'CardReader',
	CardEditor:'CardEditor',
	CardAdmin:'CardAdmin',
	UserReader:'UserReader',
	UserEditor:'UserEditor',
	UserAdmin:'UserAdmin',
	HolderReader:'HolderReader',
	HolderEditor:'HolderEditor',
	HolderAdmin:'HolderAdmin',
	TransactionReader:'TransactionReader',
	TransactionEditor:'TransactionEditor',
	TransactionAdmin:'TransactionAdmin',
	AccountReader:'AccountReader',
	AccountEditor:'AccountEditor',
	AccountAdmin:'AccountAdmin',
	ReportingReader:'ReportingReader',
	ReportingEditor:'ReportingEditor',
	ReportingAdmin:'ReportingAdmin',
	BalanceReader:'BalanceReader',
	BalanceEditor:'BalanceEditor',
	BalanceAdmin:'BalanceAdmin',
	PassfortAdmin:'PassfortAdmin',
	PassfortEditor:'PassfortEditor',
	PassfortReader:'PassfortReader',
	ProgramAdmin:'ProgramAdmin',
	ProgramEditor:'ProgramEditor',
	ProgramReader:'ProgramReader',
	FeeGroupAdmin:'FeeGroupAdmin',
	FeeGroupEditor:'FeeGroupEditor',
	FeeGroupReader:'FeeGroupReader',
	LimitGroupAdmin:'LimitGroupAdmin',
	LimitGroupEditor:'LimitGroupEditor',
	LimitGroupReader:'LimitGroupReader',
	UsageGroupAdmin:'UsageGroupAdmin',
	UsageGroupEditor:'UsageGroupEditor',
	UsageGroupReader:'UsageGroupReader',
	Superuser: '*',
	ANY:'any'
}

export default Role