import { createSlice/*, configureStore*/ } from '@reduxjs/toolkit'
import { endpoint } from '../../AppGlobal';
import { User, AuthInfo } from '../../model/auth';

const setAuthSliceInitialState = () => {
	const authInfo = endpoint.sessionManager.getAuthInfo()
	if (authInfo != null) {
		endpoint.authModel = authInfo
		return authInfo.user
	}
	return null;
}

const authSlice = createSlice({

	name: 'auth',
	initialState: {
		user: setAuthSliceInitialState()
	},
	reducers: {
		login: (state, action) => {
			state.user = (new AuthInfo(action.payload.access, action.payload.refresh)).user
		},
		logout: (state) => {
			state.user = null;
		},
		updateAuthInfo: (state, action) => {
			const authInfo = new AuthInfo(action.payload.access, action.payload.refresh)
			state.user = authInfo.user;


		}
	}
});

export const { login, logout, updateAuthInfo } = authSlice.actions;
export default authSlice.reducer