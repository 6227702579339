import { CardStatus, Programs } from "../../../const/enums";
const CardManagementFilters = [
    {
        key:0,
        title: "Holder Id",
        placeholder:"Holder Id",
        dataIndex: 'holder'
    },
    {
        key:1,
        title: "FH Holder Id",
        placeholder:"FH Holder Id",
        dataIndex: 'pholderid'
    },
    {
        key:2,
        title: 'Account Id',
        placeholder:'Account Id',
        dataIndex: 'account_id'
    },
    {
        key:3,
        title: 'FH Account Id',
        placeholder:'FH Account Id',
        dataIndex: 'paccountid'
    },
    {
        key:4,
        title: 'Card Id',
        placeholder:'Card Id',
        dataIndex: 'card_id',
    },
    {
        key:5,
        title: 'FH Card Id',
        placeholder:'FH Card Id',
        dataIndex: 'pcardid',
    },
    {
        key:6,
        title: 'Card Expiration Month',
        placeholder:'Card Expiration Month',
        dataIndex: 'expiration_month'
    },
    {   
        key:7,
        title: 'Card Expiration Year',
        placeholder:' Card Expiration Year',
        dataIndex: 'expiration_year'
    },
    {
        key:8,
        title: 'Card PAN',
        placeholder:"Card PAN",
		dataIndex:'card_pan',
    },
    {
        key:9,
        title: 'Card Virtual',
        placeholder:'Card Virtual',
        dataIndex: 'card_virtual',
        type:'Select',
        options:[
            {
                key:10,
                label:'Virtual',
                value:true
            },
            {
                key:11,
                label:'Physical',
                value:false
            }
        ]
    },
    {
        key:12,
        title: 'Card Status',
        placeholder:'Card Status',
        dataIndex: 'status',
        type: 'Select',
        options:CardStatus.asItemList()
    },
    {
        key:13,
        title: 'Card Program',
        placeholder:'Card Program',
        dataIndex: 'card_program_id',
        type: 'Select',
        options:Programs.asItemList()
    },
    {
        key:14,
        title: "Holder's First Name",
        placeholder:"Holder's First Name",
        dataIndex: 'holder_first_name'
    },
    {
        key:15,
        title: "Holder's Last Name",
        placeholder:"Holder's Last Name",
        dataIndex: 'holder_last_name'
    },
    {
        key:16,
        title: 'Client',
        placeholder:"Client",
		dataIndex:'client',
    },
    {
        key:17,
        title: 'Created At From',
		dataIndex:'created_at',
		rangeNames: ['after', 'before'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true],
    },
];

export default CardManagementFilters