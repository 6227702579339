import React, { useState } from 'react';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import TransactionsAuthorizationsFilters from './components/TransactionAuthorizationsFilters';
import { endpoint } from '../../AppGlobal';
import TransactionsColumns from '../TransactionPage/components/TransactionsColumns';
import TransactionDetailsModal from '../TransactionPage/components/TransactionDetailsModal';
import DrawerModal from '../../components/DrawerModal';
import CardDetailsModal from '../CardManagementPage/components/CardManagementDetailsModal';

function Authorizations() {

    const fetchMethod = endpoint.getMethod('fetchAuthorizations');

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    const [selectedCard, setSelectedCard] = useState(null);
    const [cardModalVisible, setCardModalVisible] = useState(false);

    const openCardModal = (card_id, processor_type) => {
        setSelectedCard(card_id, processor_type);
        setCardModalVisible(true);
    };

    return (

        <div>
            <CollapsedSearchLayout
                columns={TransactionsColumns(openModal, openCardModal)}
                fetchMethod={fetchMethod}
                filters={TransactionsAuthorizationsFilters}
                barRoles={[]}
                title={"Authorizations"}
            />
            {selectedItem && (
                <TransactionDetailsModal
                    visible={modalVisible}
                    transaction={selectedItem}
                    closeModal={closeModal}
                />
            )}
            {selectedCard && (
                <CardDetailsModal
                    visible={cardModalVisible}
                    card={selectedCard}
                    closeModal={() => setCardModalVisible(false)}
                />
            )}
        </div>

        // <CollapsedSearchLayout
        //     columns={TransactionsColumns(openModal)}
        //     fetchMethod={fetchMethod}
        //     filters={TransactionsAuthorizationsFilters}
        //     barRoles={[]}
        //     title={"Authorizations"}
        // />
    )
}

export default Authorizations