import React from 'react'
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import AccountsColumns from './components/AccountsColumns';
import AccountsFilters from './components/AccountsFilters';
import { endpoint } from '../../AppGlobal';
import AccountDetailsModal from './components/AccountsDetailsModal';
import DrawerModal from '../../components/DrawerModal';
import { useLocation } from 'react-router-dom';

function Accounts() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const holderId = urlParams.get('holder_id');

    const fetchMethod = async (page, filter) => {
        if (holderId) {
            filter = { ...filter, holder_id: holderId };
        }
        const response = await endpoint.fetchAccounts(page, filter);
        return response;
    };

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    return (

        <div>
            <CollapsedSearchLayout
                columns={AccountsColumns(openModal)} //remove openModal for the old details page
                fetchMethod={fetchMethod}
                filters={AccountsFilters}
                barRoles={[]}
                title={"Accounts"}
            />
            {selectedItem && (
                <AccountDetailsModal
                    visible={modalVisible}
                    account={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // For the old details page uncomment this, comment above div.

        // <CollapsedSearchLayout
        //     columns={AccountsColumns}
        //     fetchMethod={fetchMethod}
        //     filters={AccountsFilters}
        //     barRoles={[]}
        //     title={"Accounts"}
        // />
    )
}

export default Accounts