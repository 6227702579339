export const CreatePassfortSettingsFields = [
    {
        key: 0,
        title: 'Grant Type',
		placeholder: 'Grant Type',
        dataIndex: 'grant_type',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 1,
        title: 'Client Secret',
		placeholder: 'Client Secret',
        dataIndex: 'client_secret',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 2,
        title: 'Client ID',
		placeholder: 'Client ID',
        dataIndex: 'client_id',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 3,
        title: 'Product Name',
		placeholder: 'Product Name',
        dataIndex: 'product_name',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
]