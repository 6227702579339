import { listFieldsForAccount } from "../../AccountsDetail/components/AccountsListFields";
import { endpoint } from "../../../AppGlobal";
import { EditableListView } from "../../../components/EditableItemView";
import { Row, Select, Spin, Drawer } from "antd";
import { AccountStatus } from "../../../const/enums";
import { useState } from "react";
import Endpoint from "../../../api/endpoint";
import { errorMessageHandler } from "../../../utils/ErrorHandler";
import { createNotification } from "../../../stores/reducers/notificationreducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Role from "../../../const/role";
import { roleCheckForUser } from "../../../utils/RoleCheck";
import { currencyBuilder } from "../../../const/Internalization";
function AccountDetailsModal({ visible, account, closeModal }) {
    const id = account.length === 2 ? account[0] : account; //if it opens directly from accounts list page, account[0] = id, if it opens from card management list page, account = id
    const processor = account ? account[1] : null;
    const { user } = useSelector(state => state.authreducer);
    // const { id, processor } = useParams();
    const processorType = parseInt(processor, 0)
    const listFields = listFieldsForAccount(processorType);
    const [progress, setProgress] = useState(true)
    const [status, setStatus] = useState(AccountStatus.SUSPENDED)
    const [statusProgress, setStatusProgress] = useState(false)
    const hasRoleIntersection = roleCheckForUser(user, [Role.AccountEditor, Role.AccountAdmin]);
    const dispatch = useDispatch()

    const fetchMethod = async (accountId) => {
        let response = await endpoint.fetchAccountDetails(accountId)
        if (response._status === Endpoint.status.success) {
            response.formattedReservedBalance = isNaN(response.reserved_balance) ? '-' : currencyBuilder(response.currency_ison).format(response.reserved_balance / 100)
            response.formattedSettledBalance = isNaN(response.settled_balance) ? '-' : currencyBuilder(response.currency_ison).format(response.settled_balance / 100)
            response.formattedAvailableBalance = isNaN(response.available_balance) ? '-' : currencyBuilder(response.currency_ison).format(response.available_balance / 100)
        }
        return response
    }

    const fetchHandler = (response) => {

        if (response._status === Endpoint.status.success) {
            setStatus(AccountStatus.EnumItemFromValue(response.status))
        }
        setProgress(false)
    }
    const updateStatus = async (val) => {
        setStatusProgress(true)
        const response = await endpoint.updateAccountStatus(id, val)
        if (response._status === Endpoint.status.success) {
            setStatus(AccountStatus.EnumItemFromValue(val))
            dispatch(createNotification({ title: 'Success', message: 'Account status updated', type: 'success' }));
            setTimeout(() => {
				window.location.reload(true); // For the drawer form of the details, after updating reload the page for the updated values.
			}, 500);

        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setStatusProgress(false)
    }
    const enabled = hasRoleIntersection && (status || AccountStatus.SUSPENDED).value !== AccountStatus.SUSPENDED.value
    const statusItem = statusProgress ? <Spin /> : <Select style={{ width: '130px', marginTop: '8px', marginLeft: '10px' }} disabled={!enabled} onChange={updateStatus} value={status} options={AccountStatus.asItemList()} />
    return (
        <Drawer
            title="Account Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700}
        >
            <EditableListView id={id}
                listFields={listFields}
                fetchMethod={fetchMethod}
                fetchHandler={fetchHandler}
                title='Details'
                key={status.value}
            />
            {
                !progress && (
                    <>
                        <Row>
                            <p style={{ fontWeight: 'bold' }}>Change Account Status:</p>{statusItem}
                        </Row>
                    </>
                )
            }
        </Drawer>

    );
}

export default AccountDetailsModal