

export class IdleTimer {
	events = ['mousedown','mousemove', 'keypress', 'scroll', 'touchstart'];
	#interval = null;
	#warningInterval = null;
	#maxIdleTimeout = null;
	#timeOutHanlder = null;
	#warningSeconds = null;
	constructor(maxIdleTimeoutMinute = 5, warningSeconds = 45) {

		this.#maxIdleTimeout = maxIdleTimeoutMinute * 60000;
		// console.log("Max Idle Timeout (milliseconds):", this.#maxIdleTimeout);
		this.#warningSeconds = warningSeconds
		this.#initialize();
	}

	get warningSeconds(){
		return this.#warningSeconds
	}

	get idleTime(){
		return (new Date().getTime() - document.lastAction.getTime());
	}


	updateLastAction() {
		document.lastAction = new Date();
		
	}

	addHandler(handler) {
		this.#timeOutHanlder = handler
	}

	removeHandler(handler) {
		if (this.#timeOutHanlder === handler) {
		  this.#timeOutHanlder = null;
		  clearInterval(this.#warningInterval);
		}
	  }
    
	fire(){
		if(this.#timeOutHanlder){
			let seconds = this.#warningSeconds
			this.#warningInterval = setInterval(()=>{
				seconds = seconds -1 > -1 ? seconds - 1 : 0;
				// console.log("Remaining seconds until logout:", seconds);
				if(seconds === 0){
					clearInterval(this.#warningInterval)
				}
				this.#timeOutHanlder(seconds)
			}, 1000);
		}
	}

	#initialize(){
		document.lastAction = new Date()
		this.events.forEach(event => document.addEventListener(event, this.updateLastAction.bind(this), false));
		this.reset();
		this.#interval = setInterval(()=>{
			if(this.idleTime > this.#maxIdleTimeout){
				clearInterval(this.#interval)
				this.fire();
			}
		},this.#maxIdleTimeout)
	}

	restart() {
       this.reset()
	   this.#initialize()
	}

	reset(){
		this.events.forEach(event => document.removeEventListener(event, this.updateLastAction.bind(this), false));
		
		clearInterval(this.#interval)
		clearInterval(this.#warningInterval)
	}


}

export const SessionExpireStatus = {
	 idle:0,
	 active:1
}
