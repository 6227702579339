export const CreateCommentFields = [
	{
		key: 2,
		title: 'Comments',
		placeholder: 'Comments',
		dataIndex: 'comment',
		type: 'Text',
		rule: [
			{
				required: true,
			}]

	},
    {
		key: 0,
		title: "User",
		dataIndex: 'user',
        type: 'Hidden',
		showTitle:false
	},
	{
		key: 1,
		title: 'Holder',
		placeholder: 'Holder',
		dataIndex: 'holder',
		rule: [{
			required: true,
		},
		],
		type: 'Hidden',
		showTitle:false
	}

]