import { listFieldsForCard } from "../../CardManagementDetail/components/CardManagementListFields";
import { endpoint } from "../../../AppGlobal";
import { EditableListView } from "../../../components/EditableItemView"
import { Select, Spin, Row, Drawer, Button, Col } from "antd";
import { CardStatus } from "../../../const/enums";
import { useState, useEffect } from "react";
import Endpoint from "../../../api/endpoint";
import { errorMessageHandler } from "../../../utils/ErrorHandler";
import { createNotification } from "../../../stores/reducers/notificationreducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Role from "../../../const/role";
import { roleCheckForUser } from "../../../utils/RoleCheck";
import Form from "antd/es/form/Form";
import { SaveOutlined } from "@ant-design/icons";
import { CardSetPinFields } from "../../CardManagementDetail/components/CardSetPinField";

function CardDetailsModal({ visible, card, closeModal }) {
    const id = card.length === 2 ? card[0] : card;
    const processor = card ? card[1] : null;
    const { user } = useSelector(state => state.authreducer);
    // const { id, processor } = useParams();
    const fetchMethod = async () => {
        if (id && processor) {
            const respone = await endpoint.fetchCardDetails(id, processor);
            return respone;
        }
        return null
    };
    const processorType = parseInt(processor, 0)
    const listFields = listFieldsForCard(processorType);
    const [progress, setProgress] = useState(true)
    const [status, setStatus] = useState(CardStatus.NOTACTIVATED)
    const [statusProgress, setStatusProgress] = useState(false)
    const [formFields, setFormFields] = useState([]);
    const [blockUpdate, setBlockUpdate] = useState(false);
    const [update, setUpdate] = useState(0);
    const hasRoleIntersection = roleCheckForUser(user, [Role.CardEditor, Role.CardAdmin]);
    const dispatch = useDispatch()


    const fetchHandler = (response) => {

        if (response._status === Endpoint.status.success) {
            setStatus(CardStatus.EnumItemFromValue(response.status))
        }
        setProgress(false)
    }


    const updateStatus = async (val) => {
        setStatusProgress(true)
        const response = await endpoint.updateCardStatus(id, val)
        if (response._status === Endpoint.status.success) {
            setStatus(CardStatus.EnumItemFromValue(val))
            dispatch(createNotification({ title: 'Success', message: 'Card status updated', type: 'success' }));
            setTimeout(() => {
				window.location.reload(true); // For the drawer form of the details, after updating reload the page for the updated values.
			}, 500);

        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setStatusProgress(false)
    }


    const enabled = hasRoleIntersection && (status || CardStatus.NOTACTIVATED).value !== CardStatus.NOTACTIVATED.value

    const statusItem = statusProgress ? <Spin /> : <Select style={{ width: '130px', marginTop: '8px', marginLeft: '10px' }} disabled={!enabled} onChange={updateStatus} value={status} options={CardStatus.asItemList()} />

    const setPin = async () => {
        setProgress(true)
        const response = await endpoint.setPin(id)
        if (response._status === Endpoint.status.success) {
            dispatch(createNotification({ title: 'Success', message: 'Pin saved successfully', type: 'success' }));
            setUpdate(update + 1)

        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setProgress(false)
    }

    const [form] = Form.useForm()

    useEffect(() => {
        setFormFields(CardSetPinFields)

    }, [])

    return (
        <Drawer
            title="Card Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700}
        >
            <EditableListView id={id}
                listFields={listFields}
                fetchMethod={fetchMethod}
                fetchHandler={fetchHandler}
                title='Details'
                key={status.value}
                setUpdateDispatcher={update}
            />
            {
                !progress && (
                    <>
                        <Row>
                            <p style={{ fontWeight: 'bold' }}>Change Card Status:</p>{statusItem}
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <p style={{ fontWeight: 'bold', marginRight: '16px' }}>Set Card PIN:</p>
                            <Row>
                                <Col className='formItemInline' xs={24} sm={12} xxl={12} >
                                    <Button onClick={setPin} loading={progress} type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={blockUpdate} >Save PIN</Button>
                                </Col>

                            </Row>
                        </Row>
                    </>
                )
            }
        </Drawer>
    );
}

export default CardDetailsModal