import { isonToCurrencyName } from "../components/CountrySelector";

export var locale = 'en-GB'
export var timezone  = {
	id:'Europe/London',
	title:'London'
} 

export var currency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'GBP',
});

var staticCurrencies = {}

export const currencyBuilder = (ison) => {
    if (staticCurrencies['currency' + ison]) {
        return staticCurrencies['currency' + ison]
    }
    const currencyName = isonToCurrencyName(ison)
    const newCurrency = new  Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyName,
    });
    staticCurrencies['currency' + ison] = newCurrency
    return staticCurrencies['currency' + ison]
}