const CardHolderFilters = [
	{
		key: 0,
		title: 'Holder Id',
		placeholder: 'Holder Id',
		dataIndex:'holder_id',
		type: 'Input'
	},
	{
		key: 1,
		title: 'FH Holder Id',
		placeholder: 'FH Holder Id',
		dataIndex:'customer_id',
		type: 'Input'
	},
	{
		key: 2,
		title: 'First Name',
		placeholder: 'First Name',
		dataIndex:'first_name',
		type: 'Input'
	},
	{
		key: 3,
		title: 'Last Name',
		placeholder: 'Last Name',
		dataIndex:'last_name',
		type: 'Input'
	},
	{
		key: 4,
		title: 'Email',
		placeholder: 'Email',
		dataIndex:'email',
		type: 'Input'
	},
	{
		key: 5,
		title: 'Status',
		placeholder: 'Select Status',
		dataIndex:'is_active',
		type: 'Select',
		options: [
			{
				key: 6,
				label: 'Active',
				value: 1
			},
			{
				key: 7,
				label: 'Not Active',
				value: 0
			}
		]
	},
	{
		key: 8,
		title: 'Nationality',
		placeholder: 'Select Nationality',
		dataIndex:'nationality',
		type: 'Country',
	},
	{
        key:9,
        title: 'Client',
        placeholder:"Client",
		dataIndex:'client',
    },
	{
		key: 10,
		title: 'Created At From',
		dataIndex:'created_at',
		rangeNames: ['after', 'before'],
		rule: [{ required: false}],
		type: 'DateRange',
		allowEmpty: [true, true],

	}

]
export default CardHolderFilters