const ClientHistoryColumns = [
    {
		dataIndex: "date",
		title: "Created At",
		key: 0,
		customRender: true,
		render: (value) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<div>
					<div className='responsiveTableData'>
						Created at:
					</div>
					<p className='responsiveInline'>
						{date}
					</p>
				</div>
			)
		},
	},
	{
		dataIndex: "history_type",
		title: "Type",
		key: 1,
	},
    {
		dataIndex: "modified_by_username",
		title: "Modified By",
		key: 2,
	},
	
];


export default ClientHistoryColumns