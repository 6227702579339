import DetailLayout from "../../../Layout/detail";
import FormBuilder from "../../../components/Form";
import { useEffect, useState } from "react";
import { CreateUserFields } from "./components/CreateUserFields";
import Form from "antd/es/form/Form";
import { endpoint } from "../../../AppGlobal";
import Endpoint from "../../../api/endpoint";
import { useParams } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../stores/reducers/notificationreducer";
import { useNavigate } from "react-router-dom";
import { errorMessageHandler } from "../../../utils/ErrorHandler";

function CreateUser() {
    const { id } = useParams();
    const [progress, setProgress] = useState(false);
    const [blockUpdate, setBlockUpdate] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [editItem, setEditItem] = useState({})
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const breadCrumbItems = [
        {
            title: <a href="/user">User Management</a>,
        },
        {
            title: "Create User"
        },
    ]

    // const watchHandler = (value, values) => {
    //     if (value.is_staff !== undefined) {
    //         let updatedfields = CreateUserFields
    //         console.log(updatedfields)
    //         updatedfields.find(item => item.dataIndex == 'groups').isActive = value.is_staff
    //         setFormFields(updatedfields)
    //     }

    // }

    const save = async (values) => {
        delete values.confirm_password
        setProgress(true);
        const response = await endpoint.createUser(values)
        if (response._status === Endpoint.status.success) {
            dispatch(createNotification({ title: 'Success', message: "Created a new User", type: 'success' }));
            const redirectInterval = setInterval(() => {
                clearInterval(redirectInterval);
                navigate(`/user`)
            }, 500)
        } else {
            const message = errorMessageHandler(response)
            dispatch(createNotification({ title: 'Access Error', message: message, type: 'warning' }));
        }
        setProgress(false);
    }

    const fetchMethod = async () => {
        setProgress(true)

        let groups = CreateUserFields.find(item => item.dataIndex === 'groups');
        if (Endpoint.status.success) {

            const responseGroups = await endpoint.fetchGroups()

            if (responseGroups._status === Endpoint.status.success) {
                groups.options = responseGroups.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                groups.inprogress = false
            }
            else {
                groups.inprogress = false
                groups.error = groups.message || 'An error occurred'
            }
        }

        setProgress(false)
    };

    useEffect(() => {
        setFormFields(CreateUserFields)
        fetchMethod()
    }, [])

    const [form] = Form.useForm()

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <FormBuilder
                disabled={progress}
                form={form}
                layout="vertical"
                models={formFields.filter(item => item.isActive !== false)}
                // handler={watchHandler}
                onFinishHandler={save}
            >
                <Row>
                    <Col className='formItemInline' xs={24} sm={12} xxl={12} >
                        <Button loading={progress} type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={blockUpdate} > Save</Button>
                    </Col>

                </Row>
            </FormBuilder>
        </DetailLayout>
    );
}

export default CreateUser