import { IdleTimer } from "./SessionTimer";
import { AuthInfo } from "../../model/auth";
export class SessionManager{

	idleTimer = null;
	
	addAuthInfo(authInfo){
		let cookie = `authinfo=${JSON.stringify(authInfo)};expires=${authInfo.expire.toUTCString()};path=/`;
		document.cookie = cookie;
		return authInfo
	}
	
	getAuthInfo(){
		let cookies = document.cookie;
		let regex = /\s?authinfo=(((?!(;)).)*);?/g;
		let result = cookies.match(regex);
		if (result != null) {
			if (result.length > 0) {
				let authInfoString = result.length === 1 ? result[0] : result[1];
				authInfoString = authInfoString.replace('authinfo=', '');
				const authInfoObj = JSON.parse(authInfoString)
				let authInfo = new AuthInfo(authInfoObj.access,authInfoObj.refresh);
				return authInfo;
			}
		}
		return null;

	}
	
	addIdleHandler(handler){
		this.idleTimer = new IdleTimer();
		this.idleTimer.addHandler(handler);
	}

	removeIdleHandler(handler) {
		if (this.idleTimer) {
		  this.idleTimer.removeHandler(handler);
		}
	  }

	clearAuthInfo(){
		document.cookie = "authinfo=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
		if(this.idleTimer !== undefined && this.idleTimer !== null){
			this.idleTimer.reset();
			delete this.idleTimer;
		}
	}

}