import React from 'react'
import { endpoint } from '../../../AppGlobal';
import Role from '../../../const/role';
import CollapsedSearchLayout from '../../../components/CollapseSearchListView';
import UsageGroupsColumns from './components/UsageGroupsColumns';
import UsageGroupsDetailsModal from './components/UsageGroupsDetailsModal';
import DrawerModal from '../../../components/DrawerModal';

function UsageGroups() {

    const fetchMethod = endpoint.getMethod('fetchUsageGroups');

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    return (

        <div>
            <CollapsedSearchLayout
                columns={UsageGroupsColumns(openModal)} // remove openModal for the old details page
                fetchMethod={fetchMethod}
                filters={[]}
                barRoles={[Role.UsageGroupEditor, Role.UsageGroupAdmin]}
                searchRoles={[]}
                barAddURL="/usagegroups/create"
                title={"Usage Groups"}
            />
            {selectedItem && (
                <UsageGroupsDetailsModal
                    visible={modalVisible}
                    usagegroup={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // For the old details page uncomment this, comment above div.

        // <CollapsedSearchLayout
        //     columns={UsageGroupsColumns}
        //     fetchMethod={fetchMethod}
        //     filters={[]}
        //     barRoles={[Role.Superuser]}
        //     barAddURL="/usagegroups/create"
        //     title={"Usage Groups"}
        // />
    )

}

export default UsageGroups