import { endpoint } from '../../../AppGlobal'
import { EditableListView } from '../../../components/EditableItemView'
import Role from '../../../const/role'
import { useParams } from 'react-router-dom'
import DetailLayout from '../../../Layout/detail'
import ProgramsListFields from './components/ProgramsListFields'
import ProgramsFormFields from './components/ProgramsFormFields'
function ProgramDetail() {

    const { id } = useParams();

    const fetchMethod = endpoint.getMethod('fetchProgramDetail');

    const breadCrumbItems = [
        {
            title: <a href="/programs">Programs</a>,
        },
        {
            title: "Program"
        },
    ]

    return (
        <DetailLayout breadCrumbItems={breadCrumbItems}>
            <EditableListView id={id}
                listFields={ProgramsListFields}
                formFields={ProgramsFormFields}
                fetchMethod={fetchMethod}
                updateMethod={endpoint.getMethod('updateProgramDetails')}
                title='Details'
                groupsForEdit={[Role.ProgramEditor, Role.ProgramAdmin]}
            />
        </DetailLayout>
    )
}

export default ProgramDetail