import { LoadType } from "../../../const/enums";

export const AddBalanceFields = [
	{
		key: 0,
		title: "Processor",
		placeholder: 'Processor',
		dataIndex: 'processor',
		type: 'Select',
		async: true,
		inprogress: true,
		rule: [
			{
				required: true,
				message: "Please Select a Processor"
			}
		]

	},
	{
		key: 1,
		title: "Amount",
		placeholder: 'Amount',
		dataIndex: 'amount',
		type: 'Input',
		rule: [
			{
				required: true,
				pattern: /^\d+(\.\d{1,2})?$/,
				message: "Enter a valid amount"
			},
		]

	},
	// {
	// 	key: 2,
	// 	title: 'Currency',
	// 	placeholder: 'Currency',
	// 	dataIndex: 'currency',
	// 	type: 'Currency',
	// 	rule: [
	// 		{
	// 			required: true,
	// 		}]

	// },
	{
		key: 3,
		title: 'Load Type',
		placeholder: 'Load Type',
		dataIndex: 'load_type',
		type: 'Select',
        options:LoadType.asItemList().filter(item => item.value == 1 || item.value == 5),
		rule: [
			{
				required: true,
			}]

	},
	{
		key: 4,
		title: 'Description',
		placeholder: 'Description',
		dataIndex: 'description',
		type: 'Text',
		rule: [
			{
				required: false,
			}]

	},

]