import { fieldsForTypes, FieldsForTribe } from "../tabs/ProcessorDetail/components/formfield";
import { endpoint } from "../../../AppGlobal";
import { EditableListView } from "../../../components/EditableItemView";
import { useState } from "react";
import Endpoint from "../../../api/endpoint";
import { listFieldsForTypes, FieldsForTribe as TribeListFields } from "../tabs/ProcessorDetail/components/listfields";
import Role from "../../../const/role";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../stores/reducers/notificationreducer";
import {Drawer} from 'antd'
function ProcessorDetailsModal({ visible, processor, closeModal }) {
    const id = processor.length == 2 ? processor[1] : processor;
    // const { id, processorid } = useParams();
    const [tempFields, setTempFields] = useState([])
    const [listFields, setListFields] = useState([]);
    const dispatch = useDispatch();

    const watchHandler = (values, editItem) => {

        if (values.type !== undefined) {
            let fields = fieldsForTypes(values.type)
            setTempFields(fields.filter(item => item.isActive !== false))
            const dynamicFields = listFieldsForTypes(
                values.type
            );
            setListFields(dynamicFields)
        }
        if (values.settings__sub__addressCheck !== undefined) {
            let updatedfields = fieldsForTypes(editItem.type)
            updatedfields.find(item => item.dataIndex == 'settings__sub__allowed').isActive = values.settings__sub__addressCheck
            setTempFields(updatedfields)
            const dynamicFields = listFieldsForTypes(
                editItem.type
            );
            setListFields(dynamicFields)

        }
    }

    const fetchMethod = async (id) => {
        const response = await endpoint.fetchClientProcessorDetail(id)
        let programs = FieldsForTribe.find(item => item.dataIndex === 'programs');
        let usageGroup = FieldsForTribe.find(item => item.dataIndex === 'card_usage_group')
        let feeGroup = FieldsForTribe.find(item => item.dataIndex === 'fee_group')
        let limitGroup = FieldsForTribe.find(item => item.dataIndex === 'limit_group')
        let programsForList = TribeListFields.find(item => item.dataIndex === 'programs');
        let usageForList = TribeListFields.find(item => item.dataIndex === 'card_usage_group');
        let feeForList = TribeListFields.find(item => item.dataIndex === 'fee_group');
        let limitForList = TribeListFields.find(item => item.dataIndex === 'limit_group');
        if (response._status === Endpoint.status.success) {

            const dynamicFields = listFieldsForTypes(
                response.type
            );

            let fields = fieldsForTypes(response.type)
            const responsePrograms = await endpoint.fetchPrograms()

            if (responsePrograms._status === Endpoint.status.success) {
                let programsListField = dynamicFields.find(item => item.dataIndex === 'programs')
                programsListField.error = false
                programsForList.options = programs.options = responsePrograms.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                programs.inprogress = false
                if (programsListField) {
                    programsListField.options = programs.options
                }


            } else {
                let programsListField = dynamicFields.find(item => item.dataIndex === 'programs')
                programsListField.error = true
                programs.inprogress = false
                programs.error = 'An error occurred when fetching programs data'
                dispatch(createNotification({ title: 'Access Error', message: programs.error, type: 'warning' }));
            }
            const responseUsage = await endpoint.fetchUsageGroups()

            if (responseUsage._status === Endpoint.status.success) {

                usageGroup.inprogress = false
                let usageListField = dynamicFields.find(item => item.dataIndex === 'card_usage_group')
                usageListField.error = false
                usageForList.options = usageGroup.options = responseUsage.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                if (usageListField) {
                    usageListField.options = usageGroup.options
                }

            } else {
                let usageListField = dynamicFields.find(item => item.dataIndex === 'card_usage_group')
                usageListField.error = true
                usageGroup.inprogress = false
                usageGroup.error = 'An error occurred when fetching card usage group data'
                dispatch(createNotification({ title: 'Access Error', message: usageGroup.error, type: 'warning' }));
            }
            const responseFee = await endpoint.fetchFeeGroups()

            if (responseFee._status === Endpoint.status.success) {

                feeGroup.inprogress = false
                let feeListField = dynamicFields.find(item => item.dataIndex === 'fee_group')
                feeListField.error = false
                feeForList.options = feeGroup.options = responseFee.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                if (feeListField) {
                    feeListField.options = feeGroup.options
                }

            } else {
                let feeListField = dynamicFields.find(item => item.dataIndex === 'fee_group')
                feeListField.error = true
                feeGroup.inprogress = false
                feeGroup.error = 'An error occurred when fetching fee group data'
                dispatch(createNotification({ title: 'Access Error', message: feeGroup.error, type: 'warning' }));
            }

            const responseLimit = await endpoint.fetchLimitGroups()

            if (responseLimit._status === Endpoint.status.success) {
                limitGroup.inprogress = false
                let limitListField = dynamicFields.find(item => item.dataIndex === 'limit_group')
                limitListField.error = false
                limitForList.options = limitGroup.options = responseLimit.results.map(item => { return { label: item.name, value: item.id, type: item.type } })
                if (limitListField) {
                    limitListField.options = limitGroup.options
                }
            } else {
                let limitListField = dynamicFields.find(item => item.dataIndex === 'limit_group')
                limitListField.error = true
                limitGroup.inprogress = false
                limitGroup.error = 'An error occured when fetching limit group data'
                dispatch(createNotification({ title: 'Access Error', message: limitGroup.error, type: 'warning' }))
            }

            fields.find(item => item.dataIndex == 'settings__sub__allowed').isActive = response.settings.addressCheck
            setTempFields(fields)
            setListFields(dynamicFields)
        }
        return response
    }

    return (
        <Drawer
            title="Processor Details"
            placement="right"
            open={visible}
            onClose={closeModal}
            width={700}
        >
            <EditableListView id={id}
                listFields={listFields}
                formFields={tempFields.filter(item => item.isActive !== false)}
                fetchMethod={fetchMethod.bind(this)}
                updateMethod={(processorId, processorData) => {
                    if (processorData.programs === undefined) {
                        processorData.programs = []
                        processorData.card_usage_group = []
                    }
                    if (processorData.allowed === undefined) {
                        processorData.allowed = []
                    }
                    return endpoint.updateClientProcessor(processorId, processorData)
                }}
                title='Details'
                watchHandler={watchHandler}
                groupsForEdit={[Role.ClientAdmin, Role.ClientEditor]}

            />
        </Drawer>
    )
}

export default ProcessorDetailsModal