import { createSlice/*, configureStore*/} from '@reduxjs/toolkit'

const notificationSlice = createSlice({

	name: 'notificationItem',
	initialState: {
		notificationItem:null
	},
	reducers: {
		createNotification:(state, action) => {
			state.notificationItem = action.payload

		}
	}
});

export const { createNotification } = notificationSlice.actions;
export default notificationSlice.reducer