import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const LogsColumns = [
	{
		title: "Username",
		dataIndex: 'username'
	},
	{
		title: "API",
		dataIndex: 'api'
	},
	{
		title: "Status Code",
		dataIndex: 'status_code'
	},
	{

        title: "Created at",
        dataIndex: 'created_at',
        customRender: true,
        render: (value) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        },
    },
	{
        title: 'Action',
        dataIndex: 'logs',
        align: 'center',
        customRender: true,
        render: (log_id) => {
            return (
                <Link to={`/logs`}>
                    <EyeOutlined style={{ fontSize: '22px' }} />
                </Link>
            )
        }
    },
	// {
	// 	key: 1,
	// 	title: "Username",
	// 	dataIndex: 'username',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const account = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{account}
	// 			</p>
	// 		)
	// 	},
	// },
	// {
	// 	key: 2,
	// 	title: "User ID",
	// 	dataIndex: 'user_id'
	// },
	// {
	// 	key: 3,
	// 	title: 'API',
	// 	dataIndex: 'api'
	// },
	// {
	// 	key: 4,
	// 	title: 'Headers',
	// 	dataIndex: 'headers'

	// },
	// {
	// 	key: 5,
	// 	title: 'Body',
	// 	dataIndex: 'body',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const description = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{description}
	// 			</p>
	// 		)
	// 	},

	// },
    // {
	// 	key: 6,
	// 	title: 'Method',
	// 	dataIndex: 'method',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const description = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{description}
	// 			</p>
	// 		)
	// 	},

	// },
    // {
	// 	key: 7,
	// 	title: 'IP',
	// 	dataIndex: 'ip',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const description = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{description}
	// 			</p>
	// 		)
	// 	},

	// },
    // {
	// 	key: 8,
	// 	title: 'Response',
	// 	dataIndex: 'response',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const description = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{description}
	// 			</p>
	// 		)
	// 	},

	// },
    // {
	// 	key: 9,
	// 	title: 'Status Code',
	// 	dataIndex: 'status_code',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const description = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{description}
	// 			</p>
	// 		)
	// 	},

	// },
    // {
	// 	key: 10,
	// 	title: 'Execution Time',
	// 	dataIndex: 'execution_time',
	// 	customRender: true,
	// 	render: (value) => {

	// 		const description = value ? value : '-'
	// 		return (
	// 			<p className='responsiveInline'>
	// 				{description}
	// 			</p>
	// 		)
	// 	},

	// },
	// {
    //     key: 11,
    //     title: 'Created at',
    //     dataIndex: 'created_at',
    //     sorter: () => { },
    //     customRender: true,
    //     render: (value, row) => {
    //         return (
    //             <div>
    //                 <div className='responsiveTableData'>
    //                     Created at:
    //                 </div>
    //                 <p className='responsiveInline'>
    //                     {value.toListDateString()}
    //                 </p>
    //             </div>
    //         )
    //     }
    // },

];

export default LogsColumns