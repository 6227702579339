import FormBuilder from "./Form";
import { Form, Space, Row, Col, Button, Layout } from 'antd'
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import { useState } from "react";
const FilterForm = ({ models, filterHandler}) => {
	
    const [filter,setFilter] = useState({})
	const [searchIsEnabled, setSearchIsEnabled] = useState(false);
	const [claerIsEnabled, setClaerIsEnabled] = useState(false)
	const [form] = Form.useForm()
	const updateFilter = (values,errors)=>{
		 form.validateFields();
		 const withoutError =  form.getFieldsError().filter(item => item.errors.length> 0).length === 0
		 const needSearch = Object.keys(values).filter(key => values[key] !== "").length > 0 && withoutError
		 setFilter(form.getFieldsValue())
		 setSearchIsEnabled(needSearch)
	}
    
	const clear = ()=>{
		form.resetFields()
	    setFilter({})
		setClaerIsEnabled(false)
		setSearchIsEnabled(false)
		filterHandler({})
	}

	const search = ()=>{
		filterHandler(filter)
		setClaerIsEnabled(true)

	}
	
	return (
		<Layout className="filterFormContainer" >
		
			<FormBuilder layout='inline' models={models} handler={updateFilter} form={form}>
				<Form.Item>
					<Space>
						<Row>
							<Col className='formItemInline' xs={24} sm={12} xxl={12} >
								<Button onClick={search} type="primary" htmlType="submit" icon={<SearchOutlined />} disabled={!searchIsEnabled}> Search</Button>
							</Col>
							<Col className='formItemInline' xs={24} sm={12} xxl={12}>
								<Button onClick={clear} type="primary" icon={<ClearOutlined />} disabled={!claerIsEnabled} >Clear</Button>
							</Col>
						</Row>
					</Space>
				</Form.Item>
			</FormBuilder>
			
		</Layout>
	)

}

export default FilterForm